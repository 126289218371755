import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const ViewBrokerAgent = ({ onSaveSuccess, selectedData, setRefreshViewModal, refreshViewModal }) => {
  const {
    register,
    setValue, // Added setValue from react-hook-form
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [brokersList, setBrokersList] = useState([]);
  const [fetchDone, setFetchDone] = useState(false);

  const fetchBrokers = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.BROKERNAMES);
      setBrokersList(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  useEffect(() => {
    if (selectedData && refreshViewModal && !fetchDone) {
      setIsLoading(true);
      fetchBrokers();
      setFetchDone(true);
    }
  }, [selectedData, refreshViewModal]);


  useEffect(() => {
    if (selectedData && fetchDone) {
      console.log(selectedData)
      setValue("agentName", selectedData[1]);
      setValue("agentPhone", selectedData[2]);
      setValue("agentEmail", selectedData[3]);
      setValue("brokerId", selectedData[7]);
      setValue("agentStatus", selectedData[6]);
      setFetchDone(false);
      setIsLoading(false);
    }
  }, [brokersList]);

  return (
    <div
      className="modal fade"
      id="modalViewBrokerAgent"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >

      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">
              Viewing Agent
            </h1>
          </div>
          {!isLoading ? (<form className="modal-body">
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Name:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("agentName", {
                    required: true,
                    maxLength: 255,
                  })}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Broker:</label>
                <select
                  className="form-control create-input"
                  {...register("brokerId")}
                  disabled
                >
                  <option key={"Choose..."} value={"Choose..."}>
                    Choose...
                  </option>
                  {brokersList.map((broker) => (
                    <option key={broker[0]} value={broker[0]}>
                      {broker[1] + "  (" + broker[2] + ")"}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Phone:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("agentPhone", {
                    required: true,
                    maxLength: 255,
                  })}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Email:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("agentEmail", { maxLength: 255 })}
                  disabled
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Status:</label>
                <select
                  className="form-control create-input"
                  {...register("agentStatus")}
                  disabled
                >
                  <option value="Active">Active</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>
            <div >
            
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="closeButton"
                onClick={e => setRefreshViewModal(false)}
              >
                Close
              </button>
            </div>
          </form>) : (<>
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>)}
        </div>
      </div>
    </div>
  );
};

export default ViewBrokerAgent;
