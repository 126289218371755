import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import "../../Styling/NewLoadOrAgent.css";

const SignupBrokerAgent = ({ onSaveSuccess }) => {
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const [brokers, setBrokers] = useState([]);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const fetchBrokers = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.BROKERNAMES);
      setBrokers(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Broker is being added. Please Wait..."); // Update id inside the try block
    try {
      if (data.brokerId === 'Choose...')
      {
        toast.update(id, { render: 'Please Select a broker from the list.', type: "warning", isLoading: false, autoClose: 1500 });
        return;
      }

      await axiosInstance.post(API_ENDPOINTS.BROKERSAGENT, data);
      toast.update(id, { render: "Successful. New broker is added.", type: "success", isLoading: false, autoClose: 1500 });
      reset();
      onSaveSuccess();
      document.getElementById("closeButtonSignUpBrokerAgent").click();
    } catch (error) {
      toast.update(id, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 1500 });
      console.error("Error ocurred while adding new broker:", error);
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="modalNewBrokerAgent"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">Agent Registration</h1>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Name:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("agentName", {
                    required: true,
                    maxLength: 255,
                  })}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Broker:</label>
                <select
                  className="form-control create-input"
                  {...register("brokerId")}
                  onFocus={fetchBrokers}
                  defaultValue={"Choose..."}
                  required
                >
                  <option key="Choose..." value="Choose...">Choose...</option>
                  {brokers.map((broker) => (
                    <option key={broker[0]} value={broker[0]}>
                      {broker[1] + "  (" + broker[2]+")"}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Phone:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("agentPhone", {
                    maxLength: 255,
                  })}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Email:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("agentEmail", { maxLength: 255 })}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Status:</label>
                <select
                  className="form-control create-input"
                  {...register("agentStatus")}
                >
                  <option value="Active">Active</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>
            <div >
              <button type="submit" className="btn reg-user-submit">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="closeButtonSignUpBrokerAgent"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupBrokerAgent;
