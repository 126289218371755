import React, { useContext, useEffect, useState } from 'react';
import { useForm, } from 'react-hook-form';
import "../../Styling/LoginForm.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { FaUserSecret } from "react-icons/fa";
import { useHistory } from 'react-router-dom';  // Import useHistory
import { OpenContext } from '../../App';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginForm = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();
  const { reloadNavbarComponent } = useContext(OpenContext);
  const [success, setSuccess] = useState(false)
  const [loader, setLoader] = useState(true)
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("authkeytemp")) {
        try {
          // Make an Axios request to fetch user data or access token
          await axiosInstance.get(API_ENDPOINTS.ME);
          setSuccess(true);
          setLoader(false);
          history.push('/loads')
        } catch (error) {
          console.error('Error fetching user data:', error);
          localStorage.removeItem("authkeytemp")
          setSuccess(false);
          setLoader(false);
        }
      }
      else {
        localStorage.removeItem("authkeytemp")
        setSuccess(false);
        setLoader(false)
      }
    };

    fetchData();
  }, [history]);

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    localStorage.removeItem('authkeytemp')
    let id = toast.loading("Performing authentication. Please wait..."); // Update id inside the try block
    let messageii = "Internal Server Error. Please try again later...";
    let typeii = "error";
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.LOGIN, data);
      localStorage.setItem("authkeytemp", response.data.message.authkeytemp);
      reset();
      reloadNavbarComponent();
      messageii= "Login Successful. Redirecting you to homepage";
      typeii = "success"
      history.push('/loads');

    } catch (error) {
      console.log("Error Signing In.", error)
      messageii = error.response.data.message;
    }finally {
      setIsRequestInProgress(false);
      toast.update(id, { render: messageii , type: typeii , isLoading: false, autoClose: 1500 });
    }
  };

  return (
    <div >
      {loader ? (<div className="text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>) : success ? (
        <div className="text-center">
          <p>You are already logged In, Please hold while we redirect you to your Homepage. You can proceed manually by clicking the button below.</p>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => history.push('/loads')}  // Use onClick to navigate
          >
            Proceed to Dashboard
          </button>
        </div>
      ) : (
        <div className="bodi d-flex justify-content-center">
          <div className="user_card">
            <div className="d-flex justify-content-center">
              <div className="brand_logo_container">
                <FaUserSecret className="brand_logo" />
              </div>
            </div>
            <div className="d-flex justify-content-center form_container">
              <form onSubmit={handleSubmit(onSubmit)} >
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text"><i className="fas fa-user"></i></span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Username"
                    {...register('userName', {
                      required: 'Username is required',
                    })}
                  />
                </div>
                {errors.userName && errors.userName.type === "required" && (
                  <span className="text-danger fst-italic">
                    {" "}
                    Username is required.
                  </span>
                )}
                <div className="input-group mt-2">
                  <div className="input-group-append">
                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                  </div>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    {...register('password', { required: 'Password is required' })}
                  />
                </div>
                {errors.password && errors.password.type === "required" && (
                  <span className="text-danger fst-italic">
                    {" "}
                    Password is required.
                  </span>
                )}
                <div className="form-group mt-2">
                  <input type="checkbox" className='input-group-append' />
                  <label className="custom-control-label text-warning give_space">Remember me!</label>
                </div>
                <div className="d-flex justify-content-center mt-3 login_container">
                  <button type="submit" name="button" className="btn login_btn">Login</button>
                </div>
              </form>
            </div>

            <div className="mt-4">
              <div className="d-flex justify-content-center">
                <a href="mailto:muhammadshahab.business@gmail.com" className='text-warning'>Forgot your password?</a>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default LoginForm;
