import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const StatsLoads1 = () => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [loadsDataset, setLoadsDataset] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);

  const chartSetting = {
    yAxis: [
      {
        label: "Load Count",
      },
    ],
    width: 925,
    height: 400,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-5px, 0)",
      },
    },
  };

  useEffect(() => {
    if (selectedYear) {
      getLoadCount();
    }
  }, [selectedYear]);

  const getLoadCount = async () => {
    setIsLoading(true); // Show loader when fetching starts
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GETLOADSTATUSCOUNT}?year=${selectedYear}`
      );
      setLoadsDataset(response.data.message);
    } catch (error) {
      console.error("Error getting Loads Count:", error);
    }
    setIsLoading(false); // Hide loader once fetching is done
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
  };

  return (
    <div>
      <div className="mx-3 mt-2">
        <label className="mx-2">Select Year: </label>
        <select value={selectedYear} onChange={handleYearChange}>
          <option value="2024">2024</option>
        </select>
      </div>

      {/* Conditionally render loader or chart */}
      {isLoading ? (
        <div className="d-flex justify-content-center my-4">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <BarChart
          dataset={loadsDataset}
          xAxis={[{ scaleType: "band", dataKey: "month" }]}
          series={[
            { dataKey: "Cancelled", label: "Cancelled" },
            { dataKey: "Invoiced", label: "Invoiced" },
            { dataKey: "Conflict", label: "Conflict" },
          ]}
          {...chartSetting}
        />
      )}
    </div>
  );
};

export default StatsLoads1;
