import React, { useEffect, useState } from "react";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewLoad = ({
  loadId,
  loadNumber,
  setRefreshViewModal,
  refreshViewModal,
}) => {
  const [loadData, setLoadData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilesRCD, setSelectedFilesRCD] = useState([]);
  const [selectedFilesRC, setSelectedFilesRC] = useState([]);
  const [selectedFilesBOL, setSelectedFilesBOL] = useState([]);
  const [selectedFilesST, setSelectedFilesST] = useState([]);
  const [selectedFilesLR, setSelectedFilesLR] = useState([]);
  const [selectedFilesMD, setSelectedFilesMD] = useState([]);
  const [selectedFilesLS, setSelectedFilesLS] = useState([]);
  const [secondShipperVisible, setSecondShipperVisible] = useState(false);
  const [secondRecieverVisible, setSecondReceiverVisible] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null); // State for iframe URL
  const [fileName, setFileName] = useState(null); // State for iframe URL

  const fetchLoadData = async () => {
    setIsLoading(true);
    refreshStates();
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GETLOAD, {
        params: { loadId },
      });
      const tempData = response.data.data["load-data"];
      setLoadData(tempData);
      console.log(tempData);
    } catch (error) {
      console.error("Error fetching load to view:", error);
    }
    setIsLoading(false);
  };

  const refreshStates = () => {
    setLoadData({});
    setSelectedFilesRCD([]);
    setSelectedFilesRC([]);
    setSelectedFilesBOL([]);
    setSelectedFilesST([]);
    setSelectedFilesLR([]);
    setSelectedFilesMD([]);
    setSelectedFilesLS([]);
    setSecondReceiverVisible(false);
    setSecondReceiverVisible(false);
    setBlobUrl(null);
    setFileName(null);
  };

  useEffect(() => {
    if (loadId && refreshViewModal) {
      fetchLoadData();
    }
  }, [loadId, refreshViewModal]);

  useEffect(() => {
    setSelectedFilesRCD(loadData.rateContDriver);
    setSelectedFilesRC(loadData.rateCont);
    setSelectedFilesBOL(loadData.billOfLanding);
    setSelectedFilesST(loadData.scaleTicket);
    setSelectedFilesLR(loadData.lumperReceipt);
    setSelectedFilesMD(loadData.miscellaneousDocuments);
    setSelectedFilesLS(loadData.locationScreenshots);
  }, [loadData]);

  const handleViewClick = async (contentName) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWFILE}/${contentName}?loadId=${loadId}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  const handleDownloadClick = async (contentName) => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FILE}/${contentName}?loadId=${loadId}`
      );
      const fileUrl = response.data.url;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.log("Error fetching file:", error);
    }
  };

  const toggleSecondShipper = () => {
    setSecondShipperVisible((prev) => !prev);
  };

  const toggleSecondReceiver = () => {
    setSecondReceiverVisible((prev) => !prev);
  };

  return (
    <div
      className="modal fade"
      id="modal2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">
                Viewing Load# {loadNumber}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setRefreshViewModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={(e) => e.preventDefault()} className="modal-body">
              <ul className="nav nav-tabs" id="myTab1" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="load-details-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#load-details-view"
                    type="button"
                    role="tab"
                    aria-controls="load-details-view"
                    aria-selected="true"
                  >
                    Load Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="assigned-agents-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#assigned-agents-view"
                    type="button"
                    role="tab"
                    aria-controls="assigned-agents-view"
                    aria-selected="false"
                  >
                    Driver
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="shipper-details-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#shipper-details-view"
                    type="button"
                    role="tab"
                    aria-controls="shipper-details-view"
                    aria-selected="false"
                  >
                    Shipper Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="receiver-details-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#receiver-details-view"
                    type="button"
                    role="tab"
                    aria-controls="receiver-details-view"
                    aria-selected="false"
                  >
                    Receiver Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="broker-details-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#broker-details-view"
                    type="button"
                    role="tab"
                    aria-controls="broker-details-view"
                    aria-selected="false"
                  >
                    Broker Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="lumper-details-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#lumper-details-view"
                    type="button"
                    role="tab"
                    aria-controls="lumper-details-view"
                    aria-selected="false"
                  >
                    Lumper Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="documents-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#documents-view"
                    type="button"
                    role="tab"
                    aria-controls="documents-view"
                    aria-selected="false"
                  >
                    Documents
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="notes-view-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#notes-view"
                    type="button"
                    role="tab"
                    aria-controls="notes-view"
                    aria-selected="false"
                  >
                    Notes
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent1">
                <div
                  className="tab-pane fade show active"
                  id="load-details-view"
                  role="tabpanel"
                  aria-labelledby="load-details-view-tab"
                >
                  <div id="loadDetails" className="fw-bold text-center">
                    Load Details
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Label:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.label}
                        disabled
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Load#</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.aalLoad}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Trailer#
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.vanNumber}
                        disabled
                      />
                    </div>

                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Rate:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.rate}
                        disabled
                      />
                    </div>
                    <div className="col-md-2 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Is TONU?{" "}
                      </label>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={loadData.isTonu}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Instructions:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.instruction}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Escalation:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.escalation}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Det. Amount:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.detentionAmount}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="assigned-agents-view"
                  role="tabpanel"
                  aria-labelledby="assigned-agents-tab"
                >
                  <div id="assignedagents" className="fw-bold text-center">
                    Driver
                  </div>
                  <hr className="rounded"></hr>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Driver:
                      </label>

                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.driverName}
                        disabled
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Status:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.status}
                        disabled
                      />
                    </div>
                  </div>

                  {loadData.driver2Name !== null && (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Driver#2:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.driver2Name}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="row ">
                        <div className="col-md-12 d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={toggleSecondDriver}
                          >
                            View Second Driver
                          </button>
                        </div>
                      </div> */}
                </div>
                <div
                  className="tab-pane fade"
                  id="shipper-details-view"
                  role="tabpanel"
                  aria-labelledby="shipper-details-view-tab"
                >
                  <div
                    id="shipperDetails-view"
                    className=" fw-bold text-center mt-4"
                  >
                    Shipper Details
                  </div>{" "}
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Name:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.shipperName}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Address:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.shipperAddress}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Start Date:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.shipperStartTime}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        End Date:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.shipperEndTime}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Instructions:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.shipperInstruction}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Check In:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.shipperCheckInTime}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Check Out:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.shipperCheckOutTime}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleSecondShipper}
                      >
                        View Second Pickup
                      </button>
                    </div>
                  </div>
                  {secondShipperVisible && (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Name:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.shipper2Name}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Address:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.shipper2Address}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Start Date:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.shipper2StartTime}
                            disabled
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            End Date:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.shipper2EndTime}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Instructions:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.shipper2Instruction}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check In:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.shipperCheck2InTime}
                            disabled
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check Out:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.shipperCheck2OutTime}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="receiver-details-view"
                  role="tabpanel"
                  aria-labelledby="receiver-details-view-tab"
                >
                  <div
                    id="receiverDetails-view"
                    className="fw-bold text-center mt-4"
                  >
                    Reciever Details
                  </div>{" "}
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Name:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.receiverName}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Address:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.receiverAddress}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Start Date:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.receiverStartTime}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            End Date:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.receiverEndTime}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Instructions:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.receiverInstruction}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check In:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.receiverCheckInTime}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check Out:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.receiverCheckOutTime}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleSecondReceiver}
                      >
                        View Second DropOff
                      </button>
                    </div>
                  </div>
                  {secondRecieverVisible && (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Name:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.receiver2Name}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Address:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.receiver2Address}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                defaultValue={loadData.receiver2StartTime}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                End Date:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                defaultValue={loadData.receiver2EndTime}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Instructions:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            defaultValue={loadData.receiver2Instruction}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                defaultValue={loadData.receiver2CheckInWaqt}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                defaultValue={loadData.receiver2CheckOutWaqt}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="broker-details-view"
                  role="tabpanel"
                  aria-labelledby="broker-details-view-tab"
                >
                  <div
                    id="brokerDetails-view"
                    className="fw-bold text-center mt-4"
                  >
                    Broker Details
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex flex-column">
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Broker:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          defaultValue={loadData.brokerName}
                          disabled
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">MC#:</label>
                        <input
                          type="text"
                          className="form-control input-field"
                          defaultValue={loadData.brokerMc}
                          disabled
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          After Hours:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          defaultValue={loadData.lumperPhone}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6 d-flex flex-column">
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Agent:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          defaultValue={loadData.brokerAgent}
                          disabled
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Phone:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          defaultValue={loadData.brokerPhone}
                          disabled
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Email:
                        </label>
                        <input
                          type="email"
                          className="form-control input-field"
                          defaultValue={loadData.brokerEmail}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="lumper-details-view"
                  role="tabpanel"
                  aria-labelledby="lumper-details-view-tab"
                >
                  <div
                    id="lumperDetails-view"
                    className="fw-bold text-center mt-4"
                  >
                    Lumper Details
                  </div>{" "}
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Name:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.lumperName}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Email:</label>
                      <input
                        type="email"
                        className="form-control input-field"
                        defaultValue={loadData.lumperEmail}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Amount(USD):
                      </label>
                      <input
                        type="number"
                        className="form-control input-field"
                        defaultValue={loadData.lumperAmount}
                        disabled
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Paid by:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={
                          loadData.lumperPaidBy === "Choose..."
                            ? ""
                            : loadData.lumperPaidBy
                        }
                        disabled
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Paid with:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={
                          loadData.lumperPaidWith === "Choose..."
                            ? ""
                            : loadData.lumperPaidWith
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="documents-view"
                  role="tabpanel"
                  aria-labelledby="documents-view-tab"
                >
                  <div id="documents-view" className="fw-bold text-center mt-4">
                    Documents
                  </div>{" "}
                  <hr className="rounded"></hr>
                  {blobUrl && (
                    <>
                      <div className="d-flex flex-row-reverse">
                        <button
                          className="btn btn-outline-danger"
                          onClick={(e) => setBlobUrl(null)}
                        >
                          Close
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {fileName.endsWith(".pdf") ? (
                            <embed
                              className="iframedriver"
                              src={blobUrl}
                            ></embed>
                          ) : (
                            <img
                              width={"100%"}
                              height="auto"
                              src={blobUrl}
                              alt="Image"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Rate Confirmation:
                      </label>
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesRC ? (
                        <ul>
                          {selectedFilesRC.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleDownloadClick(file)}
                              >
                                Download
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No file attached</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Driver's Rate Confirmation:
                      </label>
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesRCD ? (
                        <ul>
                          {selectedFilesRCD.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleDownloadClick(file)}
                              >
                                Download
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No file attached</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Bill of Lading:</label>
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesBOL ? (
                        <ul>
                          {selectedFilesBOL.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleDownloadClick(file)}
                              >
                                Download
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No file attached</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Scale Ticket:</label>
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesST ? (
                        <ul>
                          {selectedFilesST.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleDownloadClick(file)}
                              >
                                Download
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No file attached</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Lumper Reciept:</label>
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesLR ? (
                        <ul>
                          {selectedFilesLR.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleDownloadClick(file)}
                              >
                                Download
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No file attached</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Location Screenshots:
                      </label>
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesLS ? (
                        <ul>
                          {selectedFilesLS.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleDownloadClick(file)}
                              >
                                Download
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No file attached</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Miscellaneous Documents:
                      </label>
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesMD ? (
                        <ul>
                          {selectedFilesMD.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleDownloadClick(file)}
                              >
                                Download
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No file attached</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="notes-view"
                  role="tabpanel"
                  aria-labelledby="notes-view-tab"
                >
                  <div id="notesEdit " className="fw-bold text-center mt-4">
                    Notes
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <textarea
                        type="text"
                        rows="7"
                        cols="40"
                        className="form-control"
                        defaultValue={loadData.dispatchNotes}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewLoad;
