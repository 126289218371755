import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const EditLoad = ({
  loadId,
  loadNumber,
  onSaveSuccess,
  setRefreshEditModal,
  refreshEditModal,
  role,
  loadStatusss,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [driversList, setDriversList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assignedDriverKey, setAssignedDriverKey] = useState("Choose...");
  const [assignedDriver2Key, setAssignedDriver2Key] = useState("Choose...");
  const [brokers, setBrokers] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [loadSpecs, setLoadSpecs] = useState({
    loadID: null,
    loadStatus: null,
  });
  const [brokerAgents, setBrokerAgents] = useState([]);
  const [loadfiles, setLoadFiles] = useState({
    rateCont: null,
    rateContDriver: null,
    billOfLanding: null,
    scaleTicket: null,
    lumperReceipt: null,
    miscellaneousDocuments: null,
    locationScreenshots: null,
  });
  const [secondDriverVisible, setDriverVisible] = useState(false);
  const [secondShipperVisible, setSecondShipperVisible] = useState(false);
  const [secondReceiverVisible, setSecondReceiverVisible] = useState(false);
  const [selectedFilesRC, setSelectedFilesRC] = useState([]);
  const [selectedFilesRCD, setSelectedFilesRCD] = useState([]);
  const [selectedFilesBOL, setSelectedFilesBOL] = useState([]);
  const [selectedFilesST, setSelectedFilesST] = useState([]);
  const [selectedFilesLR, setSelectedFilesLR] = useState([]);
  const [selectedFilesMD, setSelectedFilesMD] = useState([]);
  const [selectedFilesLS, setSelectedFilesLS] = useState([]);

  const [selectedFilesRC2, setSelectedFilesRC2] = useState([]);
  const [selectedFilesRCD2, setSelectedFilesRCD2] = useState([]);
  const [selectedFilesBOL2, setSelectedFilesBOL2] = useState([]);
  const [selectedFilesST2, setSelectedFilesST2] = useState([]);
  const [selectedFilesLR2, setSelectedFilesLR2] = useState([]);
  const [selectedFilesMD2, setSelectedFilesMD2] = useState([]);
  const [selectedFilesLS2, setSelectedFilesLS2] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const fetchLoad = async () => {
    setIsLoading(true);
    reset();
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GETLOAD, {
        params: { loadId },
      });
      if (response.data.data["driver-list"][0]) {
        setDriversList(response.data.data["driver-list"][0]);
      } else {
        console.log("Driver Not Found");
      }
      const tempData = response.data.data["load-data"];
      if (typeof tempData === "object" && tempData !== null) {
        Object.keys(tempData).forEach((key) => {
          if (key === "driver") {
            if (tempData[key] !== null) {
              const newValue = tempData[key];
              setAssignedDriverKey(newValue);
              setValue("assignedDriverKey", newValue);
            } else {
              setAssignedDriverKey("Choose...");
              setValue("assignedDriverKey", "Choose...");
            }
          } else if (key === "driver2") {
            if (tempData[key] !== null) {
              const newValue = tempData[key];
              setAssignedDriver2Key(newValue);
              setValue("assignedDriver2Key", newValue);
            } else {
              setAssignedDriver2Key("Choose...");
              setValue("assignedDriver2Key", "Choose...");
            }
          } else if (
            [
              "rateCont",
              "rateContDriver",
              "billOfLanding",
              "scaleTicket",
              "lumperReceipt",
              "miscellaneousDocuments",
              "locationScreenshots",
            ].includes(key)
          ) {
            setLoadFiles((prevLoadFiles) => ({
              ...prevLoadFiles,
              [key]: tempData[key],
            }));
          } else if (
            [
              "shipperStartTime",
              "shipperEndTime",
              "receiverStartTime",
              "receiverEndTime",
              "shipperCheckInTime",
              "shipperCheckOutTime",
              "receiverCheckInTime",
              "receiverCheckOutTime",
              "shipper2StartTime",
              "shipper2EndTime",
              "receiver2StartTime",
              "receiver2EndTime",
              "shipper2CheckInTime",
              "shipper2CheckOutTime",
              "receiver2CheckInTime",
              "receiver2CheckOutTime",
            ].includes(key)
          ) {
            if (tempData[key] && tempData[key] !== "") {
              const val = tempData[key].slice(0, 10);
              const [month, day, year] = val.split("/");
              const sliced = key.slice(0, key.indexOf("Time"));
              const formattedDateString = `${year}-${month}-${day}`;
              setValue(`${sliced}Date`, formattedDateString);
              setValue(`${sliced}Waqt`, tempData[key].slice(12, 17));
            }
          } else if (key === "loadId") {
            setLoadSpecs((prevSpecs) => ({
              ...prevSpecs,
              loadID: tempData[key],
            }));
          } else if (key === "status") {
            setLoadSpecs((prevSpecs) => ({
              ...prevSpecs,
              loadStatus: tempData[key],
            }));
            setValue(key, tempData[key]);
          } else if (key === "vanNumber" && tempData[key] === null) {
            setValue(key, "");
          } else {
            setValue(key, tempData[key]);
          }
        });
      } else {
        console.log("Invalid Data Structure");
      }
      if (tempData.driver2 !== null) {
        setDriverVisible(true);
      } else {
        setDriverVisible(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching load to edit:", error);
      setIsLoading(false);
    }
  };

  const refreshstates = () => {
    reset();
    setSelectedFilesRC([]);
    setSelectedFilesRC2([]);
    setSelectedFilesRCD([]);
    setSelectedFilesRCD2([]);
    setSelectedFilesBOL([]);
    setSelectedFilesBOL2([]);
    setSelectedFilesST([]);
    setSelectedFilesST2([]);
    setSelectedFilesLR([]);
    setSelectedFilesLR2([]);
    setSelectedFilesMD([]);
    setSelectedFilesMD2([]);
    setSelectedFilesLS([]);
    setSelectedFilesLS2([]);
    setBlobUrl(null);
  };

  useEffect(() => {
    if (loadId && refreshEditModal) {
      refreshstates();
      fetchLoad();
    } else {
      document.getElementById("closeButtonEditLoad").click();
    }
  }, [loadId, refreshEditModal]);

  useEffect(() => {
    setSelectedFilesRC(loadfiles.rateCont);
    setSelectedFilesRCD(loadfiles.rateContDriver);
    setSelectedFilesBOL(loadfiles.billOfLanding);
    setSelectedFilesST(loadfiles.scaleTicket);
    setSelectedFilesLR(loadfiles.lumperReceipt);
    setSelectedFilesMD(loadfiles.miscellaneousDocuments);
    setSelectedFilesLS(loadfiles.locationScreenshots);
  }, [loadfiles]);

  const toggleSecondDriver = () => {
    setDriverVisible((prev) => !prev);
  };

  const toggleSecondShipper = () => {
    setSecondShipperVisible((prev) => !prev);
  };

  const toggleSecondReceiver = () => {
    setSecondReceiverVisible((prev) => !prev);
  };

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    if (
      role !== "admin" &&
      (loadStatusss === "Completed" || loadStatusss === "TONU")
    ) {
      const confirmSave = window.confirm(
        `Are you sure you want to edit this completed load.`
      );
      if (!confirmSave) {
        setIsRequestInProgress(true);
        return;
      }
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating load details.Please wait...");
    try {
      const formData = new FormData();
      Object.keys(data).forEach((val) => {
        val !== "assignedBrokerUserName" &&
          val !== "assignedBrokerAgentUserName" &&
          val !== "driverName" &&
          val !== "driver2Name" &&
          val !== "loadID" &&
          formData.append(val, data[val]);
      });

      selectedFilesRC2.forEach((file) => {
        formData.append("rateCont", file);
      });

      selectedFilesRCD2.forEach((file) => {
        formData.append("rateContDriver", file);
      });

      selectedFilesBOL2.forEach((file) => {
        formData.append("billOfLanding", file);
      });
      selectedFilesST2.forEach((file) => {
        formData.append("scaleTicket", file);
      });
      selectedFilesLR2.forEach((file) => {
        formData.append("lumperReceipt", file);
      });
      selectedFilesMD2.forEach((file) => {
        formData.append("miscellaneousDocuments", file);
      });
      selectedFilesLS2.forEach((file) => {
        formData.append("locationScreenshots", file);
      });

      await axiosInstance.patch(API_ENDPOINTS.GETLOAD, formData, {
        params: { loadId: loadId },
      });
      toast.update(id, {
        render: "Load details updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      onSaveSuccess();
      document.getElementById("closeButtonEditLoad").click();
    } catch (error) {
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  const handleFileChangeRC2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesRC2([...selectedFilesRC2, ...filteredFiles]);
  };

  const handleFileChangeRCD2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesRCD2([...selectedFilesRCD2, ...filteredFiles]);
  };

  const handleFileChangeBOL2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesBOL2([...selectedFilesBOL2, ...filteredFiles]);
  };

  const handleFileChangeST2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesST2([...selectedFilesST2, ...filteredFiles]);
  };

  const handleFileChangeLR2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesLR2([...selectedFilesLR2, ...filteredFiles]);
  };

  const handleFileChangeMD2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesMD2([...selectedFilesMD2, ...filteredFiles]);
  };

  const handleFileChangeLS2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesLS2([...selectedFilesLS2, ...filteredFiles]);
  };

  const handleFileDeleteRC2 = (fileName) => {
    const updatedFiles = selectedFilesRC2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesRC2(updatedFiles);
  };

  const handleFileDeleteRCD2 = (fileName) => {
    const updatedFiles = selectedFilesRCD2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesRCD2(updatedFiles);
  };

  const handleFileDeleteBOL2 = (fileName) => {
    const updatedFiles = selectedFilesBOL2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesBOL2(updatedFiles);
  };

  const handleFileDeleteST2 = (fileName) => {
    const updatedFiles = selectedFilesST2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesST2(updatedFiles);
  };

  const handleFileDeleteLR2 = (fileName) => {
    const updatedFiles = selectedFilesLR2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLR2(updatedFiles);
  };

  const handleFileDeleteMD2 = (fileName) => {
    const updatedFiles = selectedFilesMD2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesMD2(updatedFiles);
  };

  const handleFileDeleteLS2 = (fileName) => {
    const updatedFiles = selectedFilesMD2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLS2(updatedFiles);
  };

  const handleViewClick = async (contentName) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWFILE}/${contentName}?loadId=${loadId}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  // const handleConvertToPdfClick = async (contentName) => {
  //   setBlobUrl(null);
  //   setFileName(contentName);
  //   let id = toast.loading("File is being fetched. Please wait...");
  //   try {
  //     const response = await axiosInstance.get(`${API_ENDPOINTS.CONVERTIMG2PDF}/${contentName}?loadId=${loadId}`, {
  //       responseType: 'blob'
  //     });
  //     const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
  //     const fileUrl = URL.createObjectURL(fileBlob);
  //     setBlobUrl(fileUrl);
  //     toast.update(id, { render: "Success. File will be opened shortly...", type: "success", isLoading: false, autoClose: 1500 });
  //   } catch (error) {
  //     toast.update(id, { render: "Something went wrong with the request.", type: "error", isLoading: false, autoClose: 1500 });
  //     console.log("Error fetching file:", error);
  //   }
  // };

  const handleDeleteClick = async (contentName, filekey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this document?`
    );
    if (!confirmDelete) {
      return;
    }
    let id = toast.loading("Deleting Load attachement.Please wait...");

    try {
      await axiosInstance.delete(
        `${API_ENDPOINTS.FILE}/${contentName}?loadId=${loadId}&fileKey=${filekey}`
      );
      toast.update(id, {
        render: "Load file deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      if (filekey === "rateCont") {
        const index = selectedFilesRC.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesRC((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "rateContDriver") {
        const index = selectedFilesRCD.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesRCD((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "billOfLanding") {
        const index = selectedFilesBOL.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesBOL((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "scaleTicket") {
        const index = selectedFilesST.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesST((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "lumperReceipt") {
        const index = selectedFilesLR.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesLR((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "miscellaneousDocuments") {
        const index = selectedFilesMD.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesMD((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "locationScreenshots") {
        const index = selectedFilesLS.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesLS((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else {
        console.log("Sorry, No Content Found for this key.");
      }
    } catch (error) {
      console.log("Error Deleting file:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  const fetchBrokers = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.BROKERNAMES);
      setBrokers(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };
  const fetchBrokerAgents = async () => {
    setBrokerAgents([]);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.AGENTNAMES, {
        params: { brokerKey: selectedBroker },
      });
      setBrokerAgents(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  const handleBrokerChange = (e) => {
    const selectedBrokerId = e.target.value;
    const selectedBroker1 = brokers.find(
      (broker) => String(broker[0]) === selectedBrokerId
    );
    if (selectedBroker1) {
      setSelectedBroker(selectedBroker1[0]);
      setValue("brokerName", selectedBroker1[1]);
      setValue("brokerMc", selectedBroker1[2]);
      setValue("brokerAfterHours", selectedBroker1[3]);
    }
  };

  const handleBrokerAgentChange = (e) => {
    const selectedAgentId = e.target.value;
    const selectedAgent = brokerAgents.find(
      (agent) => String(agent[0]) === selectedAgentId
    );
    if (selectedAgent) {
      setValue("brokerAgent", selectedAgent[1]);
      setValue("brokerPhone", selectedAgent[2]);
      setValue("brokerEmail", selectedAgent[3]);
    }
  };

  const handleDriverChange = (e) => {
    setAssignedDriverKey(e);
  };

  const handleDriver2Change = (e) => {
    setAssignedDriver2Key(e);
  };

  return (
    <div
      className="modal fade"
      id="modal3"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">
                Editing Load# {loadNumber}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="submit"
                className="btn reg-user-submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setRefreshEditModal(false);
                  setBlobUrl(null);
                }}
                id="closeButtonEditLoad"
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
              <ul className="nav nav-tabs" id="myTab2" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="load-details-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#load-details-edit"
                    type="button"
                    role="tab"
                    aria-controls="load-details-edit"
                    aria-selected="true"
                  >
                    Load Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="assigned-agents-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#assigned-agents-edit"
                    type="button"
                    role="tab"
                    aria-controls="assigned-agents-edit"
                    aria-selected="false"
                  >
                    Driver
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="shipper-details-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#shipper-details-edit"
                    type="button"
                    role="tab"
                    aria-controls="shipper-details-edit"
                    aria-selected="false"
                  >
                    Shipper Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="receiver-details-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#receiver-details-edit"
                    type="button"
                    role="tab"
                    aria-controls="receiver-details-edit"
                    aria-selected="false"
                  >
                    Receiver Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="broker-details-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#broker-details-edit"
                    type="button"
                    role="tab"
                    aria-controls="broker-details-edit"
                    aria-selected="false"
                  >
                    Broker Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="lumper-details-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#lumper-details-edit"
                    type="button"
                    role="tab"
                    aria-controls="lumper-details-edit"
                    aria-selected="false"
                  >
                    Lumper Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="documents-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#documents-edit"
                    type="button"
                    role="tab"
                    aria-controls="documents-edit"
                    aria-selected="false"
                  >
                    Documents
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="notes-edit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#notes-edit"
                    type="button"
                    role="tab"
                    aria-controls="notes-edit"
                    aria-selected="false"
                  >
                    Notes
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="load-details-edit"
                  role="tabpanel"
                  aria-labelledby="load-details-edit-tab"
                >
                  <div id="loadDetails" className="fw-bold text-center">
                    Load Details
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Label:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("label", {
                          required:
                            "Load Details -> Label : This field is required.",
                          maxLength: 100,
                        })}
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Load#</label>

                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("aalLoad", { maxLength: 50 })}
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Trailer#
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("vanNumber", { maxLength: 10 })}
                      />
                    </div>

                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Rate:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("rate", { maxLength: 10 })}
                      />
                    </div>
                    <div className="col-md-2 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Is TONU?{" "}
                      </label>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        {...register("isTonu", {})}
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Instructions:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("instruction", { maxLength: 255 })}
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Escalation:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("escalation", {})}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Det. Amount($):
                      </label>
                      <input
                        type="number"
                        className="form-control input-field"
                        {...register("detentionAmount", {})}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="assigned-agents-edit"
                  role="tabpanel"
                  aria-labelledby="assigned-agents-edit-tab"
                >
                  <div id="assignedagents" className="fw-bold text-center">
                    Driver
                  </div>
                  <hr className="rounded"></hr>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Driver:
                      </label>

                      <select
                        className="form-control input-field"
                        {...register("assignedDriverKey")}
                        defaultValue={assignedDriverKey}
                        onChange={(e) => handleDriverChange(e)}
                      >
                        <option key={"Choose..."} value={"Choose..."}>
                          Choose...
                        </option>
                        {driversList.map((driver) => (
                          <option key={driver[0]} value={driver[0]}>
                            {driver[1]}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Status:
                      </label>
                      <select
                        className="form-control input-field"
                        {...register("status")}
                      >
                        <option
                          value="Assigned"
                          disabled={loadStatusss === "Invoiced"}
                        >
                          Assigned
                        </option>
                        <option
                          value="Cancelled"
                          disabled={loadStatusss === "Invoiced"}
                        >
                          Cancelled
                        </option>
                        <option
                          value="Completed"
                          disabled={loadStatusss === "Invoiced"}
                        >
                          Completed
                        </option>
                        <option
                          value="Conflict"
                          disabled={loadStatusss === "Invoiced"}
                        >
                          Conflict
                        </option>
                        {role === "admin" && (
                          <option value="Invoiced" disabled>
                            Invoiced
                          </option>
                        )}
                        <option
                          value="Unassigned"
                          disabled={loadStatusss === "Invoiced"}
                        >
                          Unassigned
                        </option>
                        <option
                          value="TONU"
                          disabled={loadStatusss === "Invoiced"}
                        >
                          TONU
                        </option>
                      </select>
                    </div>
                  </div>

                  {secondDriverVisible && (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Driver#2:
                          </label>

                          <select
                            className="form-control input-field"
                            {...register("assignedDriver2Key")}
                            defaultValue={assignedDriver2Key}
                            onChange={(e) => handleDriver2Change(e)}
                          >
                            <option key={"Choose..."} value={"Choose..."}>
                              Choose...
                            </option>
                            {driversList.map((driver) => (
                              <option key={driver[0]} value={driver[0]}>
                                {driver[1]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row ">
                    <div className="col-md-12 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleSecondDriver}
                      >
                        Add Second Driver
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="shipper-details-edit"
                  role="tabpanel"
                  aria-labelledby="shipper-details-edit-tab"
                >
                  <div
                    id="shipperDetails"
                    className=" fw-bold text-center mt-4"
                  >
                    Shipper Details
                  </div>{" "}
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Name:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("shipperName", { maxLength: 100 })}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Address:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("shipperAddress", { maxLength: 255 })}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Start Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("shipperStartDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("shipperStartWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Shipper Details -> Shipper -> Start Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            End Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("shipperEndDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("shipperEndWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Shipper Details -> Shipper -> End Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Instructions:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("shipperInstruction", {})}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check In:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("shipperCheckInDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("shipperCheckInWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Shipper Details -> Shipper -> Check In Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check Out:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("shipperCheckOutDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("shipperCheckOutWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Shipper Details -> Shipper -> Check Out Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleSecondShipper}
                      >
                        View Second Pickup
                      </button>
                    </div>
                  </div>
                  {secondShipperVisible && (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Name:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("shipper2Name", { maxLength: 100 })}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Address:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("shipper2Address", { maxLength: 255 })}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                Start Date:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("shipper2StartDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("shipper2StartWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Shipper Details -> Shipper#2 -> Start Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                End Date:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("shipper2EndDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("shipper2EndWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Shipper Details -> Shipper#2 -> End Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Instructions:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("shipper2Instruction", {})}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                Check In:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("shipper2CheckInDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("shipper2CheckInWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Shipper Details -> Shipper#2 -> Check In Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                Check Out:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("shipper2CheckOutDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("shipper2CheckOutWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Shipper Details -> Shipper#2 -> Check Out Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="receiver-details-edit"
                  role="tabpanel"
                  aria-labelledby="receiver-details-edit-tab"
                >
                  <div
                    id="receiverDetails"
                    className="fw-bold text-center mt-4"
                  >
                    Reciever Details
                  </div>{" "}
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Name:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("receiverName", { maxLength: 100 })}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Address:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("receiverAddress", { maxLength: 255 })}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Start Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("receiverStartDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("receiverStartWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Receiver Details -> Receiver -> Start Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            End Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("receiverEndDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("receiverEndWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Receiver Details -> Receiver -> End Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Instructions:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("receiverInstruction", {})}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check In:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("receiverCheckInDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("receiverCheckInWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Receiver Details -> Receiver -> Check In Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1 single-line">
                            Check Out:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field"
                            {...register("receiverCheckOutDate")}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("receiverCheckOutWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Receiver Details -> Receiver -> Check Out Time: Enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleSecondReceiver}
                      >
                        Add Second DropOff
                      </button>
                    </div>
                  </div>
                  {secondReceiverVisible && (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Name:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("receiver2Name", { maxLength: 100 })}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Address:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("receiver2Address", {
                              maxLength: 255,
                            })}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                Start Date:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("receiver2StartDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("receiver2StartWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Receiver Details -> Receiver#2 -> Start Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                End Date:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("receiver2EndDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("receiver2EndWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Receiver Details -> Receiver#2 -> End Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-12 d-flex align-items-end">
                          <label className="col-form-label pb-0 mx-1">
                            Instructions:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            {...register("receiver2Instruction", {})}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                Check In:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("receiver2CheckInDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("receiver2CheckInWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Receiver Details -> Receiver#2 -> Check In Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1 single-line">
                                Check Out:
                              </label>
                              <input
                                type="date"
                                className="form-control input-field"
                                {...register("receiver2CheckOutDate")}
                              />
                            </div>
                            <div className="col-md-6 d-flex align-items-end">
                              <label className="col-form-label pb-0 mx-1">
                                Time:
                              </label>
                              <input
                                type="text"
                                className="form-control input-field"
                                {...register("receiver2CheckOutWaqt", {
                                  pattern: {
                                    value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                    message:
                                      "Receiver Details -> Receiver#2 -> Check Out Time: Enter time in format hhmm (24-hour format)",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="broker-details-edit"
                  role="tabpanel"
                  aria-labelledby="broker-details-edit-tab"
                >
                  <div id="brokerDetails" className="fw-bold text-center mt-4">
                    Broker Details
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex flex-column">
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Select:
                        </label>
                        <select
                          className="form-control input-field"
                          {...register("assignedBrokerUserName")}
                          onFocus={fetchBrokers}
                          defaultValue={"Choose..."}
                          onChange={(e) => handleBrokerChange(e)}
                        >
                          <option value="Choose...">Choose...</option>
                          {brokers.map((broker) => (
                            <option key={broker[0]} value={broker[0]}>
                              {broker[1] + (broker[2] ? ` (${broker[2]})` : "")}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Broker:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("brokerName", { maxLength: 100 })}
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">MC#:</label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("brokerMc", { maxLength: 50 })}
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          After Hours:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("brokerAfterHours", { maxLength: 50 })}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 d-flex flex-column">
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Select:
                        </label>
                        <select
                          className="form-control input-field"
                          {...register("assignedBrokerAgentUserName")}
                          onFocus={fetchBrokerAgents}
                          defaultValue={"Choose..."}
                          onChange={(e) => handleBrokerAgentChange(e)}
                        >
                          <option value="Choose...">Choose...</option>
                          {brokerAgents.map((ba) => (
                            <option key={ba[0]} value={ba[0]}>
                              {ba[1]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Agent:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("brokerAgent", { maxLength: 100 })}
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Phone:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("brokerPhone", { maxLength: 50 })}
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Email:
                        </label>
                        <input
                          type="email"
                          className="form-control input-field"
                          {...register("brokerEmail", { maxLength: 100 })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="lumper-details-edit"
                  role="tabpanel"
                  aria-labelledby="lumper-details-edit-tab"
                >
                  <div id="lumperDetails" className="fw-bold text-center mt-4">
                    Lumper Details
                  </div>{" "}
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Name:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("lumperName", { maxLength: 100 })}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Email:</label>
                      <input
                        type="email"
                        className="form-control input-field"
                        {...register("lumperEmail", { maxLength: 100 })}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Amount(USD):
                      </label>
                      <input
                        type="number"
                        className="form-control input-field"
                        {...register("lumperAmount", { maxLength: 10 })}
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Paid by:
                      </label>
                      <select
                        className="form-control input-field"
                        {...register("lumperPaidBy")}
                        defaultValue={"Choose..."}
                      >
                        <option value="Choose...">Choose...</option>
                        <option value="Broker">Broker</option>
                        <option value="Driver">Driver</option>
                      </select>
                    </div>
                    <div className="col-md-4 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Paid with:
                      </label>
                      <select
                        className="form-control input-field"
                        {...register("lumperPaidWith")}
                        defaultValue={"Choose..."}
                      >
                        <option value="Choose...">Choose...</option>
                        <option value="Cash">Cash</option>
                        <option value="EFSCode">EFS Code</option>
                        <option value="CreditCard">Credit Card</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="documents-edit"
                  role="tabpanel"
                  aria-labelledby="documents-edit-tab"
                >
                  <div id="documents" className="fw-bold text-center mt-4">
                    Documents
                  </div>{" "}
                  <hr className="rounded"></hr>
                  {blobUrl && (
                    <>
                      <div className="d-flex flex-row-reverse">
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => setBlobUrl(null)}
                        >
                          Close
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {fileName.endsWith(".pdf") ? (
                            <embed
                              className="iframedriver"
                              src={blobUrl}
                            ></embed>
                          ) : (
                            <img
                              width={"100%"}
                              height="auto"
                              src={blobUrl}
                              alt="Image"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Rate Confirmation:
                      </label>
                      <input
                        type="file"
                        className="form-control input-field"
                        onChange={handleFileChangeRC2}
                      />
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesRC !== null && (
                        <ul>
                          {selectedFilesRC.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() =>
                                  handleDeleteClick(file, "rateCont")
                                }
                              >
                                Delete
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                      {selectedFilesRC2 !== null && (
                        <ul>
                          {selectedFilesRC2.map((file) => (
                            <li key={file.name}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleFileDeleteRC2(file.name)}
                              >
                                Remove
                              </button>
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Driver's Rate Confirmation:
                      </label>
                      <input
                        type="file"
                        className="form-control input-field"
                        onChange={handleFileChangeRCD2}
                      />
                    </div>
                    <div className="col-md-6 card">
                      {/* {selectedFilesRCD !== null &&
                          <ul>
                            {selectedFilesRCD.map((file) => (
                              <li key={file}>
                                <button type="button" className="me-2 mt-1 btn btn-outline-danger btn-sm" onClick={() => handleViewClick(file)}>
                                  View
                                </button>
                                <button type="button" className="me-2 mt-1 btn btn-outline-danger btn-sm" onClick={() => handleDeleteClick(file, 'rateContDriver')}>
                                  Delete
                                </button>
                                {file}
                              </li>
                            ))}
                          </ul>
                        } */}
                      {selectedFilesRCD !== null && (
                        <ul>
                          {selectedFilesRCD.map((file) => {
                            // const isImage = /\.(jpg|jpeg|png)$/i.test(file);

                            return (
                              <li key={file}>
                                <button
                                  type="button"
                                  className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                  onClick={() => handleViewClick(file)}
                                >
                                  View
                                </button>
                                <button
                                  type="button"
                                  className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                  onClick={() =>
                                    handleDeleteClick(file, "rateContDriver")
                                  }
                                >
                                  Delete
                                </button>
                                {/* {isImage && (
                                    <button
                                      type="button"
                                      className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                      onClick={() => handleConvertToPdfClick(file)}
                                    >
                                      Convert to PDF
                                    </button>
                                  )} */}
                                {file}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {selectedFilesRCD2 !== null && (
                        <ul>
                          {selectedFilesRCD2.map((file) => (
                            <li key={file.name}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleFileDeleteRCD2(file.name)}
                              >
                                Remove
                              </button>
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Bill of Lading:</label>
                      <input
                        type="file"
                        className="form-control input-field"
                        onChange={handleFileChangeBOL2}
                      />
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesBOL !== null && (
                        <ul>
                          {selectedFilesBOL.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() =>
                                  handleDeleteClick(file, "billOfLanding")
                                }
                              >
                                Delete
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}

                      {selectedFilesBOL2 !== null && (
                        <ul>
                          {selectedFilesBOL2.map((file) => (
                            <li key={file.name}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleFileDeleteBOL2(file.name)}
                              >
                                Remove
                              </button>
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Scale Ticket:</label>
                      <input
                        type="file"
                        className="form-control input-field"
                        onChange={handleFileChangeST2}
                      />
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesST !== null && (
                        <ul>
                          {selectedFilesST.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() =>
                                  handleDeleteClick(file, "scaleTicket")
                                }
                              >
                                Delete
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}

                      {selectedFilesST2 !== null && (
                        <ul>
                          {selectedFilesST2.map((file) => (
                            <li key={file.name}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleFileDeleteST2(file.name)}
                              >
                                Remove
                              </button>
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Lumper Reciept:</label>
                      <input
                        type="file"
                        className="form-control input-field"
                        onChange={handleFileChangeLR2}
                      />
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesLR !== null && (
                        <ul>
                          {selectedFilesLR.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() =>
                                  handleDeleteClick(file, "lumperReceipt")
                                }
                              >
                                Delete
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}

                      {selectedFilesLR2 !== null && (
                        <ul>
                          {selectedFilesLR2.map((file) => (
                            <li key={file.name}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleFileDeleteLR2(file.name)}
                              >
                                Remove
                              </button>
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Location Screenshots:
                      </label>
                      <input
                        type="file"
                        className="form-control input-field"
                        onChange={handleFileChangeLS2}
                      />
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesLS !== null && (
                        <ul>
                          {selectedFilesLS.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() =>
                                  handleDeleteClick(file, "locationScreenshots")
                                }
                              >
                                Delete
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}

                      {selectedFilesLS2 !== null && (
                        <ul>
                          {selectedFilesLS2.map((file) => (
                            <li key={file.name}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleFileDeleteLS2(file.name)}
                              >
                                Remove
                              </button>
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Miscellaneous Documents:
                      </label>
                      <input
                        type="file"
                        className="form-control input-field"
                        onChange={handleFileChangeMD2}
                      />
                    </div>
                    <div className="col-md-6 card">
                      {selectedFilesMD !== null && (
                        <ul>
                          {selectedFilesMD.map((file) => (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() =>
                                  handleDeleteClick(
                                    file,
                                    "miscellaneousDocuments"
                                  )
                                }
                              >
                                Delete
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}

                      {selectedFilesMD2 !== null && (
                        <ul>
                          {selectedFilesMD2.map((file) => (
                            <li key={file.name}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleFileDeleteMD2(file.name)}
                              >
                                Remove
                              </button>
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="notes-edit"
                  role="tabpanel"
                  aria-labelledby="notes-edit-tab"
                >
                  <div id="notesEdit " className="fw-bold text-center mt-4">
                    Notes
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <textarea
                        type="text"
                        rows="7"
                        cols="40"
                        className="form-control"
                        {...register("dispatchNotes")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {errors.label && (
                <div className="text-danger">{errors.label.message}</div>
              )}
              {errors.shipperStartWaqt && (
                <div className="text-danger">
                  {errors.shipperStartWaqt.message}
                </div>
              )}
              {errors.shipperEndWaqt && (
                <div className="text-danger">
                  {errors.shipperEndWaqt.message}
                </div>
              )}
              {errors.shipperCheckInWaqt && (
                <div className="text-danger">
                  {errors.shipperCheckInWaqt.message}
                </div>
              )}
              {errors.shipperCheckOutWaqt && (
                <div className="text-danger">
                  {errors.shipperCheckOutWaqt.message}
                </div>
              )}
              {errors.shipper2StartWaqt && (
                <div className="text-danger">
                  {errors.shipper2StartWaqt.message}
                </div>
              )}
              {errors.shipper2EndWaqt && (
                <div className="text-danger">
                  {errors.shipper2EndWaqt.message}
                </div>
              )}
              {errors.shipper2CheckInWaqt && (
                <div className="text-danger">
                  {errors.shipper2CheckInWaqt.message}
                </div>
              )}
              {errors.shipper2CheckOutWaqt && (
                <div className="text-danger">
                  {errors.shipper2CheckOutWaqt.message}
                </div>
              )}
              {errors.receiverStartWaqt && (
                <div className="text-danger">
                  {" "}
                  {errors.receiverStartWaqt.message}
                </div>
              )}
              {errors.receiverEndWaqt && (
                <div className="text-danger">
                  {errors.receiverEndWaqt.message}
                </div>
              )}
              {errors.receiverCheckInWaqt && (
                <div className="text-danger">
                  {errors.receiverCheckInWaqt.message}
                </div>
              )}
              {errors.receiverCheckOutWaqt && (
                <div className="text-danger">
                  {errors.receiverCheckOutWaqt.message}
                </div>
              )}
              {errors.receiver2StartWaqt && (
                <div className="text-danger">
                  {errors.receiver2StartWaqt.message}
                </div>
              )}
              {errors.receiver2EndWaqt && (
                <div className="text-danger">
                  {errors.receiver2EndWaqt.message}
                </div>
              )}
              {errors.receiver2CheckInWaqt && (
                <div className="text-danger">
                  {errors.receiver2CheckInWaqt.message}
                </div>
              )}
              {errors.receiver2CheckOutWaqt && (
                <div className="text-danger">
                  {errors.receiver2CheckOutWaqt.message}
                </div>
              )}
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditLoad;
