import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const EditBroker = ({
  onSaveSuccess,
  selectedData,
  setRefreshEditModal,
  refreshEditModal,
  userRole,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue, // Added setValue from react-hook-form
  } = useForm();

  const [selectedFilesCP, setSelectedFilesCP] = useState([]);
  const [selectedFilesCP2, setSelectedFilesCP2] = useState([]);
  const [brokerfiles, setBrokerFiles] = useState({
    brokerContractPacket: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false); // State variable to trigger table reload
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const handleFileChangeCP2 = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFilesCP2([...selectedFilesCP2, ...files]);
  };

  const handleFileDeleteCP2 = (fileName) => {
    const updatedFiles = selectedFilesCP2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesCP2(updatedFiles);
  };

  const fetchFiles = async (brokerId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GETBROKERFILES, {
        params: { brokerId: brokerId },
      });
      const tempData = response.data.message[0];
      // Check if brokerContractPacket is an array
      if (Array.isArray(tempData["brokerContractPacket"])) {
        setBrokerFiles((prevBrokerFiles) => ({
          ...prevBrokerFiles,
          ["brokerContractPacket"]: tempData["brokerContractPacket"],
        }));
      } else {
        console.log("Invalid Data Structure for brokerContractPacket");
      }

      // Handle potentially null values using nullish coalescing operator (??)
      setValue("brokerEmail", tempData["brokerEmail"] ?? "");
      setValue("brokerPayment", tempData["brokerPayment"] ?? "");
      setValue("brokerRemittance", tempData["brokerRemittance"] ?? "");
      setValue("brokerStatus", tempData["brokerStatus"] ?? "Disabled"); // Assuming "Inactive" is the fallback if null
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching broker to edit:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedData && refreshEditModal) {
      setIsLoading(true);
      setValue("brokerName", selectedData[1]);
      setValue("mcNumber", selectedData[2]);
      setValue("brokerPhone", selectedData[3]);
      setValue("brokerAfterHours", selectedData[4]);
      setValue("brokerBillingEmail", selectedData[5]);
      fetchFiles(selectedData[0]);
    }
  }, [selectedData, reloadTable, refreshEditModal]);

  useEffect(() => {
    setSelectedFilesCP(brokerfiles.brokerContractPacket);
    setIsLoading(false);
  }, [brokerfiles]);

  const handleDownloadClick = async (contentName) => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.BROKERFILESACTION}/${contentName}?brokerId=${selectedData[0]}`
      );
      const fileUrl = response.data.url;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.log("Error fetching file:", error);
    }
  };

  const handleDeleteClick = async (contentName, filekey) => {
    let id = toast.loading("Deleting Broker Contract File.Please wait..."); // Update id inside the try block
    try {
      await axiosInstance.delete(
        `${API_ENDPOINTS.BROKERFILESACTION}/${contentName}?brokerId=${selectedData[0]}&fileKey=${filekey}`
      );
      toast.update(id, {
        render: "Contract Packet deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      setReloadTable((prevReloadTable) => !prevReloadTable);
    } catch (error) {
      console.log("Error Deleting file:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating Broker details.Please wait..."); // Update id inside the try block
    try {
      const formData = new FormData();

      Object.keys(data).forEach((val) => {
        if (data[val]) {
          formData.append(val, data[val]);
        }
      });

      selectedFilesCP2.forEach((file) => {
        formData.append("brokerContractPacket", file);
      });
      await axiosInstance.patch(API_ENDPOINTS.BROKERS, formData, {
        params: { brokerId: selectedData[0] },
      });
      toast.update(id, {
        render: "Broker details updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      onSaveSuccess();
      reset();
      setSelectedFilesCP([]);
      setSelectedFilesCP2([]);
      setReloadTable((prevReloadTable) => !prevReloadTable);
      document.getElementById("closeButtonEditBroker").click();
    } catch (error) {
      console.error("Error:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="modalEditBroker"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">Editing Broker</h1>
          </div>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label fw-bold">Name:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerName", {
                      required: true,
                      maxLength: 255,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label fw-bold">MC#</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("mcNumber", { maxLength: 255 })}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label fw-bold">Phone:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerPhone", {
                      maxLength: 255,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label fw-bold">Email:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerEmail", { maxLength: 255 })}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">After Hours:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerAfterHours", { maxLength: 255 })}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Billing Email:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerBillingEmail", { maxLength: 255 })}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">
                    Remittance Email:
                  </label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerRemittance", { maxLength: 255 })}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Payment Inquiry:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerPayment", { maxLength: 255 })}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Status:</label>
                  <select
                    className="form-control create-input"
                    {...register("status")}
                  >
                    <option value="Active">Active</option>
                    <option value="Disabled">Disabled</option>
                  </select>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Contract Packet:</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChangeCP2}
                  />
                  {selectedFilesCP !== null && (
                    <ul>
                      {selectedFilesCP.map((file) => (
                        <li key={file}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-success btn-sm"
                            onClick={() => handleDownloadClick(file)}
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() =>
                              handleDeleteClick(file, "brokerContractPacket")
                            }
                          >
                            Delete
                          </button>
                          {file}
                        </li>
                      ))}
                    </ul>
                  )}
                  {selectedFilesCP2 !== null && (
                    <ul>
                      {selectedFilesCP2.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() => handleFileDeleteCP2(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div>
                <button type="submit" className="btn reg-user-submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    setRefreshEditModal(false);
                    setSelectedFilesCP(null);
                  }}
                  id="closeButtonEditBroker"
                >
                  Close
                </button>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditBroker;
