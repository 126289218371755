import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const DriverWeeklyReport = ({
  selectedDataId,
  reportStartDate,
  reportEndDate,
}) => {
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const { register, handleSubmit, setValue, reset, watch } = useForm();
  const [userData, setUserData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  async function get_user_account_details(selectedKey, startDatee, endDatee) {
    setIsLoading(true);
    try {
      let date = new Date(startDatee);
      const formattedDateStart = date.toISOString().split("T")[0];
      date = new Date(endDatee);
      const formattedDateEnd = date.toISOString().split("T")[0];
      const response = await axiosInstance.get(
        API_ENDPOINTS.DRIVERWEEKLYREPORTS,
        {
          params: {
            userName: selectedKey,
            startDate: formattedDateStart,
            endDate: formattedDateEnd,
          },
        }
      );
      const data = response.data.message.loads;
      setUserData(data);

      let totalGrandTotal = 0;

      data.forEach((item, index) => {
        setValue(`data[${index}].loadId`, item[0]);
        setValue(`data[${index}].label`, item[1]);
        setValue(`data[${index}].shipperStartTime`, item[2]);
        setValue(`data[${index}].driver`, item[3]);
        setValue(`data[${index}].driver2`, item[4]);
        setValue(`data[${index}].driverName`, item[5]);
        setValue(`data[${index}].driver2Name`, item[6]);
        setValue(`data[${index}].detentionAmount`, item[7]);
        if (item[8] === 1) {
          setValue(`data[${index}].isTonu`, "Yes");
        } else {
          setValue(`data[${index}].isTonu`, "No");
        }

        setValue(`data[${index}].lumperAmount`, item[9]);
        setValue(`data[${index}].baseRate`, item[10]);

        const totalRate =
          parseFloat(item[7] || 0) +
          parseFloat(item[9] || 0) +
          parseFloat(item[10] || 0);
        setValue(`data[${index}].totalRate`, totalRate);

        totalGrandTotal += totalRate;
      });

      setValue("reportData.grandTotal", totalGrandTotal);

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth() is zero-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, "0");
        return month + day;
      };

      const data2 = response.data.message.account[0];
      setValue(
        `reportData.reportNumber`,
        selectedDataId +
          " - " +
          formatDate(reportStartDate) +
          " - " +
          formatDate(reportEndDate)
      );
      setValue(`reportData.accountName`, data2.accountName);
      setValue(`reportData.accountNumber`, data2.accountDetails);

      setValue(`loanData[0].Detail`, data2.notes);
      setValue(`loanData[0].Amount`, data2.loan);

      setValue(`loanData[1].Detail`, "Loan Remaining");
    } catch (error) {
      console.error("Error getting userlist:", error);
    }
    setIsLoading(false);
  }

  function extractContentBetweenBrackets(str) {
    const match = str.match(/\[(.*?)\]/);
    return match ? match[1] : null;
  }

  function extractWordAfterPeriod(str) {
    const parts = str.split(".");
    return parts.length > 1 ? parts[1] : null;
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type);
      if (type === "change") {
        if (
          name &&
          name.substring(0, 4) === "data" &&
          ["detentionAmount", "lumperAmount", "baseRate"].includes(
            extractWordAfterPeriod(name)
          )
        ) {
          const extractedContent = extractContentBetweenBrackets(name);

          // Parse values or default to 0 if empty string
          const detentionAmount =
            parseFloat(value.data[extractedContent].detentionAmount) || 0;
          const lumperAmount =
            parseFloat(value.data[extractedContent].lumperAmount) || 0;
          const baseRate =
            parseFloat(value.data[extractedContent].baseRate) || 0;

          // Calculate totalRate
          const totalRate = detentionAmount + lumperAmount + baseRate;

          // Set value with setValue function
          setValue(`data[${extractedContent}].totalRate`, totalRate);
        }

        if (value && name) {
          // Calculate grandTotal
          let summedTotal = 0;
          for (let i = 0; i < value.data.length; i++) {
            summedTotal += parseFloat(value.data[i].totalRate) || 0;
          }

          for (let i = 0; i < value.addData.length; i++) {
            summedTotal += parseFloat(value.addData[i].Amount) || 0;
          }

          // Set grandTotal in reportData
          setValue("reportData.grandTotal", summedTotal);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (selectedDataId && reportStartDate && reportEndDate) {
      reset();
      get_user_account_details(selectedDataId, reportStartDate, reportEndDate);
    }
  }, [selectedDataId, reportStartDate, reportEndDate]);

  const onSubmit = async (data, addData, reportData) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating user details. Please wait...");
    try {
      let response = await axiosInstance.post(
        API_ENDPOINTS.DRIVERWEEKLYREPORTS,
        data,
        addData,
        reportData
      );
      toast.update(id, {
        render: "User finances updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      document.getElementById("closeButtonEditFinances").click();
      const fileUrl = response.data.message[2];
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error updating user finances:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="modal3"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">
              Weekly Report for Driver: {selectedDataId}
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeButtonEditFinances"
            ></button>
            <button
              type="submit"
              className="btn reg-user-submit"
              onClick={handleSubmit(onSubmit)}
            >
              Save Changes
            </button>
          </div>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
              <div className="row mb-2">
                <div className="col-md-3 d-flex align-items-end">
                  <label className="col-form-label pb-0 mx-1">Report#</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("reportData.reportNumber", { maxLength: 50 })}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-end">
                  <label className="col-form-label pb-0 mx-1 single-line">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("reportData.accountName", { maxLength: 50 })}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-end">
                  <label className="col-form-label pb-0 mx-1 single-line">
                    Paid By:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("reportData.accountNumber", { maxLength: 50 })}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-end">
                  <label className="col-form-label pb-0 mx-1 single-line">
                    Grand:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("reportData.grandTotal", { maxLength: 50 })}
                  />
                </div>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Start Time</th>
                    <th>Tonu</th>
                    <th>Detention</th>
                    <th>Lumper</th>
                    <th>Base Rate</th>
                    <th>Total</th>
                    <th>Driver Name</th>
                    <th>Driver 2 Name</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((item, index) => (
                    <tr key={item[0]}>
                      <td>
                        <input
                          type="text"
                          {...register(`data[${index}].label`)}
                          readOnly
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="datetime-local"
                          {...register(`data[${index}].shipperStartTime`)}
                          readOnly
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="text"
                          {...register(`data[${index}].isTonu`)}
                          readOnly
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="number"
                          {...register(`data[${index}].detentionAmount`)}
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="number"
                          {...register(`data[${index}].lumperAmount`)}
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="number"
                          {...register(`data[${index}].baseRate`)}
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="number"
                          {...register(`data[${index}].totalRate`)}
                          readOnly
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="text"
                          {...register(`data[${index}].driverName`)}
                          readOnly
                        />
                      </td>
                      <td className="table-size-tonu">
                        <input
                          type="text"
                          {...register(`data[${index}].driver2Name`)}
                          readOnly
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th>Detail</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    <td className="table-size-tonu">
                      <input
                        type="text"
                        {...register(`addData[${0}].Detail`)}
                      />
                    </td>
                    <td className="table-size-tonu">
                      <input
                        type="number"
                        defaultValue={0}
                        {...register(`addData[${0}].Amount`)}
                      />
                    </td>
                  </tr>
                  <tr key={2}>
                    <td className="table-size-tonu">
                      <input
                        type="text"
                        {...register(`addData[${1}].Detail`)}
                      />
                    </td>
                    <td className="table-size-tonu">
                      <input
                        type="number"
                        defaultValue={0}
                        {...register(`addData[${1}].Amount`)}
                      />
                    </td>
                  </tr>
                  <tr key={3}>
                    <td className="table-size-tonu">
                      <input
                        type="text"
                        {...register(`addData[${2}].Detail`)}
                      />
                    </td>
                    <td className="table-size-tonu">
                      <input
                        type="number"
                        defaultValue={0}
                        {...register(`addData[${2}].Amount`)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th>Loan Detail</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    <td className="table-size-tonu">
                      <input
                        type="text"
                        {...register(`loanData[${0}].Detail`)}
                      />
                    </td>
                    <td className="table-size-tonu">
                      <input
                        type="number"
                        defaultValue={0}
                        {...register(`loanData[${0}].Amount`)}
                      />
                    </td>
                  </tr>
                  <tr key={2}>
                    <td className="table-size-tonu">
                      <input
                        type="text"
                        {...register(`loanData[${1}].Detail`)}
                      />
                    </td>
                    <td className="table-size-tonu">
                      <input
                        type="number"
                        defaultValue={0}
                        {...register(`loanData[${1}].Amount`)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          ) : (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverWeeklyReport;
