import React, { useState } from "react";
import { useForm } from "react-hook-form";

const LoadFilter = ({ sendData }) => {
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => sendData(data);

  const clearFields = () => {
    reset();
    sendData({
      dateShipper: "",
      startDate: "",
      endDate: "",
      dispatcher: "",
      driver: "",
      loadno: "",
      brokerName: "",
      sortBy: "Ascending",
      status: "All",
    });
  };

  return (
    <div className="p-0 card mt-3">
      <div className="card-header fw-bold">Filter</div>
      <form className="card-body" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <label className="form-label">Load#</label>
          <input
            type="text"
            className="form-control"
            {...register("loadno", { maxLength: 100 })}
          />
        </div>
        <div className="row mb-2">
          <div className="col-md-4">
            <label className="form-label">Broker:</label>
            <input
              type="text"
              className="form-control"
              {...register("brokerName", { maxLength: 100 })}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Dispatcher:</label>
            <input
              type="text"
              className="form-control"
              {...register("dispatcher", {})}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Driver:</label>
            <input
              type="text"
              className="form-control"
              {...register("driver", {})}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-4">
            <label className="form-label">Status:</label>
            <select
              className="form-select"
              {...register("status")}
              defaultValue="All"
            >
              <option value="All">All</option>
              <option value="Assigned">Assigned</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Completed">Completed</option>
              <option value="Conflict">Conflict</option>
              <option value="Invoiced">Invoiced</option>
              <option value="Unassigned">Unassigned</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Sort By:</label>
            <select className="form-select" {...register("sortBy")}>
              <option value="Ascending">Ascending</option>
              <option value="Descending">Descending</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Shipper Start Date:</label>
            <input
              type="date"
              className="form-control"
              {...register("dateShipper", {})}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6">
            <label className="form-label">Start Date:</label>
            <input
              type="date"
              className="form-control"
              {...register("startDate", {})}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">End Date:</label>
            <input
              type="date"
              className="form-control"
              {...register("endDate", {})}
            />
          </div>
        </div>

        <div className="text-center mb-2">
          <button type="submit" className="btn reg-user-submit">
            Filter
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={clearFields}
          >
            Clear Filter
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoadFilter;
