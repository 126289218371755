const PageTitle = ({ title, description }) => {
  return (
    <div style={{ margin: "10px 0" }}>
      <div
        className="card p-0"
        style={{
          border: "none",
          boxShadow: "0 -5px 3px -3px #012266, 0 5px 3px -3px #012266",
          padding: "20px",
        }}
      >
        <h1 className="text-center">{title}</h1>
        {/* <div className='text-center' style={{ marginTop: '10px' }}>
          {description}
        </div> */}
      </div>
    </div>
  );
};

export default PageTitle;
