import React, { useState, useEffect } from "react";
import {
  FaDollarSign,
  FaExclamationCircle,
  FaEye,
  FaFileAlt,
  FaPager,
  FaPencilAlt,
  FaTrash,
  FaTruck,
  FaUsers,
} from "react-icons/fa";
import "../../Styling/AgentList.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import CreateLoad from "../../Components/AdminDispatcher/CreateLoad";
import ViewLoad from "../../Components/AdminDispatcher/ViewLoad";
import EditLoad from "../../Components/AdminDispatcher/EditLoad";
import InvoiceLoad from "../../Components/Admin/InvoiceLoad";
import LoadFilter from "../AdminDispatcher/LoadFilter";
import TableLoader from "../Shared/TableLoader";
import { toast } from "react-toastify";
import TruckIcon from "../../Assets/TruckIcon.png";
import "react-toastify/dist/ReactToastify.css";
import AddDocuments from "./AddDocuments";

const LoadsTableAdmin = ({ userRole }) => {
  const [myData, setMyData] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState(null); // State variable to store the selected user data
  const [selectedLoadNumber, setSelectedLoadNumber] = useState(null); // State variable to store the selected user data
  const [selectedLoadStatus, setSelectedLoadStatus] = useState(null); // State variable to store the selected user data
  const [pageLimit, setPageLimit] = useState(1);
  const [reloadTable, setReloadTable] = useState(false); // State variable to trigger table reload
  const [page, setPage] = useState(1);
  const [loadCount, setLoadCount] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadFilterVisible, setIsLoadFilterVisible] = useState(false);
  const [refreshViewModal, setRefreshViewModal] = useState(false);
  const [refreshEditModal, setRefreshEditModal] = useState(false);
  const [refreshInvoiceModal, setRefreshInvoiceModal] = useState(false);
  const [refreshAddDocumentsModal, setRefreshAddDocumentsModal] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(true);
  const [filterData, setFilterData] = useState({
    dateShipper: "",
    startDate: "",
    endDate: "",
    dispatcher: "",
    driver: "",
    loadno: "",
    brokerName: "",
    sortBy: "Ascending",
    status: "Default",
  });

  async function getData(params) {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.LOADS, {
        params: params,
      });
      const { data } = response.data;
      setMyData(data["items"]);
      setPageLimit(data["pagination"]["totalPages"]);
    } catch (error) {
      console.error("Error:", error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData({
      pageId: page,
      loadCountId: loadCount,
      ...filterData,
    });
  }, [reloadTable, page, loadCount, filterData]);

  const handleDeleteRow = async (loadId) => {
    if (isDeleting === true) {
      setIsDeleting(false);
      const confirmDelete = window.confirm(
        `Are you sure you want to delete load with label? This will also delete the supporting documents.`
      );
      if (!confirmDelete) {
        setIsDeleting(true);
        return;
      }
      let id = toast.loading("Load is being removed. Please wait... "); // Update id inside the try block
      try {
        await axiosInstance.delete(API_ENDPOINTS.LOADS, { params: { loadId } });
        setPageLimit(pageLimit - 1);
        if (page > 1) {
          setPage(page - 1);
        }
        toast.update(id, {
          render: "Successful, Load was deleted.",
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });
        setReloadTable((prevReloadTable) => !prevReloadTable);
        setIsDeleting(true);
      } catch (error) {
        console.error("Error:", error);
        toast.update(id, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 1500,
        });
        setIsDeleting(true);
      }
    } else {
      window.alert("System is Busy. Please Try Again Later");
    }
  };

  const handleSaveSuccess = () => {
    setReloadTable((prevReloadTable) => !prevReloadTable);
  };

  const handleViewRow = (loadId, aalLoad) => {
    setSelectedLoad(loadId);
    setSelectedLoadNumber(aalLoad);
    setRefreshViewModal(true);
  };

  const handleEditRow = (loadId, aalLoad, lstatus) => {
    setSelectedLoad(loadId);
    setSelectedLoadNumber(aalLoad);
    setSelectedLoadStatus(lstatus);
    setRefreshEditModal(true);
  };

  const handleEditDocumentRow = (loadId, aalLoad, lstatus) => {
    setSelectedLoad(loadId);
    setSelectedLoadNumber(aalLoad);
    setSelectedLoadStatus(lstatus);
    setRefreshAddDocumentsModal(true);
  };

  const handleInvoiceRow = (loadId, aalLoad) => {
    setSelectedLoad(loadId);
    setSelectedLoadNumber(aalLoad);
    setRefreshInvoiceModal(true);
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const handleLoadCountChange = (selectedValue) => {
    setLoadCount(selectedValue);
    setPage(1);
  };

  const toggleLoadFilter = () => {
    setIsLoadFilterVisible((prev) => !prev);
  };

  const getCompletedLoads = () => {
    setPage(1);
    setFilterData({
      dateShipper: "",
      startDate: "",
      endDate: "",
      dispatcher: "",
      driver: "",
      loadno: "",
      brokerName: "",
      sortBy: "Ascending",
      status: "Completed",
    });
  };

  const getInvoicedLoads = () => {
    setPage(1);
    setFilterData({
      dateShipper: "",
      startDate: "",
      endDate: "",
      dispatcher: "",
      driver: "",
      loadno: "",
      brokerName: "",
      sortBy: "Descending",
      status: "Invoiced",
    });
  };

  const getConflictLoads = () => {
    setPage(1);
    setFilterData({
      dateShipper: "",
      startDate: "",
      endDate: "",
      dispatcher: "",
      driver: "",
      loadno: "",
      brokerName: "",
      sortBy: "Ascending",
      status: "Conflict",
    });
  };

  const getDeafultLoads = () => {
    setPage(1);
    setFilterData({
      dateShipper: "",
      startDate: "",
      endDate: "",
      dispatcher: "",
      driver: "",
      loadno: "",
      brokerName: "",
      sortBy: "Ascending",
      status: "Default",
    });
  };

  //   const getMacarioLocation = async () => {
  //   try {
  //     // Send the GET request with query parameters
  //     const response = await axiosInstance.get(API_ENDPOINTS.DRIVERLOCATIONS, {
  //       params: { driverName: 75 }
  //     });

  //     // Extract latitude and longitude from the response data
  //     const { latestLocation } = response.data.data.items;
  //     const [latitude, longitude] = latestLocation.split(',');

  //     // Construct Google Maps URL
  //     const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  //     // Open Google Maps URL in a new tab
  //     window.open(mapsUrl, '_blank');
  //   } catch (error) {
  //     console.error("Error while fetching location data from the backend:", error);
  //   }
  // };

  const sendData = (data) => {
    setPage(1);
    if (data !== null) {
      setFilterData(data);
    } else {
      setFilterData({
        dateShipper: "",
        startDate: "",
        endDate: "",
        dispatcher: "",
        driver: "",
        loadno: "",
        brokerName: "",
        sortBy: "Ascending",
        status: "All",
      });
    }
  };

  function formatDateTime(inputDateTime) {
    // Check if input is null or empty
    if (inputDateTime === null || inputDateTime === "") {
      return null; // or return an empty string, depending on your preference
    }
    const dateObj = new Date(inputDateTime);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hours}${minutes}`;
    const result = `${formattedDate}, ${formattedTime}`;

    return result;
  }

  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        {myData.length >= 0 && (
          <div className="">
            <button
              type="button"
              className="btn reg-user-submit"
              data-bs-toggle="modal"
              data-bs-target="#modal1"
            >
              Add New Load
            </button>
          </div>
        )}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={toggleLoadFilter}
        >
          Filter
        </button>
        {userRole === "admin" && (
          <button
            type="button"
            className="btn reg-user-submit"
            onClick={getCompletedLoads}
          >
            Completed
          </button>
        )}
        {userRole === "admin" && (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={getInvoicedLoads}
          >
            Invoiced
          </button>
        )}
        <button
          type="button"
          className="btn reg-user-submit"
          onClick={getConflictLoads}
        >
          Conflicts
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={getDeafultLoads}
        >
          Restore Default
        </button>

        {/* <button
          type="button"
          className="btn btn-secondary"
          onClick={getMacarioLocation}
        >
          GET MACARIO LOCATION
        </button> */}
      </div>
      {isLoadFilterVisible && <LoadFilter sendData={sendData} />}
      <div className="col-md-12">
        {isLoading ? (
          <TableLoader />
        ) : myData.length > 0 ? (
          <div className="table-responsive shadow mb-4">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>PU Time</th>
                  <th>Del Time</th>
                  <th>Broker</th>
                  <th>Label</th>
                  <th>Load#</th>
                  <th>Rate</th>
                  <th>Driver</th>
                  <th>Status</th>
                  <th>Actions</th>
                  <th>Trailer#</th>
                  <th>Tags</th>
                  <th>Dispatcher</th>
                </tr>
              </thead>
              <tbody>
                {myData.map((userData, index) => {
                  const [
                    loadId,
                    label,
                    load,
                    loadStatus,
                    trailer,
                    escalation,
                    ratee,
                    shipper,
                    shipper2,
                    shipperStartTime,
                    shipperEndTime,
                    scit,
                    scot,
                    receiver,
                    receiver2,
                    receiverStartTime,
                    receiverEndTime,
                    rcit,
                    rcot,
                    broker,
                    lumperAmount,
                    lumperPaidBy,
                    driver,
                    driColor,
                    driver2,
                    dri2Color,
                    dispatcher,
                    disColor,
                  ] = userData;
                  return (
                    <tr key={index}>
                      <td>
                        <a
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={
                            (shipper !== null
                              ? "Shipper Name: " + shipper + "\n"
                              : "") +
                            (scit !== null
                              ? "CheckIn: " + formatDateTime(scit) + "\n"
                              : "") +
                            (scot !== null
                              ? "CheckOut: " + formatDateTime(scot)
                              : "")
                          }
                        >
                          <div>{formatDateTime(shipperStartTime)}</div>
                          <div>{formatDateTime(shipperEndTime)}</div>
                        </a>
                      </td>
                      <td>
                        <a
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={
                            (receiver !== null
                              ? "Receiver Name: " + receiver + "\n"
                              : "") +
                            (rcit !== null
                              ? "CheckIn: " + formatDateTime(rcit) + "\n"
                              : "") +
                            (rcot !== null
                              ? "CheckOut: " + formatDateTime(rcot)
                              : "")
                          }
                        >
                          <div>{formatDateTime(receiverStartTime)}</div>
                          <div>{formatDateTime(receiverEndTime)}</div>
                        </a>
                      </td>
                      <td>{broker}</td>
                      <td>{label}</td>
                      <td>{load}</td>
                      <td>{ratee !== null ? "$" + ratee : <></>}</td>
                      <td>
                        {driver2 ? (
                          <React.Fragment>
                            <div
                              className="p-1 text-center mb-2"
                              style={{ backgroundColor: driColor }}
                            >
                              {driver}
                            </div>
                            <div
                              className="p-1 text-center"
                              style={{ backgroundColor: dri2Color }}
                            >
                              {driver2}
                            </div>
                          </React.Fragment>
                        ) : (
                          <div
                            className="p-1 text-center"
                            style={{ backgroundColor: driColor }}
                          >
                            {driver}
                          </div>
                        )}
                      </td>
                      <td>
                        {scit !== null &&
                        rcot === null &&
                        loadStatus === "Assigned" ? (
                          <div className="truck-container">
                            <img
                              src={TruckIcon}
                              alt="Truck Icon"
                              className="truck-icon"
                            />
                          </div>
                        ) : (
                          <div
                            className={`p-1 load-status-${loadStatus.toLowerCase()} text-center btn-sm`}
                          >
                            {loadStatus}
                          </div>
                        )}
                      </td>
                      <td>
                        <span className="actions">
                          {userRole === "admin" &&
                            loadStatus !== "Invoiced" && (
                              <FaTrash
                                className="DelBin"
                                onClick={() => handleDeleteRow(loadId)}
                              />
                            )}
                          <FaEye
                            className="PenTip"
                            onClick={() => handleViewRow(loadId, load)}
                            data-bs-toggle="modal"
                            data-bs-target="#modal2"
                          />
                          {(userRole === "admin" ||
                            loadStatus !== "Invoiced") && (
                            <FaPencilAlt
                              className="EyeTip"
                              onClick={() =>
                                handleEditRow(loadId, load, loadStatus)
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#modal3"
                            />
                          )}
                          {userRole === "dispatcher" &&
                            loadStatus === "Invoiced" && (
                              <FaPager
                                className="EyeTip"
                                onClick={() =>
                                  handleEditDocumentRow(
                                    loadId,
                                    load,
                                    loadStatus
                                  )
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#modal5"
                              />
                            )}
                          {userRole === "admin" &&
                            loadStatus === "Completed" && (
                              <FaFileAlt
                                className="PageInvoice"
                                onClick={() => handleInvoiceRow(loadId, load)}
                                data-bs-toggle="modal"
                                data-bs-target="#modal4"
                              />
                            )}
                        </span>
                      </td>
                      <td>{trailer}</td>

                      <td>
                        <div className="d-flex">
                          {shipper2 && shipper2 !== "" && (
                            <FaTruck
                              className="PenTip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={"2nd Shipper Name: " + shipper2}
                            />
                          )}
                          {receiver2 && receiver2 !== "" && (
                            <FaUsers
                              className="PenTip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={"2nd Reciever Name: " + receiver2}
                            />
                          )}
                          {escalation && escalation !== "" && (
                            <FaExclamationCircle
                              className="EyeTip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={escalation}
                            />
                          )}
                          {lumperAmount && lumperAmount !== "" && (
                            <FaDollarSign
                              className={
                                lumperPaidBy === "Driver" ? "DelBin" : ""
                              }
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={"Lumper Amount: " + "$" + lumperAmount}
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          className="p-1 text-center"
                          style={{ backgroundColor: disColor }}
                        >
                          {" "}
                          {dispatcher}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="d-flex justify-content-center mx-auto align-items-center my-3">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center mb-0">
                  <li
                    className={`page-item ${
                      !(page - 1 >= 1) ? "disabled" : ""
                    }`}
                  >
                    <div
                      className={`page-link ${
                        !(page - 1 >= 1) ? "" : "pointer-cursor-page"
                      }`}
                      aria-disabled={!(page - 1 >= 1)}
                      onClick={() => handlePageClick(page - 1)}
                    >
                      Previous
                    </div>
                  </li>

                  {page - 2 >= 1 && (
                    <li className="page-item pointer-cursor-page">
                      <div
                        className="page-link"
                        onClick={() => handlePageClick(1)}
                      >
                        {1}
                      </div>
                    </li>
                  )}

                  {page - 2 >= 1 && (
                    <li className="page-item disabled">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  <li className="page-item pointer-cursor-page">
                    <div
                      className="page-link fw-bold"
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </div>
                  </li>

                  {page + 1 <= pageLimit && (
                    <li className="page-item pointer-cursor-page">
                      <div
                        className="page-link"
                        onClick={() => handlePageClick(page + 1)}
                      >
                        {page + 1}
                      </div>
                    </li>
                  )}

                  {page + 1 < pageLimit - 1 && (
                    <li className="page-item disabled">
                      <span className="page-link">...</span>
                    </li>
                  )}

                  {page + 2 <= pageLimit && (
                    <li className="page-item pointer-cursor-page">
                      <div
                        className="page-link"
                        onClick={() => handlePageClick(pageLimit)}
                      >
                        {pageLimit}
                      </div>
                    </li>
                  )}

                  <li
                    className={`page-item ${
                      !(page + 1 <= pageLimit) ? "disabled" : ""
                    }`}
                  >
                    <div
                      className={`page-link ${
                        !(page + 1 <= pageLimit) ? "" : "pointer-cursor-page"
                      }`}
                      aria-disabled={!(page + 1 <= pageLimit)}
                      onClick={() => handlePageClick(page + 1)}
                    >
                      Next
                    </div>
                  </li>
                </ul>
              </nav>

              <div className="d-flex align-items-center">
                <p className="form-label my-0 text-nowrap mx-3">
                  Loads Per Page :
                </p>
                <select
                  className="form-select"
                  defaultValue={loadCount}
                  onChange={(e) => handleLoadCountChange(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p>Record Not Found</p>
          </div>
        )}
      </div>

      <CreateLoad onSaveSuccess={handleSaveSuccess} role={userRole} />
      <ViewLoad
        loadId={selectedLoad}
        loadNumber={selectedLoadNumber}
        setRefreshViewModal={setRefreshViewModal}
        refreshViewModal={refreshViewModal}
      />
      <EditLoad
        onSaveSuccess={handleSaveSuccess}
        loadId={selectedLoad}
        loadNumber={selectedLoadNumber}
        setRefreshEditModal={setRefreshEditModal}
        refreshEditModal={refreshEditModal}
        role={userRole}
        loadStatusss={selectedLoadStatus}
      />
      <InvoiceLoad
        onSaveSuccess={handleSaveSuccess}
        loadId={selectedLoad}
        loadNumber={selectedLoadNumber}
        setRefreshInvoiceModal={setRefreshInvoiceModal}
        refreshInvoiceModal={refreshInvoiceModal}
        role={userRole}
      />
      <AddDocuments
        loadId={selectedLoad}
        loadNumber={selectedLoadNumber}
        setRefreshAddDocumentsModal={setRefreshAddDocumentsModal}
        refreshAddDocumentsModal={refreshAddDocumentsModal}
        role={userRole}
      />
    </div>
  );
};

export default LoadsTableAdmin;
