import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Driver from "./Pages/Admin/Drivers";
import Dispatcher from "./Pages/Admin/Dispatchers";
import Loads from "./Pages/Admin/Loads";
import Login from "../src/Pages/Shared/Login";
import Navbar from "./Components/Shared/Navbar";
import Logout from "./Pages/Shared/Logout";
import EditLogins from "./Pages/Admin/EditLogins";
import Home from "./Pages/Admin/Home";
import NotFound from "./Pages/Shared/NotFound";
import { ToastContainer } from "react-toastify";
import Brokers from "./Pages/AdminDispatcher/Brokers";
import Invoices from "./Pages/Admin/Invoices";

export const OpenContext = createContext();

function App() {
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadNavbar, setReloadNavbar] = useState(false); // Add this state
  const handleUserRole = (role) => {
    setUserRole(role);
    setIsLoading(false);
  };

  const reloadNavbarComponent = () => {
    setReloadNavbar((prev) => !prev);
    setIsLoading(true);
  };

  let backgroundColor = "white"; // Default background color
  if (userRole === "admin") {
    backgroundColor = "lightpink";
  } else if (userRole === "dispatcher") {
    backgroundColor = "lightcoral";
  } else if (userRole === "driver") {
    backgroundColor = "lightblue";
  }

  return (
    <>
      <ToastContainer />
      <OpenContext.Provider
        value={{
          isLoading,
          userRole,
          handleUserRole,
          reloadNavbar,
          reloadNavbarComponent,
        }}
      >
        <Router>
          <div className="App" style={{ backgroundColor }}>
            <Navbar />
            <div className="content container-customized">
              <Switch>
                <Route exact path="/" render={() => <Login />} />
                <Route exact path="/login" render={() => <Login />} />
                <Route exact path="/home" render={() => <Home />} />
                <Route exact path="/loads" render={() => <Loads />} />
                <Route exact path="/invoices" render={() => <Invoices />} />
                <Route
                  exact
                  path="/dispatchers"
                  render={() => <Dispatcher />}
                />
                <Route exact path="/drivers" render={() => <Driver />} />
                <Route exact path="/brokers" render={() => <Brokers />} />
                <Route
                  exact
                  path="/edit-logins"
                  render={() => <EditLogins />}
                />
                {/* <Route
                  exact
                  path="/create-invoice"
                  render={() => <CreateInvoice />}
                /> */}
                <Route exact path="/logout" render={() => <Logout />} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </Router>
      </OpenContext.Provider>
    </>
  );
}

export default App;
