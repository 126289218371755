import React, { useContext } from "react";
import PageTitle from "../../Components/Shared/PageTitle";
import { OpenContext } from "../../App";
import NotFound from "../Shared/NotFound";
import CheckDriverLoadsCount from "../../Components/Admin/CheckDriverLoadsCount";
import StatsLoads1 from "../../Components/Admin/StatsLoads1";
import StatsLoads2 from "../../Components/Admin/StatsLoads2";

const Home = () => {
  const { userRole, isLoading } = useContext(OpenContext);

  return (
    <div>
      {!isLoading && (
        <>
          {userRole === "admin" ? (
            <>
              {" "}
              <PageTitle title="Home" description="Admin will see Stats here" />
              <div>
                <div style={{ marginBottom: "10px" }}>
                  <CheckDriverLoadsCount />
                  <div className="card mt-2">
                    <div className="card-header">
                      <h4 className="text-center font-weight-bold">
                        Load Stats
                      </h4>
                    </div>
                    <div
                      className="card-body p-0"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <StatsLoads1 />
                      </div>
                      <div style={{ flex: 1, marginLeft: "10px" }}>
                        <StatsLoads2 />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </div>
  );
};

export default Home;
