import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import "../../Styling/NewLoadOrAgent.css";

const SignupBroker = ({ onSaveSuccess, userRole }) => {
  const { register, handleSubmit, reset } = useForm();
  const [selectedFilesCP, setSelectedFilesCP] = useState([]);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const handleFileChangeCP = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesCP.some((existingFile) => existingFile.name === file.name)
    );
    setSelectedFilesCP([...selectedFilesCP, ...uniqueFiles]);
  };

  const handleFileDeleteCP = (fileName) => {
    const updatedFiles = selectedFilesCP.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesCP(updatedFiles);
  };

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);

    let id = toast.loading("Broker is being added. Please Wait..."); // Update id inside the try block
    try {
      const formData = new FormData();
      Object.keys(data).forEach((val) => {
        formData.append(val, data[val]);
      });

      selectedFilesCP.forEach((file) => {
        formData.append("brokerContractPacket", file);
      });

      await axiosInstance.post(API_ENDPOINTS.BROKERS, formData);
      toast.update(id, {
        render: "Successful. New broker is added.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      reset();
      onSaveSuccess();
      document.getElementById("closeButtonSignUpBroker").click();
    } catch (error) {
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.error("Error ocurred while adding new broker:", error);
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="modalNewBroker"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">Broker Registration</h1>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Name:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("brokerName", {
                    required: true,
                    maxLength: 255,
                  })}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">MC#</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("mcNumber", { maxLength: 255 })}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Phone:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("brokerPhone", {
                    maxLength: 255,
                  })}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Email:</label>
                <input
                  type="email"
                  className="form-control create-input"
                  {...register("brokerEmail", { maxLength: 255 })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">After Hours:</label>
                <input
                  type="text"
                  className="form-control create-input"
                  {...register("brokerAfterHours", { maxLength: 255 })}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Billing Email:</label>
                <input
                  type="email"
                  className="form-control create-input"
                  {...register("brokerBillingEmail", { maxLength: 255 })}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Remittance Email:</label>
                <input
                  type="email"
                  className="form-control create-input"
                  {...register("brokerRemittance", { maxLength: 255 })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Payment Inquiry:</label>
                <input
                  type="email"
                  className="form-control create-input"
                  {...register("brokerPayment", { maxLength: 255 })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Status:</label>
                <select
                  className="form-control create-input"
                  {...register("brokerStatus")}
                >
                  <option value="Active">Active</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <label className="form-label fw-bold">Contract Packet:</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileChangeCP}
                />
                <ul>
                  {selectedFilesCP.map((file) => (
                    <li key={file.name}>
                      <button
                        type="button"
                        className="me-2 mt-1 btn btn-outline-danger btn-sm"
                        onClick={() => handleFileDeleteCP(file.name)}
                      >
                        Remove
                      </button>
                      {file.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <button type="submit" className="btn reg-user-submit">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="closeButtonSignUpBroker"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupBroker;
