import React, { useState, useEffect } from "react";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { FaEye, FaPencilAlt, FaTrash } from "react-icons/fa";
import "../../Styling/AgentList.css";
import TableLoader from "../Shared/TableLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditBrokerAgent from "../../Components/AdminDispatcher/EditBrokerAgent";
import ViewBrokerAgent from "../../Components/AdminDispatcher/ViewBrokerAgent";
import SignupBrokerAgent from "../../Components/AdminDispatcher/SignupBrokerAgent";

const BrokersAgentList = ({ userRole }) => {
  const [myData, setMyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false); // State variable to trigger table reload
  const [selectedAgent, setSelectedAgent] = useState(null); // State variable to store the selected broker data
  const [refreshEditModal, setRefreshEditModal] = useState(false);
  const [refreshViewModal, setRefreshViewModal] = useState(false);

  async function getData() {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.BROKERSAGENT);
      const data = response.data.message[0];
      setMyData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error getting brokerlist:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [reloadTable]);

  const handleDeleteRow = async (index) => {
    const updatedData = [...myData];
    const agentName = updatedData[index][1];
    const agentId = updatedData[index][0];
    const confirmDelete = window.confirm(
      `Are you sure you want to delete agent named ${agentName}?`
    );
    if (!confirmDelete) {
      return;
    }
    let id = toast.loading("Removing broker.Please wait..."); // Update id inside the try block
    try {
      await axiosInstance.delete(API_ENDPOINTS.BROKERSAGENT, {
        params: { agentId: agentId },
      });
      updatedData.splice(index, 1);
      toast.update(id, {
        render: "Agent deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      setMyData(updatedData);
    } catch (error) {
      console.error("Error deleting agent:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  const handleSaveSuccess = () => {
    setReloadTable((prevReloadTable) => !prevReloadTable);
  };

  const handleViewRow = (index) => {
    setSelectedAgent(myData[index]);
    setRefreshViewModal(true);
  };

  const handleEditRow = (index) => {
    setSelectedAgent(myData[index]);
    setRefreshEditModal(true);
  };

  return (
    <div className="row">
      <div className="text-center mt-1 mb-1">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-toggle="modal"
          data-bs-target="#modalNewBrokerAgent"
        >
          Add New Agent
        </button>
      </div>

      {isLoading ? (
        <TableLoader />
      ) : myData.length > 0 ? (
        <div>
          <div className="table-responsive shadow mb-4">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Broker</th>
                  <th>Mc#</th>
                  <th>Actions</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {myData.map((brokerData, index) => {
                  const [agentId, name, phone, email, broker, mc, status] =
                    brokerData;

                  return (
                    <tr className="myfs-13" key={index}>
                      <td>{name}</td>
                      <td>{phone}</td>
                      <td>{email}</td>
                      <td>{broker}</td>
                      <td>{mc}</td>

                      <td>
                        <span className="actions">
                          {userRole === "admin" && (
                            <FaTrash
                              className="DelBin"
                              onClick={() => handleDeleteRow(index)}
                            />
                          )}
                          <FaEye
                            className="PenTip"
                            onClick={() => handleViewRow(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#modalViewBrokerAgent"
                          />
                          <FaPencilAlt
                            className="EyeTip"
                            onClick={() => handleEditRow(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#modalEditBrokerAgent"
                          />
                        </span>
                      </td>
                      <td>
                        <span
                          className={`status-circle agent-status-${status.toLowerCase()}`}
                        ></span>
                        <span className="status-text">{status}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <p>No Brokers to Show</p>
        </div>
      )}

      <SignupBrokerAgent onSaveSuccess={handleSaveSuccess} />
      <ViewBrokerAgent
        selectedData={selectedAgent}
        setRefreshViewModal={setRefreshViewModal}
        refreshViewModal={refreshViewModal}
      />
      <EditBrokerAgent
        onSaveSuccess={handleSaveSuccess}
        selectedData={selectedAgent}
        setRefreshEditModal={setRefreshEditModal}
        refreshEditModal={refreshEditModal}
      />
    </div>
  );
};

export default BrokersAgentList;
