import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import "../../Styling/NewLoadOrAgent.css";


const SignupBroker = ({ onSaveSuccess, role }) => {
  const [currentColor, setCurrentColor] = useState('#FFFFFF'); // Initial color
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  function handleChangeComplete(event) {
    setCurrentColor(event.target.value); // Set the current color
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true)
    let id = toast.loading("User is being created. Please Wait..."); // Update id inside the try block
    try {
      data.role = String(role);
      data.backgroundColor = String(currentColor);
      await axiosInstance.post(API_ENDPOINTS.USERS, data);
      toast.update(id, { render: "Successful. New user is added.", type: "success", isLoading: false, autoClose: 1500 });
      reset();
      onSaveSuccess();
      document.getElementById("closeButtonSignupUser").click();
    } catch (error) {
      toast.update(id, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 1500 });
      console.error("Error ocurred while adding new user:", error);
    }finally {
      setIsRequestInProgress(false)
    }
  };

  const password = watch("password");

  return (
    <div
      className="modal fade"
      id="modal1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">Registration</h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
            <div className="row mb-3">

              <div className="col-md-12">
                <label className="form-label fw-bold">Username</label>
                <input
                  type="text"
                  placeholder="Jhon"
                  className="form-control create-input"
                  {...register("userName", {
                    required: true,
                    maxLength: 255,
                  })}
                />
                {errors.userName && errors.userName.type === "required" && (
                  <span className="text-danger fst-italic">
                    This field is required
                  </span>
                )}
                {errors.userName && errors.userName.type === "maxLength" && (
                  <span className="text-danger fst-italic">
                    Username must not exceed 255 characters
                  </span>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Phone</label>
                <input
                  type="tel"
                  placeholder="12345678900"
                  className="form-control create-input"
                  {...register("phoneNumber", { maxLength: 15 })}
                />
                {errors.phone && errors.username.type === "maxLength" && (
                  <span className="text-danger fst-italic">
                    Username must not exceed 15 characters
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Email</label>
                <input
                  type="email"
                  placeholder="user@example.com"
                  className="form-control create-input"
                  {...register("email", { maxLength: 255 })}
                />
                {errors.phone && errors.username.type === "maxLength" && (
                  <span className="text-danger fst-italic">
                    Username must not exceed 255 characters
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="form-control create-input"
                  {...register("password", { required: true, minLength: 8 })}
                />
                {errors.password && errors.password.type === "required" && (
                  <span className="text-danger fst-italic">
                    This field is required
                  </span>
                )}
                {errors.password && errors.password.type === "minLength" && (
                  <span className="text-danger fst-italic">
                    Password must be at least 8 characters long
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Confirm Password</label>
                <input
                  className="form-control create-input"
                  type="password"
                  placeholder="Retype Password"
                  {...register("confirmPassword", {
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  })}
                />
                {errors.confirmPassword && (
                  <span className="text-danger fst-italic">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Status:</label>
                <select
                  className="form-control create-input"
                  {...register("status")}
                >
                  <option value="Active">Active</option>
                  <option value="Leave">Leave</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <input
                  type="color"
                  value={currentColor}
                  onChange={handleChangeComplete}
                />
              </div>
              <div className="col-md-6">
                <span className="status-button" style={{ backgroundColor: currentColor }}>Name XYZ</span>
              </div>
            </div>
            <div >
              <button type="submit" className="btn reg-user-submit">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="closeButtonSignupUser"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupBroker;
