import React, { useState, useEffect } from "react";
import "../../Styling/AgentList.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import EditLoadDriver from "../../Components/Driver/EditLoadDriver";
import TableLoader from "../Shared/TableLoader"
import EditButtons from "./EditButtons";
import * as Sentry from '@sentry/react';

const LoadsTableDriver = () => {
  const [myData, setMyData] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [selectedLoadNo, setSelectedLoadNo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshEditModal, setRefreshEditModal] = useState(false);
  const [refreshButtonModal, setRefreshButtonModal] = useState(false);
  const [filterData, setFilterData] = useState({
    status: "Assigned",
  });

  async function getData(params) {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.LOADS, {
        params: params
      });
      const { data } = response.data;
      setMyData(data['items'])
    } catch (error) {
      console.error("Error:", error);
      Sentry.captureException(error);

    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData({
      ...filterData
    })
  }, [filterData.status]);

  // const LogCurrentLocation = () => {
  //   if ("geolocation" in navigator) {
  //     console.log("Getting your location...");
    
  //     // Set a timeout for 10 seconds
  //     const timeoutId = setTimeout(() => {
  //       console.warn("Error: Timeout while getting location.");
  //       // Cancel the geolocation request if it takes longer than 10 seconds
  //       navigator.geolocation.clearWatch(watchId);
  //     }, 10000);
    
  //     const watchId = navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         clearTimeout(timeoutId); // Clear the timeout if location is obtained successfully
  //         console.log("Success! Your location:", trackingMode);
  //         console.log(position);
  //         const formData = new FormData();
  //         formData.append('latitude', position.coords.latitude);
  //         formData.append('longitude', position.coords.longitude);
  //         try {
  //           // Send the FormData to the backend using axios patch request
  //           await axiosInstance.patch(API_ENDPOINTS.DRIVERLOCATIONS, formData, {
  //             params: { driverName: 'updateLocations' }
  //           });
  //         } catch (error) {
  //           console.error("Error while sending location data to the backend:", error);
  //         }
  //       },
  //       (error) => {
  //         clearTimeout(timeoutId); // Clear the timeout if an error occurs
  //         switch (error.code) {
  //           case error.PERMISSION_DENIED:
  //             console.warn("Error: Permission denied.");
  //             break;
  //           case error.POSITION_UNAVAILABLE:
  //             console.warn("Error: Position unavailable.");
  //             break;
  //           case error.TIMEOUT:
  //             console.warn("Error: Request timeout.");
  //             break;
  //           default:
  //             console.warn("Error: Unknown error.");
  //             break;
  //         }
  //       }
  //     );
  //   } else {
  //     console.warn("Geolocation is not supported by your browser.");
  //   }
  // };
  
  
  
  
  // useEffect(() => {
  //   if(trackingMode){const interval = setInterval(() => {
  //     LogCurrentLocation();
  //   }, 10000); // Repeat every 10 minutes
  
  //   return () => clearInterval(interval);}
  // }, [trackingMode]);
  

  const showAssignedLoads = () => {
    setFilterData({
      status: "Assigned",
    })
  };

  const showRecentLoads = () => {
    setFilterData({
      status: "Recents",
    })
  };

  function formatDateTime(inputDateTime) {
    if (inputDateTime === null || inputDateTime === "") {
      return null;
    }
    const dateObj = new Date(inputDateTime);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hours}${minutes}`;
    const result = `${formattedDate}, ${formattedTime}`;
    return result;
  }


  const handleButtonModal = (loadId, loadNo) => {
    setSelectedLoad(loadId)
    setSelectedLoadNo(loadNo)
    setRefreshButtonModal(true);
  };

  const handleEditModal = (loadId, loadNo) => {
    setSelectedLoad(loadId)
    setSelectedLoadNo(loadNo)
    setRefreshEditModal(true);
  };

  return (
    
    <div className="row">
        <div className="p-2 d-flex justify-content-center">
        <button
            type="button"
            className="btn btn-secondary"
            onClick={showAssignedLoads}
          >
            Assigned
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={showRecentLoads}
          >
            Recents
          </button>
          {/* <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setTrackingMode(!trackingMode)}
          >
            {trackingMode ? "Stop Tracking" : "Start Tracking"}
          </button>   */}
          </div>
      <div className="col-md-12">
        {isLoading ? (
          <TableLoader />
        ) : myData.length >= 0 ? (
          <div className="table-responsive no-radius shadow mb-4">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="reduce-space">Label/Load#</th>
                  <th className="reduce-space">Pickup Time</th>
                  <th className="reduce-space">Actions</th>
                </tr>
              </thead>
              <tbody>
                {myData.map((userData, index) => {
                  const [loadId, label, loadno, loadStatus,  pickupstarttime,  pickup2starttime] = userData;
                  return (
                    <tr key={index}>
                      <td className={`reduce-space ${loadStatus !== 'Assigned' ? '' : 'text-decoration-underline'}`}
                          {...(loadStatus === 'Assigned' ? 
                              {
                                  "data-bs-toggle": "modal", 
                                  "data-bs-target": "#modal5", 
                                  onClick: () => handleButtonModal(loadId, label + " (" + loadno + ")")
                              } 
                              : {})}
                      >
                        {label}
                        <br />
                        ({loadno})
                      </td>
                      <td> <div>{pickupstarttime && formatDateTime(pickupstarttime)}</div>
                        <div className="background-warning">{pickup2starttime && ("2nd PickUp Time: " + formatDateTime(pickup2starttime))}</div></td>

                      <td className="reduce-space">
                        { loadStatus === "Assigned" ? (<span className="btn btn-outline-danger btn-sm"
                          onClick={() => handleEditModal(loadId, label + " (" + loadno + ")")}
                          data-bs-toggle="modal"
                          data-bs-target="#modal3"> View</span>) : (<span className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleEditModal(loadId, label + " (" + loadno + ")")}
                          data-bs-toggle="modal"
                          data-bs-target="#modal3"> View</span>)
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

        ) : (
          <div className="text-center">
            <p>Record Not Found.</p>
          </div>
        )}
      </div>

      <EditLoadDriver
        loadId={selectedLoad}
        loadNo={selectedLoadNo}
        setRefreshEditModal={setRefreshEditModal} refreshEditModal={refreshEditModal}
      />
      <EditButtons
        loadId={selectedLoad}
        loadNo={selectedLoadNo}
        setRefreshButtonModal={setRefreshButtonModal} refreshButtonModal={refreshButtonModal}
      />
    </div >

  );
};

export default LoadsTableDriver;
