import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { FaClock, FaComment, FaLocationArrow, FaUser } from "react-icons/fa";

const EditLoadDriver = ({
  loadId,
  loadNo,
  setRefreshEditModal,
  refreshEditModal,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null); // State for iframe URL
  const [blobUrl, setBlobUrl] = useState(null); // State for iframe URL
  const [loadfiles, setLoadFiles] = useState({
    rateContDriver: null,
    billOfLanding: null,
    scaleTicket: null,
    lumperReceipt: null,
    locationScreenshots: null,
  });

  const [selectedFilesRCD, setSelectedFilesRCD] = useState([]);
  const [selectedFilesBOL, setSelectedFilesBOL] = useState([]);
  const [selectedFilesST, setSelectedFilesST] = useState([]);
  const [selectedFilesLR, setSelectedFilesLR] = useState([]);
  const [selectedFilesLS, setSelectedFilesLS] = useState([]);
  const [selectedFilesBOL2, setSelectedFilesBOL2] = useState([]);
  const [selectedFilesST2, setSelectedFilesST2] = useState([]);
  const [selectedFilesLR2, setSelectedFilesLR2] = useState([]);
  const [selectedFilesLS2, setSelectedFilesLS2] = useState([]);
  const [loadData, setLoadData] = useState({});

  const refreshstates = () => {
    reset();
    setIsRequestInProgress(false);
    setIsLoading(false);
    setFileName(null);
    setBlobUrl(null);
    setLoadFiles({
      rateContDriver: null,
      billOfLanding: null,
      scaleTicket: null,
      lumperReceipt: null,
      locationScreenshots: null,
    });
    setSelectedFilesRCD([]);
    setSelectedFilesBOL([]);
    setSelectedFilesBOL2([]);
    setSelectedFilesST([]);
    setSelectedFilesST2([]);
    setSelectedFilesLR([]);
    setSelectedFilesLR2([]);
    setSelectedFilesLS([]);
    setSelectedFilesLS2([]);
    setLoadData({});
  };

  const fetchLoad = async () => {
    setIsLoading(true);
    try {
      reset();
      const response = await axiosInstance.get(API_ENDPOINTS.GETLOAD, {
        params: { loadId },
      });

      if (response.status === 200) {
        const tempData = response.data.data["load-data"];
        setLoadData(tempData);

        if (typeof tempData === "object" && tempData !== null) {
          Object.keys(tempData).forEach((key) => {
            if (
              [
                "rateContDriver",
                "billOfLanding",
                "scaleTicket",
                "lumperReceipt",
                "locationScreenshots",
              ].includes(key)
            ) {
              setLoadFiles((prevLoadFiles) => ({
                ...prevLoadFiles,
                [key]: tempData[key],
              }));
            } else if (
              [
                "shipperCheckInTime",
                "shipperCheckOutTime",
                "receiverCheckInTime",
                "receiverCheckOutTime",
                "shipper2CheckInTime",
                "shipper2CheckOutTime",
                "receiver2CheckInTime",
                "receiver2CheckOutTime",
              ].includes(key)
            ) {
              if (tempData[key] && tempData[key] !== "") {
                const val = tempData[key].slice(0, 10);
                const [month, day, year] = val.split("/");
                const sliced = key.slice(0, key.indexOf("Time"));
                const formattedDateString = `${year}-${month}-${day}`;
                setValue(`${sliced}Date`, formattedDateString);
                setValue(`${sliced}Waqt`, tempData[key].slice(12, 17));
              }
            } else if (
              [
                "vanNumber",
                "lumperAmount",
                "lumperPaidBy",
                "lumperPaidWith",
              ].includes(key)
            ) {
              setValue(key, tempData[key]);
            }
          });
        } else {
          console.log("Invalid load data structure");
        }
      } else {
        console.log("Edit Load Response Wasn't successful except 200");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching load:", error);
      setIsLoading(false);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (loadId && refreshEditModal) {
      refreshstates();
      fetchLoad();
    } else {
      document.getElementById("closeButtonEditLoadDriver").click();
    }
  }, [loadId, refreshEditModal]);

  useEffect(() => {
    setSelectedFilesRCD(loadfiles.rateContDriver);
    setSelectedFilesBOL(loadfiles.billOfLanding);
    setSelectedFilesST(loadfiles.scaleTicket);
    setSelectedFilesLR(loadfiles.lumperReceipt);
    setSelectedFilesLS(loadfiles.locationScreenshots);
  }, [loadfiles]);

  const handleFileChangeBOL2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return extension === "pdf" || ["jpg", "jpeg", "png"].includes(extension);
    });
    setSelectedFilesBOL2([...selectedFilesBOL2, ...filteredFiles]);
  };

  const handleFileChangeST2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return extension === "pdf" || ["jpg", "jpeg", "png"].includes(extension);
    });
    setSelectedFilesST2([...selectedFilesST2, ...filteredFiles]);
  };

  const handleFileChangeLR2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return extension === "pdf" || ["jpg", "jpeg", "png"].includes(extension);
    });
    setSelectedFilesLR2([...selectedFilesLR2, ...filteredFiles]);
  };

  const handleFileChangeLS2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return extension === "pdf" || ["jpg", "jpeg", "png"].includes(extension);
    });
    setSelectedFilesLS2([...selectedFilesLS2, ...filteredFiles]);
  };

  const handleFileDeleteBOL2 = (fileName) => {
    const updatedFiles = selectedFilesBOL2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesBOL2(updatedFiles);
  };

  const handleFileDeleteST2 = (fileName) => {
    const updatedFiles = selectedFilesST2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesST2(updatedFiles);
  };

  const handleFileDeleteLR2 = (fileName) => {
    const updatedFiles = selectedFilesLR2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLR2(updatedFiles);
  };

  const handleFileDeleteLS2 = (fileName) => {
    const updatedFiles = selectedFilesLS2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLS2(updatedFiles);
  };

  const onSubmit = async (data) => {
    if (loadData.status !== "Assigned") {
      return;
    }
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating load details.Please wait..."); // Update id inside the try block
    let message_toast = "default";
    let message_type = "error";
    try {
      const formData = new FormData();
      Object.keys(data).forEach((val) => {
        formData.append(val, data[val]);
      });

      selectedFilesBOL2.forEach((file) => {
        formData.append("billOfLanding", file);
      });
      selectedFilesST2.forEach((file) => {
        formData.append("scaleTicket", file);
      });
      selectedFilesLR2.forEach((file) => {
        formData.append("lumperReceipt", file);
      });
      selectedFilesLS2.forEach((file) => {
        formData.append("locationScreenshots", file);
      });
      await axiosInstance.patch(API_ENDPOINTS.GETLOAD, formData, {
        params: { loadId: loadId, editType: "WithFiles" },
      });
      message_toast = "Load details updated successfully.";
      message_type = "success";
      // toast.update(id, { render: "Load details updated successfully.", type: "success", isLoading: false, autoClose: 1500 });
      document.getElementById("closeButtonEditLoadDriver").click();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error:", error);
      message_toast = error.response.data.message;
      message_type = "error";
      // toast.update(id, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 1500 });
    } finally {
      setIsRequestInProgress(false);
      if (message_toast === "default") {
        toast.update(id, {
          render:
            "Browser sent a request which server could not understand. Refreshing...",
          type: "error",
          isLoading: false,
          autoClose: 1500,
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000); // Delay the reload by 1000 milliseconds (1 second)
      } else {
        toast.update(id, {
          render: message_toast,
          type: message_type,
          isLoading: false,
          autoClose: 1500,
        });
      }
    }
  };

  // const handleDownloadClick = async (contentName) => {
  //   try {
  //     const response = await axiosInstance.get(`${API_ENDPOINTS.FILE}/${contentName}?loadId=${loadId}`);

  //     const fileUrl = response.data.url;

  //     window.open(fileUrl, '_blank');
  //   } catch (error) {
  //     console.log("Error fetching file:", error);
  //   }
  // };

  const handleViewClick = async (contentName) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWFILE}/${contentName}?loadId=${loadId}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  const handleCloseClickIframe = () => {
    setBlobUrl(null);
  };

  return (
    <div
      className="modal fade"
      id="modal3"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-1">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">{loadNo}</h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              {loadData.status === "Assigned" && (
                <button
                  type="submit"
                  className="btn reg-user-submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setRefreshEditModal(false);
                  setBlobUrl(null);
                }}
                id="closeButtonEditLoadDriver"
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
              {blobUrl && (
                <>
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-danger"
                      onClick={handleCloseClickIframe}
                    >
                      Close
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {fileName.endsWith(".pdf") ? (
                        <embed
                          className="iframedriveract"
                          src={blobUrl}
                        ></embed>
                      ) : (
                        <img
                          width={"100%"}
                          height="auto"
                          src={blobUrl}
                          alt="Preview"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div id="loadDetails" className="fw-bold text-center">
                Load Details
              </div>
              <hr className="rounded"></hr>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start align-items-center">
                  {loadData.status === "Assigned" ? (
                    <>
                      <label className="col-form-label fw-bold">Trailer#</label>
                      <input
                        type="text"
                        placeholder="CA123"
                        className="form-control input-field me-1"
                        {...register("vanNumber", { maxLength: 255 })}
                      />
                    </>
                  ) : (
                    loadData.vanNumber && (
                      <div className="col-form-label me-2">
                        Trailer# {loadData.vanNumber}{" "}
                      </div>
                    )
                  )}
                </div>
              </div>
              {loadData.instruction && (
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-start align-items-center text-danger">
                    <label className="col-form-label me-1 fw-bold">
                      Instructions:
                    </label>
                    {loadData.instruction}
                  </div>
                </div>
              )}
              <div className="row">
                {(loadData.status === "Assigned" || selectedFilesRCD) && (
                  <div className="col-md-6">
                    <label className="col-form-label">Rate Confirmation:</label>
                    {selectedFilesRCD !== null && (
                      <ul>
                        {selectedFilesRCD.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            {/* <button type="button" className="me-2 mt-1 btn btn-outline-success btn-sm" onClick={() => handleDownloadClick(file)}>
                          Download
                        </button> */}
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}

                {(loadData.status === "Assigned" || selectedFilesBOL) && (
                  <div className="col-md-6">
                    <label className="col-form-label me-2">
                      Bill of Lading:
                    </label>
                    {loadData.status === "Assigned" && (
                      <>
                        {" "}
                        <input
                          type="file"
                          id="actual-btn-bol"
                          onChange={handleFileChangeBOL2}
                          hidden
                        />
                        <label
                          className="btn btn-success btn-sm"
                          htmlFor="actual-btn-bol"
                        >
                          Add File
                        </label>
                      </>
                    )}
                    {selectedFilesBOL !== null && (
                      <ul>
                        {selectedFilesBOL.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            {/* <button type="button" className="me-2 mt-1 btn btn-outline-success btn-sm" onClick={() => handleDownloadClick(file)}>
                          Download
                        </button> */}
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}
                    {selectedFilesBOL2 !== null && (
                      <ul>
                        {selectedFilesBOL2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteBOL2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}

                {(loadData.status === "Assigned" || selectedFilesST) && (
                  <div className="col-md-6">
                    <label className="col-form-label me-2">Scale Ticket:</label>
                    {loadData.status === "Assigned" && (
                      <>
                        <input
                          type="file"
                          id="actual-btn-st"
                          onChange={handleFileChangeST2}
                          hidden
                        />
                        <label
                          className="btn btn-success btn-sm"
                          htmlFor="actual-btn-st"
                        >
                          Add File
                        </label>
                      </>
                    )}
                    {selectedFilesST !== null && (
                      <ul>
                        {selectedFilesST.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            {/* <button type="button" className="me-2 mt-1 btn btn-outline-success btn-sm" onClick={() => handleDownloadClick(file)}>
                          Download
                        </button> */}
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}
                    {selectedFilesST2 !== null && (
                      <ul>
                        {selectedFilesST2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteST2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
                {(loadData.status === "Assigned" || selectedFilesLR) && (
                  <div className="col-md-6">
                    <label className="col-form-label me-2">
                      Lumper Reciept:
                    </label>
                    {loadData.status === "Assigned" && (
                      <>
                        <input
                          type="file"
                          id="actual-btn-lr"
                          onChange={handleFileChangeLR2}
                          hidden
                        />
                        <label
                          className="btn btn-success btn-sm"
                          htmlFor="actual-btn-lr"
                        >
                          Add File
                        </label>
                      </>
                    )}
                    {selectedFilesLR !== null && (
                      <ul>
                        {selectedFilesLR.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            {/* <button type="button" className="me-2 mt-1 btn btn-outline-success btn-sm" onClick={() => handleDownloadClick(file)}>
                          Download
                        </button> */}
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}
                    {selectedFilesLR2 !== null && (
                      <ul>
                        {selectedFilesLR2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteLR2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
              <div className="row">
                {(loadData.status === "Assigned" || selectedFilesLS) && (
                  <div className="col-md-12">
                    <label className="col-form-label me-2">
                      Location Screenshots:
                    </label>
                    {loadData.status === "Assigned" && (
                      <>
                        {" "}
                        <input
                          type="file"
                          id="actual-btn-ls"
                          onChange={handleFileChangeLS2}
                          hidden
                        />
                        <label
                          className="btn btn-success btn-sm"
                          htmlFor="actual-btn-ls"
                        >
                          Add File
                        </label>
                      </>
                    )}
                    {selectedFilesLS !== null && (
                      <ul>
                        {selectedFilesLS.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            {/* <button type="button" className="me-2 mt-1 btn btn-outline-success btn-sm" onClick={() => handleDownloadClick(file)}>
                          Download
                        </button> */}
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}
                    {selectedFilesLS2 !== null && (
                      <ul>
                        {selectedFilesLS2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteLS2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
              <div id="shipperDetails" className="fw-bold text-center mt-4">
                Shipper Times{" "}
                <span className="text-danger">
                  {loadData.driver &&
                    loadData.driver2 &&
                    "( For " + loadData.driver + " )"}
                </span>
              </div>
              <hr className="rounded"></hr>
              {loadData.shipperAddress ? (
                <>
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.shipperName && (
                        <>
                          <FaUser className="me-2" />
                          {loadData.shipperName}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.shipperAddress && (
                        <>
                          <FaLocationArrow className="me-2" />
                          {loadData.shipperAddress}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {(loadData.shipperStartTime ||
                        loadData.shipperEndTime) && (
                        <>
                          <FaClock className="me-2" />
                          {loadData.shipperStartTime
                            ? loadData.shipperStartTime
                            : "N/A"}
                          {" - "}
                          {loadData.shipperEndTime
                            ? loadData.shipperEndTime
                            : "N/A"}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1 text-info">
                      {loadData.shipperInstruction && (
                        <>
                          <FaComment className="me-2" />
                          {loadData.shipperInstruction}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start align-items-center mb-1">
                      {loadData.status === "Assigned" ? (
                        <>
                          <label className="col-form-label single-line me-1 fw-bold">
                            In: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("shipperCheckInDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("shipperCheckInWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.shipperCheckInWaqt && (
                            <span>{errors.shipperCheckInWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.shipperCheckInTime && (
                          <div className="pb-0 mx-1">
                            Checked In: {loadData.shipperCheckInTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-items-center">
                      {loadData.status === "Assigned" ? (
                        <>
                          <label className="col-form-label single-line me-1 fw-bold">
                            Out: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("shipperCheckOutDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("shipperCheckOutWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.shipperCheckOutWaqt && (
                            <span>{errors.shipperCheckOutWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.shipperCheckOutTime && (
                          <div className="pb-0 mx-1">
                            Checked Out: {loadData.shipperCheckOutTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {(loadData.shipper2Address ||
                    loadData.shipper2StartTime ||
                    loadData.shipper2EndTime) && (
                    <div className="text-bg-primary p-3">
                      2nd Shipper Address is missing for this load. Contact
                      Dispatcher/Admin to add the following details.{" "}
                    </div>
                  )}
                </>
              )}
              {loadData.shipper2Address ? (
                <>
                  <div className="p-2 text-danger text-center fw-bold">
                    Shipper#2 Information
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.shipper2Name && (
                        <>
                          <FaUser className="me-2" />
                          {loadData.shipper2Name}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.shipper2Address && (
                        <>
                          <FaLocationArrow className="me-2" />
                          {loadData.shipper2Address}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {(loadData.shipper2StartTime ||
                        loadData.shipper2EndTime) && (
                        <>
                          <FaClock className="me-2" />
                          {loadData.shipper2StartTime
                            ? loadData.shipper2StartTime
                            : "N/A"}
                          {" - "}
                          {loadData.shipper2EndTime
                            ? loadData.shipper2EndTime
                            : "N/A"}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1 text-info">
                      {loadData.shipper2Instruction && (
                        <>
                          <FaComment className="me-2" />
                          {loadData.shipper2Instruction}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start align-items-center mb-1">
                      {loadData.status === "Assigned" ? (
                        <>
                          <label className="col-form-label single-line me-1 fw-bold">
                            In: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("shipper2CheckInDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("shipper2CheckInWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.shipper2CheckInWaqt && (
                            <span>{errors.shipper2CheckInWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.shipper2CheckInTime && (
                          <div className="pb-0 mx-1">
                            Checked In: {loadData.shipper2CheckInTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-items-center">
                      {loadData.status === "Assigned" ? (
                        <>
                          <label className="col-form-label single-line me-1 fw-bold">
                            Out: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("shipper2CheckOutDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("shipper2CheckOutWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.shipper2CheckOutWaqt && (
                            <span>{errors.shipper2CheckOutWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.shipper2CheckOutTime && (
                          <div className="pb-0 mx-1">
                            Checked Out: {loadData.shipper2CheckOutTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div id="receiverDetails" className="fw-bold text-center mt-4">
                Receiver Times{" "}
                <span className="text-danger">
                  {loadData.driver &&
                    loadData.driver2 &&
                    "( For " + loadData.driver2 + " )"}{" "}
                </span>
              </div>{" "}
              <hr className="rounded"></hr>
              {loadData.receiverAddress ? (
                <>
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.receiverName && (
                        <>
                          <FaUser className="me-2" />
                          {loadData.receiverName}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.receiverAddress && (
                        <>
                          <FaLocationArrow className="me-2" />
                          {loadData.receiverAddress}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {(loadData.receiverStartTime ||
                        loadData.receiverEndTime) && (
                        <>
                          <FaClock className="me-2" />
                          {loadData.receiverStartTime
                            ? loadData.receiverStartTime
                            : "N/A"}
                          {" - "}
                          {loadData.receiverEndTime
                            ? loadData.receiverEndTime
                            : "N/A"}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1 text-info">
                      {loadData.receiverInstruction && (
                        <>
                          <FaComment className="me-2" />
                          {loadData.receiverInstruction}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start align-items-center mb-1">
                      {loadData.status === "Assigned" ? (
                        <>
                          {" "}
                          <label className="col-form-label single-line me-1 fw-bold">
                            In: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("receiverCheckInDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("receiverCheckInWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.receiverCheckInWaqt && (
                            <span>{errors.receiverCheckInWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.receiverCheckInTime && (
                          <div className="pb-0 mx-1">
                            Checked In: {loadData.receiverCheckInTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-items-center">
                      {loadData.status === "Assigned" ? (
                        <>
                          {" "}
                          <label className="col-form-label single-line me-1 fw-bold">
                            Out: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("receiverCheckOutDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("receiverCheckOutWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.receiverCheckOutWaqt && (
                            <span>{errors.receiverCheckOutWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.receiverCheckOutTime && (
                          <div className="pb-0 mx-1">
                            Checked Out: {loadData.receiverCheckOutTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {(loadData.receiver2Address ||
                    loadData.receiver2StartTime ||
                    loadData.receiver2EndTime) && (
                    <div className="text-bg-primary p-3">
                      2nd Receiver Address is missing for this load. Contact
                      Dispatcher/Admin to add the following details.{" "}
                    </div>
                  )}
                </>
              )}
              {loadData.receiver2Address ? (
                <>
                  <div className="p-2 text-danger text-center fw-bold">
                    Receiver#2 Information
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.receiver2Name && (
                        <>
                          <FaUser className="me-2" />
                          {loadData.receiver2Name}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {loadData.receiver2Address && (
                        <>
                          <FaLocationArrow className="me-2" />
                          {loadData.receiver2Address}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start mb-1">
                      {(loadData.receiver2StartTime ||
                        loadData.receiver2EndTime) && (
                        <>
                          <FaClock className="me-2" />
                          {loadData.receiver2StartTime
                            ? loadData.receiver2StartTime
                            : "N/A"}
                          {" - "}
                          {loadData.receiver2EndTime
                            ? loadData.receiver2EndTime
                            : "N/A"}
                        </>
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mb-1 text-info">
                      {loadData.receiver2Instruction && (
                        <>
                          <FaComment className="me-2" />
                          {loadData.receiver2Instruction}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start align-items-center mb-1">
                      {loadData.status === "Assigned" ? (
                        <>
                          {" "}
                          <label className="col-form-label single-line me-1 fw-bold">
                            In: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("receiver2CheckInDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("receiver2CheckInWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.receiver2CheckInWaqt && (
                            <span>{errors.receiver2CheckInWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.receiver2CheckInTime && (
                          <div className="pb-0 mx-1">
                            Checked In: {loadData.receiver2CheckInTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-items-center">
                      {loadData.status === "Assigned" ? (
                        <>
                          {" "}
                          <label className="col-form-label single-line me-1 fw-bold">
                            Out: Date:
                          </label>
                          <input
                            type="date"
                            className="form-control input-field me-2"
                            {...register("receiver2CheckOutDate")}
                          />
                          <label className="col-form-label single-line me-1 fw-bold">
                            Time:
                          </label>
                          <input
                            type="text"
                            className="form-control input-field"
                            placeholder="hhmm"
                            {...register("receiver2CheckOutWaqt", {
                              pattern: {
                                value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                message:
                                  "Please enter time in format hhmm (24-hour format)",
                              },
                            })}
                          />
                          {errors.receiver2CheckOutWaqt && (
                            <span>{errors.receiver2CheckOutWaqt.message}</span>
                          )}
                        </>
                      ) : (
                        loadData.receiver2CheckOutTime && (
                          <div className="pb-0 mx-1">
                            Checked Out: {loadData.receiver2CheckOutTime}{" "}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {(loadData.lumperPaidBy ||
                loadData.lumperPaidWith ||
                loadData.lumperAmount ||
                loadData.brokerAfterHours ||
                loadData.status === "Assigned") && (
                <>
                  <div id="lumperDetails" className="fw-bold text-center mt-2">
                    Lumper Details
                  </div>{" "}
                  <hr className="rounded"></hr>
                </>
              )}
              <div className="row">
                {loadData.brokerAfterHours && (
                  <div className="col-md-3 d-flex justify-content-start align-items-center single-line mb-1">
                    <div className="pb-0 mx-1">
                      After Hours: {loadData.brokerAfterHours}{" "}
                    </div>
                  </div>
                )}

                <div className="col-md-3 d-flex justify-content-start align-items-center mb-1">
                  {loadData.status === "Assigned" ? (
                    <>
                      <label className="col-form-label pb-0 mx-1 single-line fw-bold">
                        Amount(USD):
                      </label>
                      <input
                        type="number"
                        className="form-control input-field"
                        {...register("lumperAmount")}
                      />
                    </>
                  ) : (
                    loadData.lumperAmount && (
                      <div className="pb-0 mx-1">
                        Amount(USD): {loadData.lumperAmount}{" "}
                      </div>
                    )
                  )}
                </div>
                <div className="col-md-3 d-flex justify-content-start align-items-center mb-1">
                  {loadData.status === "Assigned" ? (
                    <>
                      <label className="col-form-label pb-0 mx-1 single-line fw-bold">
                        Paid By:
                      </label>
                      <select
                        className="form-control input-field"
                        {...register("lumperPaidBy")}
                        defaultValue={"Choose..."}
                      >
                        <option value="Choose...">Choose...</option>
                        <option value="Broker">Broker</option>
                        <option value="Driver">Driver</option>
                      </select>
                    </>
                  ) : (
                    loadData.lumperPaidBy && (
                      <div className="pb-0 mx-1">
                        Paid By: {loadData.lumperPaidBy}{" "}
                      </div>
                    )
                  )}
                </div>

                <div className="col-md-3 d-flex justify-content-start align-items-center mb-1">
                  {loadData.status === "Assigned" ? (
                    <>
                      <label className="col-form-label pb-0 mx-1 single-line fw-bold">
                        Paid with:
                      </label>
                      <select
                        className="form-control input-field"
                        {...register("lumperPaidWith")}
                        defaultValue={"Choose..."}
                      >
                        <option value="Choose...">Choose...</option>
                        <option value="Cash">Cash</option>
                        <option value="EFSCode">EFS Code</option>
                        <option value="CreditCard">Credit Card</option>
                        <option value="Other">Other</option>
                      </select>
                    </>
                  ) : (
                    loadData.lumperPaidWith && (
                      <div className="pb-0 mx-1">
                        Paid With: {loadData.lumperPaidWith}{" "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditLoadDriver;
