import React, { useContext } from "react";
import PageTitle from "../../Components/Shared/PageTitle";
import EditLoginForm from "../../Components/Admin/EditLoginForm";
import { OpenContext } from "../../App";
import NotFound from "../Shared/NotFound";

const EditLogins = () => {
  const { userRole, isLoading } = useContext(OpenContext);

  return (
    <div>
      {!isLoading && (
        <>
          {userRole === "admin" ? (
            <>
              {" "}
              <PageTitle
                title="Edit Logins"
                description="Only Admins can edit the logins in this page."
              />
              <EditLoginForm />
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </div>
  );
};

export default EditLogins;
