import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvoiceAct = ({
  selectedInvoiceData,
  setRefreshInvoiceModal,
  refreshInvoiceModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [fileName, setFileName] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [loadfiles, setLoadFiles] = useState({
    rateCont: null,
    billOfLanding: null,
    scaleTicket: null,
    lumperReceipt: null,
    miscellaneousDocuments: null,
    locationScreenshots: null,
  });
  const [selectedFilesRC, setSelectedFilesRC] = useState([]);
  const [selectedFilesBOL, setSelectedFilesBOL] = useState([]);
  const [selectedFilesST, setSelectedFilesST] = useState([]);
  const [selectedFilesLR, setSelectedFilesLR] = useState([]);
  const [selectedFilesMD, setSelectedFilesMD] = useState([]);
  const [selectedFilesLS, setSelectedFilesLS] = useState([]);
  const [selectedDocumentKeys, setSelectedDocumentKeys] = useState([]);

  const fetchInvoiceData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.INVOICESDETAILSATTACH,
        {
          params: { invoiceId: selectedInvoiceData[0] },
        }
      );
      const tempData = response.data.data["invoice-data"];
      setInvoiceData(tempData);
      if (typeof tempData === "object" && tempData !== null) {
        Object.keys(tempData).forEach((key) => {
          if (
            [
              "rateCont",
              "billOfLanding",
              "scaleTicket",
              "lumperReceipt",
              "miscellaneousDocuments",
              "locationScreenshots",
            ].includes(key)
          ) {
            setLoadFiles((prevLoadFiles) => ({
              ...prevLoadFiles,
              [key]: tempData[key],
            }));
          }
        });
      } else {
        console.log("Invalid Data Structure");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching load to invoice:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedInvoiceData && refreshInvoiceModal) {
      fetchInvoiceData();
    }
  }, [selectedInvoiceData, refreshInvoiceModal]);

  useEffect(() => {
    setSelectedFilesRC(loadfiles.rateCont);
    setSelectedFilesBOL(loadfiles.billOfLanding);
    setSelectedFilesST(loadfiles.scaleTicket);
    setSelectedFilesLR(loadfiles.lumperReceipt);
    setSelectedFilesMD(loadfiles.miscellaneousDocuments);
    setSelectedFilesLS(loadfiles.locationScreenshots);
  }, [loadfiles]);

  const handleViewClick = async (contentName) => {
    if (!invoiceData.loadId) {
      return;
    }
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWFILE}/${contentName}?loadId=${invoiceData.loadId}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  const handleCheckboxChange = (fileKey) => {
    if (!fileKey.toLowerCase().endsWith(".pdf")) {
      toast.warning(
        "Please select PDF files only or convert to PDF before invoicing."
      );
      return;
    }

    setSelectedDocumentKeys((prevKeys) => {
      if (prevKeys.includes(fileKey)) {
        return prevKeys.filter((key) => key !== fileKey);
      } else {
        return [...prevKeys, fileKey];
      }
    });
  };

  const handleViewInvoiceClick = async (contentName, Type) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWINVOICE}/${contentName}?Type=${Type}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  const handleUpdateInvoiceClick = async (TypeInv) => {
    if (selectedInvoiceData[0]) {
      //pass
    } else {
      return;
    }
    let id = toast.loading("File is being updated. Please wait...");
    try {
      const formData = new FormData();
      if (TypeInv === "Merged") {
        formData.append("invoiceType", TypeInv);
        selectedDocumentKeys.forEach((fileKey) =>
          formData.append("selectedAttachments", fileKey)
        );
      } else {
        return;
      }
      const response = await axiosInstance.patch(
        API_ENDPOINTS.INVOICESDETAILSATTACH,
        formData,
        {
          params: { invoiceId: selectedInvoiceData[0] },
        }
      );
      const singleValue = response.data.message["Single"];
      const MergedValue = response.data.message["Merged"];

      if (singleValue) {
        setInvoiceData((prevData) => ({
          ...prevData,
          invoiceFileNameS3: singleValue,
        }));
      }
      if (MergedValue) {
        setInvoiceData((prevData) => ({
          ...prevData,
          invoiceFileNameS3Merged: MergedValue,
        }));
      }
      toast.update(id, {
        render: "Success. Invoice is Updated",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  return (
    <div
      className="modal fade"
      id="modalInvoiceAct"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">
                {selectedInvoiceData
                  ? `Inv# ${selectedInvoiceData[3]} - Load# ${selectedInvoiceData[1]} - ${selectedInvoiceData[4]}`
                  : ""}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButtonInvoiceLoad"
                onClick={(e) => {
                  setRefreshInvoiceModal(false);
                  setSelectedDocumentKeys([]);
                }}
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={(e) => e.preventDefault()} className="modal-body">
              <ul className="nav nav-tabs" id="myTab1" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="load-invoice-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#load-invoice-details"
                    type="button"
                    role="tab"
                    aria-controls="load-invoice-details"
                    aria-selected="true"
                  >
                    Regenerate Invoice
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent1">
                <div
                  className="tab-pane fade show active"
                  id="load-invoice-details"
                  role="tabpanel"
                  aria-labelledby="load-invoice-details-tab"
                >
                  <div className="row mt-2 mb-2">
                    <div className="col-md-12 d-flex flex-row-reverse">
                      <button
                        type="button"
                        className="me-2 mt-1 btn btn-warning"
                        onClick={() => handleUpdateInvoiceClick("Merged")}
                      >
                        Update Invoice Document
                      </button>
                    </div>
                  </div>
                  <div id="documentsDetails" className="fw-bold text-center">
                    Documents
                  </div>
                  <hr className="rounded"></hr>
                  {blobUrl && (
                    <>
                      <div className="d-flex flex-row-reverse">
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => setBlobUrl(null)}
                        >
                          Close
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {fileName.endsWith(".pdf") ? (
                            <embed
                              className="iframedriver"
                              src={blobUrl}
                            ></embed>
                          ) : (
                            <img
                              width={"100%"}
                              height="auto"
                              src={blobUrl}
                              alt="Image"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Invoice Single:</label>
                      {invoiceData.invoiceFileNameS3 && (
                        <ul>
                          <li>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-success btn-sm"
                              onClick={() =>
                                handleViewInvoiceClick(
                                  invoiceData.invoiceFileNameS3,
                                  "Single"
                                )
                              }
                            >
                              View
                            </button>
                            <span>{invoiceData.invoiceFileNameS3}</span>
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">Invoice Merged:</label>

                      {invoiceData.invoiceFileNameS3Merged && (
                        <ul>
                          <li>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-success btn-sm"
                              onClick={() =>
                                handleViewInvoiceClick(
                                  invoiceData.invoiceFileNameS3Merged,
                                  "Merged"
                                )
                              }
                            >
                              View
                            </button>
                            <span>{invoiceData.invoiceFileNameS3Merged}</span>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Rate Confirmation:
                      </label>
                      {selectedFilesRC !== null && (
                        <ul>
                          {selectedFilesRC.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">Bill of Lading:</label>
                      {selectedFilesBOL !== null && (
                        <ul>
                          {selectedFilesBOL.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Scale Ticket:</label>
                      {selectedFilesST !== null && (
                        <ul>
                          {selectedFilesST.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">Lumper Receipt:</label>
                      {selectedFilesLR !== null && (
                        <ul>
                          {selectedFilesLR.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Location Screenshots:
                      </label>
                      {selectedFilesLS !== null && (
                        <ul>
                          {selectedFilesLS.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Miscellaneous Documents:
                      </label>
                      {selectedFilesMD !== null && (
                        <ul>
                          {selectedFilesMD.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div
                    id="selectedDocumentsDetails"
                    className="mt-3 fw-bold text-center"
                  >
                    Selected Documents
                  </div>

                  <div className="card p-2">
                    <div className="selected-documents">
                      {selectedDocumentKeys.map((key, index) => (
                        <div key={index} className="selected-document">
                          <div className="file-icon">
                            <i className="fas fa-file-alt"></i>
                          </div>
                          <div className="file-caption">{key}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show"
                  id="load-details-invoice"
                  role="tabpanel"
                  aria-labelledby="load-details-invoice-tab"
                >
                  <div id="loadDetails" className="fw-bold text-center">
                    Load Details
                  </div>
                  <hr className="rounded"></hr>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceAct;
