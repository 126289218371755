import React, { useContext } from "react";
import Userlist from "../../Components/Admin/Userlist";
import PageTitle from "../../Components/Shared/PageTitle";
import { OpenContext } from "../../App";
import NotFound from "../Shared/NotFound";

const Drivers = () => {
  const { userRole, isLoading } = useContext(OpenContext);
  return (
    <div>
      {!isLoading && (
        <>
          {userRole === "admin" ? (
            <>
              {" "}
              <PageTitle title="Drivers" description="Driver ki mojain" />
              <Userlist role="Driver" />
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </div>
  );
};

export default Drivers;
