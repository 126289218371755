import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { FaClock, FaLocationArrow, FaUser } from "react-icons/fa";
import * as Sentry from '@sentry/react';

const EditButtons = ({ loadId, loadNo, setRefreshButtonModal, refreshButtonModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [loadData, setLoadData] = useState({});

  const fetchLoad = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(API_ENDPOINTS.GETLOAD, {
        params: { loadId }
      });
      const tempData = response.data.data['load-data'];
      setLoadData(tempData);
      setIsLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error fetching load:", error);
      setIsLoading(false);
    }
  };




  useEffect(() => {
    if (loadId && refreshButtonModal) {
      setIsLoading(false);
      setIsRequestInProgress(false);
      setLoadData({});
      fetchLoad();
    }
    else
    {
      document.getElementById("closeButtonEditButtonsDriver").click();
    }
  }, [loadId, refreshButtonModal]);



  const handleTimeButtonClick = async (key) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
  
    const formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}`;
  
    let id = toast.loading("Updating load details. Please wait..."); // Update id inside the try block
    try {
      const formData = new FormData();
      formData.append('column', key);
      formData.append('column_value', formattedDateTime);
      await axiosInstance.patch(API_ENDPOINTS.DRIVERCHECKACTIONS, formData, {
        params: { 'loadId': loadId }
      });
      toast.update(id, { render: "Load details updated successfully.", type: "success", isLoading: false, autoClose: 1500 });
      setIsRequestInProgress(false);
      document.getElementById("closeButtonEditButtonsDriver").click();
    } catch (error) {
      console.error("Error:", error);
      Sentry.captureException(error);
      toast.update(id, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 1500 });
      setIsRequestInProgress(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="modal5"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">{loadNo}</h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={e => setRefreshButtonModal(false)
                }
                id="closeButtonEditButtonsDriver"
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
          <div className="modal-body">
            <div id="shipperDetails" className="card p-2 " style={{ backgroundColor: "#ededed" }}><div className="fw-bold text-center">Shipper Times </div><span className="text-danger">{(loadData.driver && loadData.driver2) && "( For " + loadData.driver + " )"} </span><hr className="rounded"></hr>
              {(loadData.shipperAddress ) ? (<>  <div className="row mb-2">
                <div className="d-flex justify-content-start mb-3">
                  <FaUser className="me-2" />
                  {loadData.shipperName ? loadData.shipperName : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaLocationArrow className="me-2" />
                  {loadData.shipperAddress ? loadData.shipperAddress : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaClock className="me-2" />
                  {loadData.shipperStartTime ? loadData.shipperStartTime : "N/A"}
                  {" - "}
                  {loadData.shipperEndTime ? loadData.shipperEndTime : "N/A"}
                </div>

              </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex justify-content-between">
                    {
                      loadData.shipperCheckInTime === null ? (
                        <div className="status-button2" style={{ backgroundColor: '#012266', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("shipperCheckInTime")}>
                          Arrived
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Arrived
                        </div>
                      )
                    }

                    {
                     ( loadData.shipperCheckOutTime === null && loadData.shipperCheckInTime !== null)  ? (
                        <div className="status-button2" style={{ backgroundColor: '#BF032C', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("shipperCheckOutTime")}>
                          Departed
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Departed
                        </div>
                      )
                    }
                  </div>
                </div></>) : (<>
                  {(loadData.shipperAddress === null) && <div class="text-bg-primary p-3">Accept the tracking to get the address for this load Or Contact Dispatcher to add the address details. </div>}
                </>)}

              {loadData.shipper2Address ? (<>  <div className="row mb-2">
                <div className="mb-3"> </div>
                <div className="d-flex justify-content-start mb-3">
                  <FaUser className="me-2" />
                  {loadData.shipper2Name ? loadData.shipper2Name : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaLocationArrow className="me-2" />
                  {loadData.shipper2Address ? loadData.shipper2Address : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaClock className="me-2" />
                  {loadData.shipper2StartTime ? loadData.shipper2StartTime : "N/A"}
                  {" - "}
                  {loadData.shipper2EndTime ? loadData.shipper2EndTime : "N/A"}
                </div>

              </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex justify-content-between">
                    {
                      loadData.shipper2CheckInTime === null ? (
                        <div className="status-button2" style={{ backgroundColor: '#012266', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("shipper2CheckInTime")}>
                          Arrived
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Arrived
                        </div>
                      )
                    }

                    {
                      (loadData.shipper2CheckOutTime === null && loadData.shipper2CheckInTime !== null) ? (
                        <div className="status-button2" style={{ backgroundColor: '#BF032C', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("shipper2CheckOutTime")}>
                          Departed
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Departed
                        </div>
                      )
                    }
                  </div>
                </div></>) : (<></>)}
            </div>

            <div id="receiverDetails" className="card p-2 mt-4" style={{ backgroundColor: "#ededed" }}><div className="fw-bold text-center ">Receiver Times </div> <span className="text-danger">{(loadData.driver && loadData.driver2) && "( For " + loadData.driver2 + " )"} </span><hr className="rounded"></hr>
              {loadData.receiverAddress ? (<>  <div className="row mb-2">

                <div className="d-flex justify-content-start mb-3">
                  <FaUser className="me-2" />
                  {loadData.receiverName ? loadData.receiverName : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaLocationArrow className="me-2" />
                  {loadData.receiverAddress ? loadData.receiverAddress : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaClock className="me-2" />
                  {loadData.receiverStartTime ? loadData.receiverStartTime : "N/A"}
                  {" - "}
                  {loadData.receiverEndTime ? loadData.receiverEndTime : "N/A"}
                </div>


              </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex justify-content-between">
                    {
                      loadData.receiverCheckInTime === null ? (
                        <div className="status-button2" style={{ backgroundColor: '#012266', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("receiverCheckInTime")}>
                          Arrived
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Arrived
                        </div>
                      )
                    }

                    {
                      (loadData.receiverCheckOutTime === null && loadData.receiverCheckInTime !== null ) ? (
                        <div className="status-button2" style={{ backgroundColor: '#BF032C', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("receiverCheckOutTime")}>
                          Departed
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Departed
                        </div>
                      )
                    }
                  </div>
                </div></>) : (<>
                  {(loadData.receiverAddress === null) && <div class="text-bg-primary p-3">Accept the tracking to get the address for this load Or Contact Dispatcher to add the address details. </div>}
                </>)}

              {loadData.receiver2Address ? (<>  <div className="row mb-2">
                <div className="mb-3"> </div>
                <div className="d-flex justify-content-start mb-3">
                  <FaUser className="me-2" />
                  {loadData.receiver2Name ? loadData.receiver2Name : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaLocationArrow className="me-2" />
                  {loadData.receiver2Address ? loadData.receiver2Address : "N/A"}
                </div>
                <div className="d-flex justify-content-start  mb-3">
                  <FaClock className="me-2" />
                  {loadData.receiver2StartTime ? loadData.receiver2StartTime : "N/A"}
                  {" - "}
                  {loadData.receiver2EndTime ? loadData.receiver2EndTime : "N/A"}
                </div>
              </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex justify-content-between">
                    {
                      loadData.receiver2CheckInTime === null ? (
                        <div className="status-button2" style={{ backgroundColor: '#012266', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("receiver2CheckInTime")}>
                          Arrived
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Arrived
                        </div>
                      )
                    }

                    {
                      (loadData.receiver2CheckOutTime === null && loadData.receiver2CheckInTime !== null) ? (
                        <div className="status-button2" style={{ backgroundColor: '#BF032C', color: '#FFFFFF' }} onClick={() => handleTimeButtonClick("receiver2CheckOutTime")}>
                          Departed
                        </div>
                      ) : (
                        <div className="status-button2" style={{ backgroundColor: '#CCCCCC' }}>
                          Departed
                        </div>
                      )
                    }
                  </div>
                </div></>) : (<></>)}</div>
          </div>
          ) : (<>
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>)}
        </div>
      </div>
    </div>
  );
};

export default EditButtons;
