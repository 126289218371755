import React, { useContext } from "react";
import BrokersList from "../../Components/AdminDispatcher/BrokersList";
import PageTitle from "../../Components/Shared/PageTitle";
import { OpenContext } from "../../App";
import NotFound from "../Shared/NotFound";
import BrokersAgentList from "../../Components/AdminDispatcher/BrokersAgentList";

const Brokers = () => {
  const { userRole, isLoading } = useContext(OpenContext);
  return (
    <div>
      {!isLoading && (
        <>
          {userRole === "admin" || userRole === "dispatcher" ? (
            <>
              <PageTitle
                title="Brokers / Agents"
                description="Dispatchers can create load"
              />
              <ul className="nav nav-tabs" id="myTab23" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="brokers-agent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#brokers-agent"
                    type="button"
                    role="tab"
                    aria-controls="brokers-agent"
                    aria-selected="false"
                  >
                    Brokers
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="brokers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#brokers"
                    type="button"
                    role="tab"
                    aria-controls="brokers"
                    aria-selected="true"
                  >
                    Agents
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTab23Content">
                <div
                  className="tab-pane fade show active"
                  id="brokers-agent"
                  role="tabpanel"
                  aria-labelledby="brokers-agent-tab"
                >
                  <BrokersList userRole={userRole} />
                </div>
                <div
                  className="tab-pane fade"
                  id="brokers"
                  role="tabpanel"
                  aria-labelledby="brokers-tab"
                >
                  <BrokersAgentList userRole={userRole} />
                </div>
              </div>
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </div>
  );
};

export default Brokers;
