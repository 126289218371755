import React, { useState, useEffect } from "react";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { FaDollarSign, FaPencilAlt, FaTrash } from "react-icons/fa";
import "../../Styling/AgentList.css";
import EditUser from "../../Components/Admin/EditUser";
import SignupUser from "../../Components/Admin/SignupUser";
import TableLoader from "../Shared/TableLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditFinances from "./EditFinances";

const Userlist = ({ role }) => {
  const [myData, setMyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false); // State variable to trigger table reload
  const [selectedUser, setSelectedUser] = useState(null); // State variable to store the selected user data
  const [selectedUserId, setSelectedUserId] = useState(null); // State variable to store the selected user data

  async function getData() {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.USERS, {
        params: { role: role.toLowerCase() },
      });
      const data = response.data.message[0];
      setMyData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error getting userlist:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [reloadTable]);

  const handleDeleteRow = async (index) => {
    const updatedData = [...myData];
    const userName = updatedData[index][1];
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${role} named ${userName}?`
    );
    if (!confirmDelete) {
      return;
    }
    let id = toast.loading("Removing user.Please wait..."); // Update id inside the try block
    try {
      await axiosInstance.delete(API_ENDPOINTS.USERS, {
        params: { userName },
      });
      updatedData.splice(index, 1);
      toast.update(id, {
        render: "User deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      setMyData(updatedData);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  const handleSaveSuccess = () => {
    setReloadTable((prevReloadTable) => !prevReloadTable);
  };

  const handleEditRow = (index) => {
    setSelectedUser(myData[index]);
  };

  const handleEditFinances = (index) => {
    setSelectedUserId(myData[index][0]);
  };

  return (
    <div>
      <div className="text-center mb-2">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-toggle="modal"
          data-bs-target="#modal1"
        >
          Add New {role}
        </button>
      </div>
      {isLoading ? (
        <TableLoader />
      ) : myData.length > 0 ? (
        <div>
          <div className="table-responsive shadow mb-4">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Loan</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {myData.map((userData, index) => {
                  const [userId, name, phone, email, status, bgColor, loan] =
                    userData;

                  return (
                    <tr key={index}>
                      <td>
                        <span
                          className={`status-button`}
                          style={{ backgroundColor: bgColor }}
                        >
                          {" "}
                          {name}
                        </span>
                      </td>
                      <td>{phone}</td>
                      <td>{email}</td>
                      <td>{loan}</td>
                      <td>
                        <span
                          className={`status-circle agent-status-${status.toLowerCase()}`}
                        ></span>
                        <span className="status-text">{status}</span>
                      </td>
                      <td>
                        <span className="actions">
                          <FaTrash
                            className="DelBin"
                            onClick={() => handleDeleteRow(index)}
                          />
                          <FaPencilAlt
                            className="PenTip"
                            onClick={() => handleEditRow(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#modal2"
                          />
                          <FaDollarSign
                            className="PenTip"
                            onClick={() => handleEditFinances(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#modal3"
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <p>No {role + "s"} to Show</p>
        </div>
      )}

      <SignupUser onSaveSuccess={handleSaveSuccess} role={role.toLowerCase()} />
      <EditUser onSaveSuccess={handleSaveSuccess} selectedData={selectedUser} />
      <EditFinances
        onSaveSuccess={handleSaveSuccess}
        selectedDataId={selectedUserId}
      />
    </div>
  );
};

export default Userlist;
