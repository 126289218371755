import React, { useContext } from "react";
import PageTitle from "../../Components/Shared/PageTitle";
import { OpenContext } from "../../App";
import NotFound from "../Shared/NotFound";
import InvoicesTableAdmin from "../../Components/Admin/InvoicesTableAdmin";

const Invoices = () => {
  const { userRole, isLoading } = useContext(OpenContext);
  return (
    <>
      {!isLoading && (
        <>
          {userRole === "admin" && (
            <div>
              <PageTitle
                title="Invoices"
                description="Admin has special roles"
              />
              <InvoicesTableAdmin userRole={userRole} />
            </div>
          )}
          {userRole !== "admin" &&
            userRole !== "dispatcher" &&
            userRole !== "driver" && <NotFound />}
        </>
      )}
    </>
  );
};

export default Invoices;
