const TableLoader = () => {
  return (
    <div>
    <div className="table-responsive shadow mb-4">
      <table className="table table-loader">
        <thead>
          <tr>
            <th className="text-center">Please Hold a little bit while loads is/are being fetched.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "100%" }}>
              <span></span>
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%" }}>
              <span></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default TableLoader;