import React from "react";
import LoginForm from "../../Components/Shared/LoginForm";


const Login = () => {
  return (
    <div className="container">
        <LoginForm  />
    </div>
  );
};

export default Login;
