import React, { useState, useEffect } from "react";
import { FaEye, FaFileAlt, FaPencilAlt, FaTrash } from "react-icons/fa";
import "../../Styling/AgentList.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import CreateLoad from "../AdminDispatcher/CreateLoad";
import InvoiceFilter from "../Admin/InvoiceFilter";
import TableLoader from "../Shared/TableLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewInvoice from "./ViewInvoice";
import EditInvoice from "./EditInvoice";
import InvoiceAct from "./InvoiceAct";

const InvoicesTableAdmin = ({ userRole }) => {
  const [myData, setMyData] = useState([]);
  const [pageLimit, setPageLimit] = useState(1);
  const [reloadTable, setReloadTable] = useState(false); // State variable to trigger table reload
  const [page, setPage] = useState(1);
  const [loadCount, setLoadCount] = useState(100);
  const [itemCount, setItemCount] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadFilterVisible, setIsLoadFilterVisible] = useState(false);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState(null);
  const [refreshViewModal, setRefreshViewModal] = useState(false);
  const [refreshEditModal, setRefreshEditModal] = useState(false);
  const [refreshInvoiceModal, setRefreshInvoiceModal] = useState(false);

  const [isDeleting, setIsDeleting] = useState(true);
  const [filterData, setFilterData] = useState({
    ByLoadNo: "",
    ByNo: "",
    ByPaymentMethod: "All",
    ByBroker: "All",
    ByStatus: "All",
    ByMonth: "All",
  });

  async function getData(params) {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.INVOICESLIST, {
        params: params,
      });
      const { data } = response.data;
      setMyData(data["items"]);
      setPageLimit(data["pagination"]["totalPages"]);
      setItemCount(data["pagination"]["totalItems"]);
    } catch (error) {
      console.error("Error:", error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData({
      pageId: page,
      invCountId: loadCount,
      ...filterData,
    });
  }, [reloadTable, page, loadCount, filterData]);

  const handleDeleteRow = async (invoiceId) => {
    if (isDeleting === true) {
      setIsDeleting(false);
      const confirmDelete = window.confirm(
        `Are you sure you want to delete invoice? This will also delete the supporting documents.`
      );
      if (!confirmDelete) {
        setIsDeleting(true);
        return;
      }
      let id = toast.loading("invoice is being removed. Please wait... "); // Update id inside the try block
      try {
        await axiosInstance.delete(API_ENDPOINTS.INVOICESDETAILS, {
          params: { invoiceId: invoiceId },
        });
        setPageLimit(pageLimit - 1);
        if (page > 1) {
          setPage(page - 1);
        }
        toast.update(id, {
          render: "Successful, Invoice  was deleted.",
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });
        setReloadTable((prevReloadTable) => !prevReloadTable);
        setIsDeleting(true);
      } catch (error) {
        console.error("Error:", error);
        toast.update(id, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 1500,
        });
        setIsDeleting(true);
      }
    } else {
      window.alert("System is Busy. Please Try Again Later");
    }
  };

  const handleSaveSuccess = () => {
    setReloadTable((prevReloadTable) => !prevReloadTable);
  };

  const handleViewRow = (index) => {
    setSelectedInvoiceData(myData[index]);
    setRefreshViewModal(true);
  };

  const handleEditRow = (index) => {
    setSelectedInvoiceData(myData[index]);
    setRefreshEditModal(true);
  };

  const handleInvoiceRow = (index) => {
    setSelectedInvoiceData(myData[index]);
    setRefreshInvoiceModal(true);
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const handleLoadCountChange = (selectedValue) => {
    setLoadCount(selectedValue);
    setPage(1);
  };

  const toggleLoadFilter = () => {
    setIsLoadFilterVisible((prev) => !prev);
  };

  const getDefaultLoads = () => {
    setFilterData({
      ByLoadNo: "",
      ByNo: "",
      ByPaymentMethod: "All",
      ByBroker: "All",
      ByStatus: "All",
      ByMonth: "All",
    });
  };

  const getSentLoads = () => {
    setFilterData({
      ByLoadNo: "",
      ByNo: "",
      ByPaymentMethod: "All",
      ByBroker: "All",
      ByStatus: "Sent",
      ByMonth: "All",
    });
  };

  const sendData = (data) => {
    setPage(1);
    if (data !== null) {
      setFilterData(data);
    } else {
      setFilterData({
        ByLoadNo: "",
        ByNo: "",
        ByPaymentMethod: "All",
        ByBroker: "All",
        ByStatus: "All",
        ByMonth: "All",
      });
    }
  };

  function formatDateTime(dateStr) {
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    let parts = dateStr.split(" ");
    let day = parts[1];
    let month = months[parts[2]];
    let year = parts[3];
    let formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={toggleLoadFilter}
        >
          Filter
        </button>
        <button
          type="button"
          className="btn reg-user-submit"
          onClick={getDefaultLoads}
        >
          Restore Default
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={getSentLoads}
        >
          Sent
        </button>
      </div>
      {isLoadFilterVisible && <InvoiceFilter sendData={sendData} />}
      <div className="col-md-12">
        {isLoading ? (
          <TableLoader />
        ) : myData.length > 0 ? (
          <div className="table-responsive shadow mb-4">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>PU Date</th>
                  <th>Broker</th>
                  <th>Label</th>
                  <th>Load#</th>
                  <th>Invoice#</th>
                  <th>Inv. Date</th>
                  <th>Rate($)</th>
                  <th>Misc($)</th>
                  <th>Gross($)</th>
                  <th>Received($)</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {myData.map((userData, index) => {
                  const [
                    Id,
                    LoadNo,
                    Label,
                    Number,
                    Broker,
                    PaidStatus,
                    loadShipperPickupDate,
                    Date,
                    TotalDue,
                    AmountReceived,
                    Rate,
                    Detention,
                    Lumper,
                    MiscAmount,
                    MiscLabel,
                  ] = userData;
                  return (
                    <tr key={index}>
                      <td>{formatDateTime(loadShipperPickupDate)}</td>
                      <td>{Broker}</td>
                      <td>{Label}</td>
                      <td>{LoadNo}</td>
                      <td>{Number}</td>
                      <td>{formatDateTime(Date)}</td>
                      <td>{Rate}</td>
                      <td>
                        <div className="d-flex">
                          <div
                            className="text-danger fw-bold"
                            style={{ display: "inline" }}
                          >
                            {Detention && Detention > 0 ? (
                              <span>D ${Detention} </span>
                            ) : null}
                            {Lumper && Lumper > 0 ? (
                              <span>L ${Lumper} </span>
                            ) : null}
                            {MiscAmount &&
                            MiscAmount > 0 &&
                            MiscLabel &&
                            MiscLabel !== "" ? (
                              <span>
                                {MiscLabel} ${MiscAmount}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </td>
                      <td>{TotalDue}</td>
                      <td>{AmountReceived}</td>
                      <td>
                        <div
                          className={`p-1 invoice-status-${PaidStatus} text-center btn-sm`}
                        >
                          {PaidStatus}
                        </div>
                      </td>
                      <td>
                        <span className="actions">
                          {
                            <FaTrash
                              className="DelBin"
                              onClick={() => handleDeleteRow(Id)}
                            />
                          }
                          <FaEye
                            className="PenTip"
                            onClick={() => handleViewRow(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#modalViewInvoice"
                          />
                          {
                            <FaPencilAlt
                              className="EyeTip"
                              onClick={() => handleEditRow(index)}
                              data-bs-toggle="modal"
                              data-bs-target="#modalEditInvoice"
                            />
                          }
                          {
                            <FaFileAlt
                              className="PageInvoice"
                              onClick={() => handleInvoiceRow(index)}
                              data-bs-toggle="modal"
                              data-bs-target="#modalInvoiceAct"
                            />
                          }
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="d-flex justify-content-center mx-auto align-items-center my-3">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center mb-0">
                  <li
                    className={`page-item ${
                      !(page - 1 >= 1) ? "disabled" : ""
                    }`}
                  >
                    <div
                      className={`page-link ${
                        !(page - 1 >= 1) ? "" : "pointer-cursor-page"
                      }`}
                      aria-disabled={!(page - 1 >= 1)}
                      onClick={() => handlePageClick(page - 1)}
                    >
                      Previous
                    </div>
                  </li>

                  {page - 2 >= 1 && (
                    <li className="page-item pointer-cursor-page">
                      <div
                        className="page-link"
                        onClick={() => handlePageClick(1)}
                      >
                        {1}
                      </div>
                    </li>
                  )}

                  {page - 2 >= 1 && (
                    <li className="page-item disabled">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  <li className="page-item pointer-cursor-page">
                    <div
                      className="page-link fw-bold"
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </div>
                  </li>

                  {page + 1 <= pageLimit && (
                    <li className="page-item pointer-cursor-page">
                      <div
                        className="page-link"
                        onClick={() => handlePageClick(page + 1)}
                      >
                        {page + 1}
                      </div>
                    </li>
                  )}

                  {page + 1 < pageLimit - 1 && (
                    <li className="page-item disabled">
                      <span className="page-link">...</span>
                    </li>
                  )}

                  {page + 2 <= pageLimit && (
                    <li className="page-item pointer-cursor-page">
                      <div
                        className="page-link"
                        onClick={() => handlePageClick(pageLimit)}
                      >
                        {pageLimit}
                      </div>
                    </li>
                  )}

                  <li
                    className={`page-item ${
                      !(page + 1 <= pageLimit) ? "disabled" : ""
                    }`}
                  >
                    <div
                      className={`page-link ${
                        !(page + 1 <= pageLimit) ? "" : "pointer-cursor-page"
                      }`}
                      aria-disabled={!(page + 1 <= pageLimit)}
                      onClick={() => handlePageClick(page + 1)}
                    >
                      Next
                    </div>
                  </li>
                </ul>
              </nav>

              <div className="d-flex align-items-center">
                <p className="form-label my-0 text-nowrap mx-3">
                  Loads Per Page :
                </p>
                <select
                  className="form-select"
                  defaultValue={loadCount}
                  onChange={(e) => handleLoadCountChange(e.target.value)}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p>Record Not Found</p>
          </div>
        )}
      </div>

      <CreateLoad onSaveSuccess={handleSaveSuccess} role={userRole} />
      <ViewInvoice
        selectedInvoiceData={selectedInvoiceData}
        setRefreshViewModal={setRefreshViewModal}
        refreshViewModal={refreshViewModal}
      />
      <EditInvoice
        onSaveSuccess={handleSaveSuccess}
        selectedInvoiceData={selectedInvoiceData}
        setRefreshEditModal={setRefreshEditModal}
        refreshEditModal={refreshEditModal}
      />
      <InvoiceAct
        onSaveSuccess={handleSaveSuccess}
        selectedInvoiceData={selectedInvoiceData}
        setRefreshInvoiceModal={setRefreshInvoiceModal}
        refreshInvoiceModal={refreshInvoiceModal}
      />
    </div>
  );
};

export default InvoicesTableAdmin;
