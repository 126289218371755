import { Link } from 'react-router-dom';
import { FaHome, FaCubes, FaTruck, FaUsers, FaUserEdit, FaSignOutAlt, FaUserCircle, FaAddressBook, FaFileAlt } from 'react-icons/fa';
import AAL_Logo from '../../Assets/AAL_Logo.png';
import React, { useContext } from 'react';
import { API_ENDPOINTS } from '../../Api/apiConfig';
import { OpenContext } from '../../App';
import axios from 'axios';



const NavbarLoggedIn = ({ role, username }) => {
  const { reloadNavbarComponent } = useContext(OpenContext);

  const handleLogoutUser = async () => {
    try {
      await axios.delete(API_ENDPOINTS.ME, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authkeytemp')}`
        }
      });
      localStorage.removeItem('authkeytemp');
      reloadNavbarComponent();
    } catch (error) {
      console.error('Error logging out:', error);
      localStorage.removeItem('authkeytemp');
      reloadNavbarComponent();
    }
  };


  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <Link className="navbar-brand" to="/">
            <img src={AAL_Logo} alt="" width="150" height="auto" />
          </Link>
          <div id="receiverDetails" className="fw-bold text-center">{role.charAt(0).toUpperCase() + role.slice(1)}: {username.charAt(0).toUpperCase() + username.slice(1)}</div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              {role === 'admin' && <li className="nav-item">
                <Link className="nav-link" to="/home">
                  <FaHome className='navbar-icon' /> Home
                </Link>
              </li>}
              {['admin', 'dispatcher', 'driver'].includes(role) && (
                <li className="nav-item">
                  <Link className="nav-link" to="/loads">
                    <FaCubes className='navbar-icon' /> Loads
                  </Link>
                </li>
              )}
                {role === 'admin' && <li className="nav-item">
                <Link className="nav-link" to="/invoices">
                  <FaFileAlt className='navbar-icon' /> Invoices
                </Link>
              </li>}

              {role === 'admin' && <li className="nav-item">
                <Link className="nav-link" to="/drivers">
                  <FaTruck className='navbar-icon' /> Drivers
                </Link>
              </li>}
              {role === 'admin' && <li className="nav-item">
                <Link className="nav-link" to="/dispatchers">
                  <FaUsers className='navbar-icon' /> Dispatchers
                </Link>
              </li>}
              

              {['admin', 'dispatcher'].includes(role) && <li className="nav-item">
                <Link className="nav-link" to="/brokers">
                  <FaAddressBook className='navbar-icon' /> Brokers
                </Link>
              </li>}
              {role === 'admin' && <li className="nav-item dropdown">
                <div className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <FaUserCircle className='navbar-icon' /> Account
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="nav-item">
                    <Link className="nav-link" to="/edit-Logins">
                      <FaUserEdit className='navbar-icon' /> Edit Logins
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#" onClick={handleLogoutUser} >
                      <FaSignOutAlt className='navbar-icon' /> Logout
                    </Link>
                  </li>
                </ul>
              </li>}
              {role !== 'admin' && (
                <li className="nav-item">
                  <Link className="nav-link" to="#" onClick={handleLogoutUser} >
                    <FaSignOutAlt className='navbar-icon' /> Logout
                  </Link>
                </li>
              )}

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarLoggedIn;
