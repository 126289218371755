import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const EditFinances = ({ onSaveSuccess, selectedDataId }) => {
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm();
  const [userData, setUserData] = useState({
    accountName: "",
    accountDetails: "",
    loan: "",
    notes: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  async function get_user_account_details(selectedKey) {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.USERFINANCES, {
        params: { userId: selectedKey },
      });
      const data = response.data.message[0];
      setUserData(data);
    } catch (error) {
      console.error("Error getting userlist:", error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    // Set initial values for Phone and Email fields using selectedData
    if (selectedDataId) {
      reset();
      get_user_account_details(selectedDataId);
    }
  }, [selectedDataId]);

  useEffect(() => {
    // Set initial values for Phone and Email fields using selectedData
    if (userData) {
      setValue("accountName", userData.accountName);
      setValue("accountDetails", userData.accountDetails);
      setValue("loanAmount", userData.loan);
      setValue("loanNotes", userData.notes);
    }
  }, [userData]);

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating user details.Please wait..."); // Update id inside the try block
    try {
      await axiosInstance.patch(API_ENDPOINTS.USERFINANCES, data, {
        params: { userId: selectedDataId },
      });
      toast.update(id, {
        render: "User finances updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      onSaveSuccess();
      document.getElementById("closeButtonEditFinances").click();
    } catch (error) {
      console.error("Error updating user finances:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="modal3"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">Modification Finances</h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Account Name:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("accountName", { maxLength: 30 })}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label fw-bold">Acount Details:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("accountDetails", { maxLength: 60 })}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label fw-bold">Loan Amount:</label>
                  <input
                    type="number"
                    className="form-control create-input"
                    {...register("loanAmount", { maxLength: 255 })}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Loan Notes:</label>
                  <textarea
                    type="text"
                    className="form-control create-input-text-area"
                    {...register("loanNotes", {})}
                  />
                </div>
              </div>

              <div>
                <button type="submit" className="btn reg-user-submit">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id="closeButtonEditFinances"
                >
                  Close
                </button>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditFinances;
