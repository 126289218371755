import React, { useState, useEffect, useContext } from 'react';
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import NavbarLoggedIn from '../Shared/NavbarLoggedIn';
import NavbarLoggedOut from './NavbarLoggedOut';
import { OpenContext } from '../../App';
import { useHistory } from 'react-router-dom';

const Navbar = ({ role = null }) => {
    const { handleUserRole, reloadNavbar } = useContext(OpenContext);
    const history = useHistory();
    const [userRole, setUserRole] = useState(role);
    const [userName, setUserName] = useState(role);

    useEffect(() => {
        const fetchData = async () => {
            if (localStorage.getItem("authkeytemp")) {
                try {
                    const response = await axiosInstance.get(API_ENDPOINTS.ME);
                    setUserRole(response.data.message.role);
                    setUserName(response.data.message.username);
                    handleUserRole(response.data.message.role);

                } catch (error) {
                    console.error('Error user current user detils:', error);
                    localStorage.removeItem('authkeytemp');
                    setUserRole(null);
                    setUserName(null);
                    handleUserRole(null);
                    history.push('/login');
                }
            } else {
                localStorage.removeItem('authkeytemp');
                setUserRole(null);
                setUserName(null);
                handleUserRole(null);
                history.push('/login');
            }
        };

        fetchData();
    }, [reloadNavbar]);

    return (
        <div>
            {['admin', 'dispatcher', 'driver'].includes(userRole) && <NavbarLoggedIn role={userRole} username={userName} />}
            {userRole === null && <NavbarLoggedOut />}
        </div>
    );
};

export default Navbar;
