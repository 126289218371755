import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const EditUser = ({ onSaveSuccess, selectedData }) => {
  const [currentColor, setCurrentColor] = useState("#FFFFFF"); // Initial color
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  function handleChangeComplete(event) {
    setCurrentColor(event.target.value); // Set the current color
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue, // Added setValue from react-hook-form
  } = useForm();

  const [userStatus, setuserStatus] = useState("default");

  useEffect(() => {
    // Set initial values for Phone and Email fields using selectedData
    if (selectedData) {
      setValue("userName", selectedData[1]);
      setValue("phoneNumber", selectedData[2]);
      setValue("email", selectedData[3]);
      setuserStatus(selectedData[4]);
      if (selectedData[5]) {
        setCurrentColor(selectedData[5]);
      }
    }
  }, [selectedData]);

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating user details.Please wait..."); // Update id inside the try block
    try {
      data.backgroundColor = String(currentColor);
      await axiosInstance.patch(API_ENDPOINTS.USERS, data, {
        params: { userName: selectedData[0] },
      });
      toast.update(id, {
        render: "User details updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      onSaveSuccess();
      setValue("password", "");
      setValue("confirmPassword", "");
      setCurrentColor("#FFFFFF");
      document.getElementById("closeButtonEditUser").click();
    } catch (error) {
      console.error("Error updating user details:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  const handleuserChange = (event) => {
    setuserStatus(event.target.value);
  };

  const password = watch("password");

  return (
    <div
      className="modal fade"
      id="modal2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">Modification</h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Username:</label>
                <input
                  type="text"
                  className="form-control editdisabled"
                  value={selectedData ? selectedData[0] : ""}
                  disabled
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Phone</label>
                <input
                  type="tel"
                  placeholder="12345678900"
                  className="form-control create-input"
                  {...register("phoneNumber", { maxLength: 15 })}
                />
                {errors.phone && errors.phone.type === "maxLength" && (
                  <span className="text-danger fst-italic">
                    Phone number must not exceed 15 characters
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Email</label>
                <input
                  type="email"
                  placeholder="user@example.com"
                  className="form-control create-input"
                  {...register("email", { maxLength: 255 })}
                />
                {errors.email && errors.email.type === "maxLength" && (
                  <span className="text-danger fst-italic">
                    Email must not exceed 255 characters
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="form-control create-input"
                  {...register("password", { minLength: 8 })}
                />
                {errors.password && errors.password.type === "required" && (
                  <span className="text-danger fst-italic">
                    This field is required
                  </span>
                )}
                {errors.password && errors.password.type === "minLength" && (
                  <span className="text-danger fst-italic">
                    Password must be at least 8 characters long
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label fw-bold">Confirm Password</label>
                <input
                  className="form-control create-input"
                  type="password"
                  placeholder="Retype Above Password"
                  {...register("confirmPassword", {
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  })}
                />
                {errors.confirmPassword && (
                  <span className="text-danger fst-italic">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Status:</label>
                <select
                  className="form-control create-input"
                  {...register("status")}
                  value={userStatus}
                  onChange={handleuserChange}
                >
                  <option value="Active">Active</option>
                  <option value="Leave">Leave</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <input
                  type="color"
                  value={currentColor}
                  onChange={handleChangeComplete}
                />
              </div>
              <div className="col-md-6">
                <span
                  className="status-button"
                  style={{ backgroundColor: currentColor }}
                >
                  Name XYZ
                </span>
              </div>
            </div>
            <div>
              <button type="submit" className="btn reg-user-submit">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="closeButtonEditUser"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
