import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const CreateLoad = ({ onSaveSuccess, role }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [drivers, setDrivers] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [LoadExists, setLoadExists] = useState(false);
  const [CheckingLoadExists, setCheckingLoadExists] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [brokerAgents, setBrokerAgents] = useState([]);
  const [selectedFilesRC, setSelectedFilesRC] = useState([]);
  const [selectedFilesRCD, setSelectedFilesRCD] = useState([]);
  const [selectedFilesBOL, setSelectedFilesBOL] = useState([]);
  const [selectedFilesST, setSelectedFilesST] = useState([]);
  const [selectedFilesLR, setSelectedFilesLR] = useState([]);
  const [selectedFilesMD, setSelectedFilesMD] = useState([]);
  const [selectedFilesLS, setSelectedFilesLS] = useState([]);
  const [secondShipperVisible, setSecondShipperVisible] = useState(false);
  const [secondRecieverVisible, setSecondReceiverVisible] = useState(false);
  const [secondDriverVisible, setDriverVisible] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const handleFileChangeRC = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesRC.some((existingFile) => existingFile.name === file.name)
    );
    setSelectedFilesRC([...selectedFilesRC, ...uniqueFiles]);
  };

  const handleFileChangeRCD = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesRCD.some(
          (existingFile) => existingFile.name === file.name
        )
    );
    setSelectedFilesRCD([...selectedFilesRCD, ...uniqueFiles]);
  };

  const handleFileChangeBOL = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesBOL.some(
          (existingFile) => existingFile.name === file.name
        )
    );
    setSelectedFilesBOL([...selectedFilesBOL, ...uniqueFiles]);
  };

  const handleFileChangeST = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesST.some((existingFile) => existingFile.name === file.name)
    );
    setSelectedFilesST([...selectedFilesST, ...uniqueFiles]);
  };

  const handleFileChangeLR = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesLR.some((existingFile) => existingFile.name === file.name)
    );
    setSelectedFilesLR([...selectedFilesLR, ...uniqueFiles]);
  };

  const handleFileChangeMD = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesMD.some((existingFile) => existingFile.name === file.name)
    );
    setSelectedFilesMD([...selectedFilesMD, ...uniqueFiles]);
  };

  const handleFileChangeLS = (event) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = files.filter(
      (file) =>
        !selectedFilesLS.some((existingFile) => existingFile.name === file.name)
    );
    setSelectedFilesLS([...selectedFilesLS, ...uniqueFiles]);
  };

  const handleFileDeleteRC = (fileName) => {
    const updatedFiles = selectedFilesRC.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesRC(updatedFiles);
  };

  const handleFileDeleteRCD = (fileName) => {
    const updatedFiles = selectedFilesRCD.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesRCD(updatedFiles);
  };

  const handleFileDeleteBOL = (fileName) => {
    const updatedFiles = selectedFilesBOL.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesBOL(updatedFiles);
  };

  const handleFileDeleteST = (fileName) => {
    const updatedFiles = selectedFilesST.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesST(updatedFiles);
  };

  const handleFileDeleteLR = (fileName) => {
    const updatedFiles = selectedFilesLR.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLR(updatedFiles);
  };

  const handleFileDeleteMD = (fileName) => {
    const updatedFiles = selectedFilesMD.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesMD(updatedFiles);
  };

  const handleFileDeleteLS = (fileName) => {
    const updatedFiles = selectedFilesLS.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLS(updatedFiles);
  };

  const fetchDrivers = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.USERNAMES, {
        params: { role: "driver" },
      });
      setDrivers(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  const fetchBrokers = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.BROKERNAMES);
      setBrokers(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  const fetchBrokerAgents = async () => {
    setBrokerAgents([]);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.AGENTNAMES, {
        params: { brokerKey: selectedBroker },
      });
      setBrokerAgents(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  const toggleSecondShipper = () => {
    setSecondShipperVisible((prev) => !prev);
  };

  const toggleSecondReceiver = () => {
    setSecondReceiverVisible((prev) => !prev);
  };

  const toggleSecondDriver = () => {
    setDriverVisible((prev) => !prev);
  };

  const handleBrokerChange = (e) => {
    const selectedBrokerId = e.target.value;
    const selectedBroker1 = brokers.find(
      (broker) => String(broker[0]) === selectedBrokerId
    );

    if (selectedBroker1) {
      setSelectedBroker(selectedBroker1[0]);
      setValue("brokerName", selectedBroker1[1]);
      setValue("brokerMc", selectedBroker1[2]);
      setValue("brokerAfterHours", selectedBroker1[3]);
    }
  };

  const handleBrokerAgentChange = (e) => {
    const selectedAgentId = e.target.value;
    const selectedAgent = brokerAgents.find(
      (agent) => String(agent[0]) === selectedAgentId
    );

    if (selectedAgent) {
      setValue("brokerAgent", selectedAgent[1]);
      setValue("brokerPhone", selectedAgent[2]);
      setValue("brokerEmail", selectedAgent[3]);
    }
  };

  const handleLoadNoChange = async (e) => {
    const inputValue = e.target.value.trim();
    if (inputValue !== "") {
      setCheckingLoadExists(true);
      try {
        const response = await axiosInstance.get(API_ENDPOINTS.CHECKLOADNO, {
          params: { loadno: inputValue },
        });
        setLoadExists(response.data);
      } catch (error) {
        console.error("Error getting load# status:", error);
      }
      setCheckingLoadExists(false);
    }
  };

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Load is being created. Please wait... ");
    try {
      const formData = new FormData();
      Object.keys(data).forEach((val) => {
        val !== "assignedBrokerUserName" &&
          val !== "assignedBrokerAgentUserName" &&
          formData.append(val, data[val]);
      });

      selectedFilesRC.forEach((file) => {
        formData.append("rateCont", file);
      });
      selectedFilesRCD.forEach((file) => {
        formData.append("rateContDriver", file);
      });
      selectedFilesBOL.forEach((file) => {
        formData.append("billOfLanding", file);
      });
      selectedFilesST.forEach((file) => {
        formData.append("scaleTicket", file);
      });
      selectedFilesLR.forEach((file) => {
        formData.append("lumperReceipt", file);
      });
      selectedFilesMD.forEach((file) => {
        formData.append("miscellaneousDocuments", file);
      });
      selectedFilesLS.forEach((file) => {
        formData.append("locationScreenshots", file);
      });
      await axiosInstance.post(API_ENDPOINTS.LOADS, formData);
      toast.update(id, {
        render: "Successful, Load was created.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      reset();
      onSaveSuccess();
      setSecondReceiverVisible(false);
      setSecondShipperVisible(false);
      setDriverVisible(false);
      setBrokerAgents([]);
      setBrokers([]);
      setCheckingLoadExists(false);
      setLoadExists(false);
      setDrivers([]);
      setSelectedFilesBOL([]);
      setSelectedFilesLR([]);
      setSelectedFilesRC([]);
      setSelectedFilesRCD([]);
      setSelectedFilesST([]);
      setSelectedFilesMD([]);
      setSelectedFilesLS([]);
      document.getElementById("closeButtonCreateLoad").click();
    } catch (error) {
      console.error("Error:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  const handleClose = () => {
    reset();
    setSecondReceiverVisible(false);
    setSecondShipperVisible(false);
    setDriverVisible(false);
    setBrokerAgents([]);
    setBrokers([]);
    setDrivers([]);
    setCheckingLoadExists(false);
    setLoadExists(false);
    setSelectedFilesBOL([]);
    setSelectedFilesLR([]);
    setSelectedFilesRC([]);
    setSelectedFilesRCD([]);
    setSelectedFilesST([]);
    setSelectedFilesMD([]);
    setSelectedFilesLS([]);
    document.getElementById("closeButtonCreateLoad").click();
  };

  return (
    <div
      className="modal fade"
      id="modal1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fw-bold fs-5">Create Load</h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="submit"
                className="btn reg-user-submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButtonCreateLoad"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Reset & Close
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="load-details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#load-details"
                  type="button"
                  role="tab"
                  aria-controls="load-details"
                  aria-selected="true"
                >
                  Load Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="assigned-agents-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#assigned-agents"
                  type="button"
                  role="tab"
                  aria-controls="assigned-agents"
                  aria-selected="false"
                >
                  Driver
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="shipper-details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#shipper-details"
                  type="button"
                  role="tab"
                  aria-controls="shipper-details"
                  aria-selected="false"
                >
                  Shipper Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="receiver-details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#receiver-details"
                  type="button"
                  role="tab"
                  aria-controls="receiver-details"
                  aria-selected="false"
                >
                  Receiver Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="broker-details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#broker-details"
                  type="button"
                  role="tab"
                  aria-controls="broker-details"
                  aria-selected="false"
                >
                  Broker Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="lumper-details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#lumper-details"
                  type="button"
                  role="tab"
                  aria-controls="lumper-details"
                  aria-selected="false"
                >
                  Lumper Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="documents-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#documents"
                  type="button"
                  role="tab"
                  aria-controls="documents"
                  aria-selected="false"
                >
                  Documents
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="notes-details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#notes-details"
                  type="button"
                  role="tab"
                  aria-controls="notes-details"
                  aria-selected="false"
                >
                  Notes
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="load-details"
                role="tabpanel"
                aria-labelledby="load-details-tab"
              >
                <div id="loadDetails" className="fw-bold text-center">
                  Load Details
                </div>
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Label: </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("label", {
                        required:
                          "Load Details -> Label : This field is required.",
                        maxLength: 100,
                      })}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Load#</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("aalLoad", { maxLength: 50 })}
                      onDoubleClick={handleLoadNoChange}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Trailer#</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("vanNumber", { maxLength: 10 })}
                    />
                  </div>

                  <div className="col-md-4 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Rate:</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("rate", { maxLength: 10 })}
                    />
                  </div>
                  <div className="col-md-2 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Is TONU?{" "}
                    </label>
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      {...register("isTonu", {})}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-12 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Instructions:
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("instruction", { maxLength: 255 })}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Escalation:
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("escalation", {})}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Det. Amount($):
                    </label>
                    <input
                      type="number"
                      className="form-control input-field"
                      {...register("detentionAmount", {})}
                    />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="assigned-agents"
                role="tabpanel"
                aria-labelledby="assigned-agents-tab"
              >
                <div id="assignedagents" className="fw-bold text-center">
                  Driver
                </div>
                <hr className="rounded"></hr>

                <div className="row mb-2">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Driver:</label>

                    <select
                      className="form-control input-field"
                      {...register("assignedDriverKey")}
                      onFocus={fetchDrivers}
                      defaultValue={"Choose..."}
                    >
                      <option key="Choose..." value="Choose...">
                        Choose...
                      </option>
                      {drivers.map((driver) => (
                        <option key={driver[0]} value={driver[0]}>
                          {driver[1]}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Status:</label>
                    <select
                      className="form-control input-field"
                      {...register("status")}
                      defaultValue={"Unassigned"}
                    >
                      <option value="Assigned">Assigned</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Completed">Completed</option>
                      <option value="Conflict">Conflict</option>
                      <option value="Unassigned">Unassigned</option>
                      <option value="TONU">TONU</option>
                    </select>
                  </div>
                </div>

                {secondDriverVisible && (
                  <>
                    <div className="row mb-2">
                      <div className="col-md-12 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Driver#2:
                        </label>

                        <select
                          className="form-control input-field"
                          {...register("assignedDriver2Key")}
                          onFocus={fetchDrivers}
                          defaultValue={"Choose..."}
                        >
                          <option key="Choose..." value="Choose...">
                            Choose...
                          </option>
                          {drivers.map((driver) => (
                            <option key={driver[0]} value={driver[0]}>
                              {driver[1]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                )}
                <div className="row ">
                  <div className="col-md-12 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={toggleSecondDriver}
                    >
                      Add Second Driver
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="shipper-details"
                role="tabpanel"
                aria-labelledby="shipper-details-tab"
              >
                <div id="shipperDetails" className=" fw-bold text-center mt-4">
                  Shipper Details
                </div>{" "}
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Name:</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("shipperName", { maxLength: 100 })}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Address:</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("shipperAddress", { maxLength: 255 })}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          Start Date:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("shipperStartDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("shipperStartWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Shipper Details -> Shipper -> Start Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          End Date:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("shipperEndDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("shipperEndWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Shipper Details -> Shipper -> End Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Instructions:
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("shipperInstruction", { maxLength: 255 })}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          Check In:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("shipperCheckInDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("shipperCheckInWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Shipper Details -> Shipper -> Check In Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          Check Out:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("shipperCheckOutDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("shipperCheckOutWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Shipper Details -> Shipper -> Check Out Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={toggleSecondShipper}
                    >
                      Add Second Pickup
                    </button>
                  </div>
                </div>
                {secondShipperVisible && (
                  <>
                    <div className="row mb-2">
                      <div className="col-md-12 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Name:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("shipper2Name", { maxLength: 100 })}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-12 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Address:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("shipper2Address", { maxLength: 255 })}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              Start Date:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("shipper2StartDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("shipper2StartWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Shipper Details -> Shipper#2 -> Start Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              End Date:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("shipper2EndDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("shipper2EndWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Shipper Details -> Shipper#2 -> End Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-12 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Instructions:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("shipper2Instruction", {
                            maxLength: 255,
                          })}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              Check In:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("shipper2CheckInDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("shipper2CheckInWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Shipper Details -> Shipper#2 -> Check In Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              Check Out:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("shipper2CheckOutDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("shipper2CheckOutWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Shipper Details -> Shipper#2 -> Check Out Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="receiver-details"
                role="tabpanel"
                aria-labelledby="receiver-details-tab"
              >
                <div id="receiverDetails" className="fw-bold text-center mt-4">
                  Reciever Details
                </div>{" "}
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Name:</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("receiverName", { maxLength: 100 })}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Address:</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("receiverAddress", { maxLength: 255 })}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          Start Date:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("receiverStartDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("receiverStartWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Receiver Details -> Receiver -> Start Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          End Date:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("receiverEndDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("receiverEndWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Receiver Details -> Receiver -> End Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-12 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Instructions:
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("receiverInstruction", { maxLength: 255 })}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          Check In:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("receiverCheckInDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("receiverCheckInWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Receiver Details -> Receiver -> Check In Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1 single-line">
                          Check Out:
                        </label>
                        <input
                          type="date"
                          className="form-control input-field"
                          {...register("receiverCheckOutDate")}
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Time:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("receiverCheckOutWaqt", {
                            pattern: {
                              value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                              message:
                                "Receiver Details -> Receiver -> Check Out Time: Enter time in format hhmm (24-hour format)",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={toggleSecondReceiver}
                    >
                      Add Second DropOff
                    </button>
                  </div>
                </div>
                {secondRecieverVisible && (
                  <>
                    <div className="row mb-2">
                      <div className="col-md-12 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Name:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("receiver2Name", { maxLength: 100 })}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-12 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Address:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("receiver2Address", { maxLength: 255 })}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              Start Date:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("receiver2StartDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("receiver2StartWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Receiver Details -> Receiver#2 -> Start Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              End Date:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("receiver2EndDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("receiver2EndWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Receiver Details -> Receiver#2 -> End Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-12 d-flex align-items-end">
                        <label className="col-form-label pb-0 mx-1">
                          Instructions:
                        </label>
                        <input
                          type="text"
                          className="form-control input-field"
                          {...register("receiver2Instruction", {
                            maxLength: 255,
                          })}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              Check In:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("receiver2CheckInDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("receiver2CheckInWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Receiver Details -> Receiver#2 -> Check In Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1 single-line">
                              Check Out:
                            </label>
                            <input
                              type="date"
                              className="form-control input-field"
                              {...register("receiver2CheckOutDate")}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-end">
                            <label className="col-form-label pb-0 mx-1">
                              Time:
                            </label>
                            <input
                              type="text"
                              className="form-control input-field"
                              {...register("receiver2CheckOutWaqt", {
                                pattern: {
                                  value: /^([01][0-9]|2[0-3])[0-5][0-9]$/,
                                  message:
                                    "Receiver Details -> Receiver#2 -> Check Out Time: Enter time in format hhmm (24-hour format)",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="broker-details"
                role="tabpanel"
                aria-labelledby="broker-details-tab"
              >
                <div id="brokerDetails " className="fw-bold text-center mt-4">
                  Broker Details
                </div>
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-6 d-flex flex-column">
                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Select:
                      </label>
                      <select
                        className="form-control input-field"
                        {...register("assignedBrokerUserName")}
                        onFocus={fetchBrokers}
                        defaultValue={"Choose..."}
                        onChange={(e) => handleBrokerChange(e)}
                      >
                        <option value="Choose...">Choose...</option>
                        {brokers.map((broker) => (
                          <option key={broker[0]} value={broker[0]}>
                            {broker[1] + (broker[2] ? ` (${broker[2]})` : "")}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Broker:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("brokerName", { maxLength: 100 })}
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">MC#:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("brokerMc", { maxLength: 50 })}
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        After Hours:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("brokerAfterHours", { maxLength: 50 })}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 d-flex flex-column">
                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Select:
                      </label>
                      <select
                        className="form-control input-field"
                        {...register("assignedBrokerAgentUserName")}
                        onFocus={fetchBrokerAgents}
                        defaultValue={"Choose..."}
                        onChange={(e) => handleBrokerAgentChange(e)}
                      >
                        <option value="Choose...">Choose...</option>
                        {brokerAgents.map((ba) => (
                          <option key={ba[0]} value={ba[0]}>
                            {ba[1]}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Agent:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("brokerAgent", { maxLength: 100 })}
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Phone:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("brokerPhone", { maxLength: 50 })}
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Email:</label>
                      <input
                        type="email"
                        className="form-control input-field"
                        {...register("brokerEmail", { maxLength: 100 })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="lumper-details"
                role="tabpanel"
                aria-labelledby="lumper-details-tab"
              >
                <div id="lumperDetails" className="fw-bold text-center mt-4">
                  Lumper Details
                </div>{" "}
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Name:</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("lumperName", { maxLength: 100 })}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Email:</label>
                    <input
                      type="email"
                      className="form-control input-field"
                      {...register("lumperEmail", { maxLength: 100 })}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Amount(USD):
                    </label>
                    <input
                      type="number"
                      className="form-control input-field"
                      {...register("lumperAmount", { maxLength: 10 })}
                    />
                  </div>
                  <div className="col-md-4 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Paid by:
                    </label>
                    <select
                      className="form-control input-field"
                      {...register("lumperPaidBy")}
                      defaultValue={"Choose..."}
                    >
                      <option value="Choose...">Choose...</option>
                      <option value="Broker">Broker</option>
                      <option value="Driver">Driver</option>
                    </select>
                  </div>
                  <div className="col-md-4 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Paid with:
                    </label>
                    <select
                      className="form-control input-field"
                      {...register("lumperPaidWith")}
                      defaultValue={"Choose..."}
                    >
                      <option value="Choose...">Choose...</option>
                      <option value="Cash">Cash</option>
                      <option value="EFSCode">EFS Code</option>
                      <option value="CreditCard">Credit Card</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="documents"
                role="tabpanel"
                aria-labelledby="documents-tab"
              >
                <div id="documents" className="fw-bold text-center mt-4">
                  Documents
                </div>{" "}
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Rate Confirmation:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeRC}
                    />
                  </div>
                  <div className="col-md-6 card">
                    <ul>
                      {selectedFilesRC.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() => handleFileDeleteRC(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Driver's Rate Confirmation:
                    </label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeRCD}
                    />
                  </div>
                  <div className="col-md-6 card">
                    <ul>
                      {selectedFilesRCD.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() => handleFileDeleteRCD(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Bill of Lading:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeBOL}
                    />
                  </div>
                  <div className="col-md-6 card">
                    <ul>
                      {selectedFilesBOL.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() => handleFileDeleteBOL(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Scale Ticket:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeST}
                    />
                  </div>
                  <div className="col-md-6 card">
                    <ul>
                      {selectedFilesST.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() => handleFileDeleteST(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Lumper Reciept:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeLR}
                    />
                  </div>
                  <div className="col-md-6 card">
                    <ul>
                      {selectedFilesLR.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger"
                            onClick={() => handleFileDeleteLR(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Location Screenshots:
                    </label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeLS}
                    />
                  </div>
                  <div className="col-md-6 card">
                    <ul>
                      {selectedFilesLS.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger"
                            onClick={() => handleFileDeleteLS(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Miscellaneous Documents:
                    </label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeMD}
                    />
                  </div>
                  <div className="col-md-6 card">
                    <ul>
                      {selectedFilesMD.map((file) => (
                        <li key={file.name}>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger"
                            onClick={() => handleFileDeleteMD(file.name)}
                          >
                            Remove
                          </button>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="notes-details"
                role="tabpanel"
                aria-labelledby="notes-details-tab"
              >
                <div id="notesDetails " className="fw-bold text-center mt-4">
                  Notes
                </div>
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-12">
                    <textarea
                      type="text"
                      rows="7"
                      cols="40"
                      className="form-control"
                      {...register("dispatchNotes")}
                    />
                  </div>
                </div>
              </div>
            </div>
            {errors.label && (
              <div className="text-danger">{errors.label.message}</div>
            )}
            {CheckingLoadExists && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {LoadExists && CheckingLoadExists === false && (
              <div className="text-danger">
                {"Load Details -> Load# : Load is already added."}
              </div>
            )}
            {errors.shipperStartWaqt && (
              <div className="text-danger">
                {errors.shipperStartWaqt.message}
              </div>
            )}
            {errors.shipperEndWaqt && (
              <div className="text-danger">{errors.shipperEndWaqt.message}</div>
            )}
            {errors.shipperCheckInWaqt && (
              <div className="text-danger">
                {errors.shipperCheckInWaqt.message}
              </div>
            )}
            {errors.shipperCheckOutWaqt && (
              <div className="text-danger">
                {errors.shipperCheckOutWaqt.message}
              </div>
            )}
            {errors.shipper2StartWaqt && (
              <div className="text-danger">
                {errors.shipper2StartWaqt.message}
              </div>
            )}
            {errors.shipper2EndWaqt && (
              <div className="text-danger">
                {errors.shipper2EndWaqt.message}
              </div>
            )}
            {errors.shipper2CheckInWaqt && (
              <div className="text-danger">
                {errors.shipper2CheckInWaqt.message}
              </div>
            )}
            {errors.shipper2CheckOutWaqt && (
              <div className="text-danger">
                {errors.shipper2CheckOutWaqt.message}
              </div>
            )}
            {errors.receiverStartWaqt && (
              <div className="text-danger">
                {" "}
                {errors.receiverStartWaqt.message}
              </div>
            )}
            {errors.receiverEndWaqt && (
              <div className="text-danger">
                {errors.receiverEndWaqt.message}
              </div>
            )}
            {errors.receiverCheckInWaqt && (
              <div className="text-danger">
                {errors.receiverCheckInWaqt.message}
              </div>
            )}
            {errors.receiverCheckOutWaqt && (
              <div className="text-danger">
                {errors.receiverCheckOutWaqt.message}
              </div>
            )}
            {errors.receiver2StartWaqt && (
              <div className="text-danger">
                {errors.receiver2StartWaqt.message}
              </div>
            )}
            {errors.receiver2EndWaqt && (
              <div className="text-danger">
                {errors.receiver2EndWaqt.message}
              </div>
            )}
            {errors.receiver2CheckInWaqt && (
              <div className="text-danger">
                {errors.receiver2CheckInWaqt.message}
              </div>
            )}
            {errors.receiver2CheckOutWaqt && (
              <div className="text-danger">
                {errors.receiver2CheckOutWaqt.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateLoad;
