import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvoiceLoad = ({
  onSaveSuccess,
  loadId,
  loadNumber,
  setRefreshInvoiceModal,
  refreshInvoiceModal,
}) => {
  const { register, setValue, handleSubmit, watch, reset } = useForm();
  const [loadData, setLoadData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [loadfiles, setLoadFiles] = useState({
    rateCont: null,
    billOfLanding: null,
    scaleTicket: null,
    lumperReceipt: null,
    miscellaneousDocuments: null,
    locationScreenshots: null,
  });
  const [selectedFilesRC, setSelectedFilesRC] = useState([]);
  const [selectedFilesBOL, setSelectedFilesBOL] = useState([]);
  const [selectedFilesST, setSelectedFilesST] = useState([]);
  const [selectedFilesLR, setSelectedFilesLR] = useState([]);
  const [selectedFilesMD, setSelectedFilesMD] = useState([]);
  const [selectedFilesLS, setSelectedFilesLS] = useState([]);
  const [invoiceRate, setInvoiceRate] = useState(0);
  const [invoiceDetention, setInvoiceDetention] = useState(0);
  const [invoiceLumper, setInvoiceLumper] = useState(0);
  const [invoiceMiscAmount, setInvoiceMiscAmount] = useState(0);
  const [invoiceTotalDue, setInvoiceTotalDue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [brokers, setBrokers] = useState([]);
  const [selectedDocumentKeys, setSelectedDocumentKeys] = useState([]);

  const fetchLoadData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.INVOICES, {
        params: { loadId },
      });
      const tempData = response.data.data["load-data"];
      setInvoiceRate(tempData.invoiceRate);
      setLoadData(tempData);
      let tempInvNum = response.data.data["max_invoice_number"];
      if (tempInvNum === null) {
        window.alert(
          "System could not get Invoice Number automatically. Please accept to proceed and make sure to type Invoice Number manually and it must increment in your previous invoice number."
        );
      } else {
        tempInvNum = parseInt(tempInvNum, 10);
        setValue("invoiceNumber", tempInvNum);
      }

      if (typeof tempData === "object" && tempData !== null) {
        Object.keys(tempData).forEach((key) => {
          if (
            ["invoiceLabel", "invoiceLoadNo", "invoiceBroker"].includes(key)
          ) {
            setValue(key, tempData[key]);
          } else if (
            [
              "rateCont",
              "billOfLanding",
              "scaleTicket",
              "lumperReceipt",
              "miscellaneousDocuments",
              "locationScreenshots",
            ].includes(key)
          ) {
            setLoadFiles((prevLoadFiles) => ({
              ...prevLoadFiles,
              [key]: tempData[key],
            }));
          }
        });
      } else {
        console.log("Invalid Data Structure");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching load to invoice:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (loadId && refreshInvoiceModal) {
      reset();
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
      setValue(`invoiceDate`, formattedDate);
      fetchLoadData();
      fetchBrokers();
    }
  }, [loadId, refreshInvoiceModal]);

  useEffect(() => {
    setSelectedFilesRC(loadfiles.rateCont);
    setSelectedFilesBOL(loadfiles.billOfLanding);
    setSelectedFilesST(loadfiles.scaleTicket);
    setSelectedFilesLR(loadfiles.lumperReceipt);
    setSelectedFilesMD(loadfiles.miscellaneousDocuments);
    setSelectedFilesLS(loadfiles.locationScreenshots);
  }, [loadfiles]);

  useEffect(() => {
    const rate = parseFloat(invoiceRate) || 0;
    const detention = parseFloat(invoiceDetention) || 0;
    const lumper = parseFloat(invoiceLumper) || 0;
    const otherCharges = parseFloat(invoiceMiscAmount) || 0;
    const total = rate + detention + lumper + otherCharges;
    setInvoiceTotalDue(total);
  }, [invoiceRate, invoiceDetention, invoiceLumper, invoiceMiscAmount]);

  const handleInputChange = (e, setterFunc) => {
    const inputValue = e.target.value;
    // const isValid = /^\d+$/.test(inputValue); // Modified regex to disallow dot
    const isValid = /^\d*\.?\d*$/.test(inputValue); // Modified regex to allow dots (decimal points)
    if (isValid || inputValue === "") {
      setterFunc(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage("Please input only digits");
    }
  };

  const handleViewClick = async (contentName) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWFILE}/${contentName}?loadId=${loadId}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  const handleCheckboxChange = (fileKey) => {
    if (!fileKey.toLowerCase().endsWith(".pdf")) {
      toast.warning(
        "Please select PDF files only or convert to PDF before invoicing."
      );
      return;
    }

    setSelectedDocumentKeys((prevKeys) => {
      if (prevKeys.includes(fileKey)) {
        return prevKeys.filter((key) => key !== fileKey);
      } else {
        return [...prevKeys, fileKey];
      }
    });
  };

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Generating Invoice. Please wait... ");
    try {
      const formData = new FormData();
      if (invoiceMiscAmount && invoiceMiscAmount !== "0") {
        if (!data["invoiceMiscLabel"] || !data["invoiceMiscLabel"].trim()) {
          toast.update(id, {
            render: "Misc Label is Required for the Misc Amount.",
            type: "error",
            isLoading: false,
            autoClose: 1500,
          });
          setIsRequestInProgress(true);
          return;
        }
      } else {
        setValue("invoiceMiscLabel", null);
      }

      formData.append("invoiceRate", invoiceRate);
      formData.append("invoiceLumper", invoiceLumper);
      formData.append("invoiceDetention", invoiceDetention);
      formData.append("invoiceMiscAmount", invoiceMiscAmount);
      formData.append("invoiceTotalDue", invoiceTotalDue);

      Object.keys(data).forEach((val) => {
        val !== "assignedBrokerBillingEmail" && formData.append(val, data[val]);
      });
      selectedDocumentKeys.forEach((fileKey) =>
        formData.append("selectedAttachments", fileKey)
      );
      if (!loadData.invoiceNumber) {
        const response = await axiosInstance.post(
          API_ENDPOINTS.INVOICES,
          formData,
          {
            params: { loadId: loadId },
          }
        );
        toast.update(id, {
          render: "Invoice generated. Will be downloaded automatically",
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });
        const fileUrl = response.data.message;
        window.open(fileUrl, "_blank");
      } else {
        await axiosInstance.patch(API_ENDPOINTS.INVOICES, formData, {
          params: { loadId: loadId },
        });
        toast.update(id, {
          render: "Successful, Load was saved.",
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });
      }
      onSaveSuccess();
      document.getElementById("closeButtonInvoiceLoad").click();
    } catch (error) {
      console.error("Error:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  const fetchBrokers = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.BROKERNAMESFORBILLING
      );
      setBrokers(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  const handleBrokerChange = (e) => {
    const selectedBrokerId = e.target.value;
    const selectedBroker1 = brokers.find(
      (broker) => String(broker[0]) === selectedBrokerId
    );

    if (selectedBroker1) {
      setValue("invoiceRecipentEmail", selectedBroker1[2]);
      setValue("assignedBrokerBillingEmail", "Choose...");
    }
  };

  const invoiceNumber = watch("invoiceNumber");
  const invoiceBroker = watch("invoiceBroker");
  const invoiceRecipentEmail = watch("invoiceRecipentEmail");
  const invoiceDate = watch("invoiceDate");
  const invoiceLoadNo = watch("invoiceLoadNo");
  const invoiceLabel = watch("invoiceLabel");

  const isFormValid =
    (invoiceNumber || loadData.invoiceNumber) &&
    invoiceDate &&
    (invoiceBroker || loadData.invoiceBroker) &&
    invoiceRecipentEmail &&
    (invoiceLoadNo || loadData.invoiceLoadNo) &&
    (invoiceLabel || loadData.invoiceLabel);

  return (
    <div
      className="modal fade"
      id="modal4"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">
                Invoicing Load# {loadNumber}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="submit"
                className="btn reg-user-submit"
                onClick={handleSubmit(onSubmit)}
                disabled={!isFormValid}
              >
                Save Invoice
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButtonInvoiceLoad"
                onClick={(e) => {
                  reset();
                  setRefreshInvoiceModal(false);
                  setInvoiceDetention(0);
                  setInvoiceLumper(0);
                  setInvoiceMiscAmount(0);
                  setInvoiceRate(0);
                  setInvoiceTotalDue(0);
                  setSelectedDocumentKeys([]);
                }}
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={(e) => e.preventDefault()} className="modal-body">
              <ul className="nav nav-tabs" id="myTab1" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="load-invoice-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#load-invoice-details"
                    type="button"
                    role="tab"
                    aria-controls="load-invoice-details"
                    aria-selected="true"
                  >
                    Invoice Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="load-details-invoice-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#load-details-invoice"
                    type="button"
                    role="tab"
                    aria-controls="load-details-invoice"
                    aria-selected="true"
                  >
                    Load Details
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent1">
                <div
                  className="tab-pane fade show active"
                  id="load-invoice-details"
                  role="tabpanel"
                  aria-labelledby="load-invoice-details-tab"
                >
                  <div id="loadInvoice" className="fw-bold text-center">
                    Invoice Details
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Invoice#{" "}
                      </label>
                      <input
                        type="number"
                        className="form-control input-field"
                        {...register("invoiceNumber", {
                          required: true,
                          maxLength: 50,
                        })}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Date:
                      </label>
                      <input
                        type="date"
                        className="form-control input-field"
                        {...register("invoiceDate", {})}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Label:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("invoiceLabel", {
                          required: true,
                          maxLength: 100,
                        })}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Load#
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("invoiceLoadNo", {
                          required: true,
                          maxLength: 50,
                        })}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Broker:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("invoiceBroker", {
                          required: true,
                          maxLength: 100,
                        })}
                      />
                    </div>

                    <div className="col-md-1 d-flex align-items-end">
                      <select
                        className="form-control"
                        {...register("assignedBrokerBillingEmail")}
                        defaultValue={"Choose..."}
                        onChange={(e) => handleBrokerChange(e)}
                      >
                        <option value="Choose...">Choose</option>
                        {brokers.map((broker) => (
                          <option key={broker[0]} value={broker[0]}>
                            {broker[1] + (broker[2] ? ` (${broker[2]})` : "")}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-5 d-flex align-items-end">
                      <label className="col-form-label pb-0 single-line">
                        Billing Email:
                      </label>
                      <input
                        type="email"
                        className="form-control input-field"
                        {...register("invoiceRecipentEmail", {
                          required: true,
                          maxLength: 100,
                        })}
                      />
                    </div>
                  </div>
                  <div className="pt-3 row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Rate($):
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        value={invoiceRate === 0 ? "" : invoiceRate}
                        onChange={(e) => handleInputChange(e, setInvoiceRate)}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Detention($):
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        value={invoiceDetention === 0 ? "" : invoiceDetention}
                        onChange={(e) =>
                          handleInputChange(e, setInvoiceDetention)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Lumper($):
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        value={invoiceLumper === 0 ? "" : invoiceLumper}
                        onChange={(e) => handleInputChange(e, setInvoiceLumper)}
                      />
                    </div>
                    <div className="col-md-3 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Misc Label:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        {...register("invoiceMiscLabel", {
                          maxLength: 50,
                        })}
                      />
                    </div>
                    <div className="col-md-3 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Misc Amount($):
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        value={invoiceMiscAmount === 0 ? "" : invoiceMiscAmount}
                        onChange={(e) =>
                          handleInputChange(e, setInvoiceMiscAmount)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-end">
                    <div className="d-flex justify-content-end align-items-end">
                      <div className="d-flex align-items-center">
                        {errorMessage && (
                          <div className="text-danger mr-3">{errorMessage}</div>
                        )}
                        <p className="p-2 text-warning bg-dark mb-0">
                          Total Amount: ${invoiceTotalDue}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="documentsDetails" className="fw-bold text-center">
                    Documents
                  </div>
                  <hr className="rounded"></hr>
                  {blobUrl && (
                    <>
                      <div className="d-flex flex-row-reverse">
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => setBlobUrl(null)}
                        >
                          Close
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {fileName.endsWith(".pdf") ? (
                            <embed
                              className="iframedriver"
                              src={blobUrl}
                            ></embed>
                          ) : (
                            <img
                              width={"100%"}
                              height="auto"
                              src={blobUrl}
                              alt="Image"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Rate Confirmation:
                      </label>
                      {selectedFilesRC !== null && (
                        <ul>
                          {selectedFilesRC.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">Bill of Lading:</label>
                      {selectedFilesBOL !== null && (
                        <ul>
                          {selectedFilesBOL.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">Scale Ticket:</label>
                      {selectedFilesST !== null && (
                        <ul>
                          {selectedFilesST.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">Lumper Receipt:</label>
                      {selectedFilesLR !== null && (
                        <ul>
                          {selectedFilesLR.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Location Screenshots:
                      </label>
                      {selectedFilesLS !== null && (
                        <ul>
                          {selectedFilesLS.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">
                        Miscellaneous Documents:
                      </label>
                      {selectedFilesMD !== null && (
                        <ul>
                          {selectedFilesMD.map((file) => (
                            <li key={file}>
                              <input
                                type="checkbox"
                                className="custom-checkbox me-2 align-middle"
                                onChange={() => handleCheckboxChange(file)}
                                checked={selectedDocumentKeys.includes(file)}
                              />
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              {file}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div
                    id="selectedDocumentsDetails"
                    className="mt-3 fw-bold text-center"
                  >
                    Selected Documents
                  </div>

                  <div className="card p-2">
                    <div className="selected-documents">
                      {selectedDocumentKeys.map((key, index) => (
                        <div key={index} className="selected-document">
                          <div className="file-icon">
                            <i className="fas fa-file-alt"></i>
                          </div>
                          <div className="file-caption">{key}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show"
                  id="load-details-invoice"
                  role="tabpanel"
                  aria-labelledby="load-details-invoice-tab"
                >
                  <div id="loadDetails" className="fw-bold text-center">
                    Load Details
                  </div>
                  <hr className="rounded"></hr>
                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Label:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.invoiceLabel}
                        disabled
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Load#</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.invoiceLoadNo}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Rate:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.invoiceRate}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Escalation:
                      </label>
                      <textarea
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.escalation}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">
                        Broker:
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.invoiceBroker}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">MC#:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.brokerMc}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1">Agent:</label>
                      <input
                        type="text"
                        className="form-control input-field"
                        defaultValue={loadData.brokerAgent}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      <label className="col-form-label pb-0 mx-1 single-line">
                        Agent Email:
                      </label>
                      <input
                        type="email"
                        className="form-control input-field"
                        defaultValue={loadData.brokerEmail}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceLoad;
