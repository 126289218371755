import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const AddDocuments = ({
  loadId,
  loadNumber,
  setRefreshAddDocumentsModal,
  refreshAddDocumentsModal,
  role,
  loadStatusss,
}) => {
  const { handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [loadfiles, setLoadFiles] = useState({
    rateCont: null,
    rateContDriver: null,
    billOfLanding: null,
    scaleTicket: null,
    lumperReceipt: null,
    miscellaneousDocuments: null,
    locationScreenshots: null,
  });
  const [selectedFilesRC, setSelectedFilesRC] = useState([]);
  const [selectedFilesRCD, setSelectedFilesRCD] = useState([]);
  const [selectedFilesBOL, setSelectedFilesBOL] = useState([]);
  const [selectedFilesST, setSelectedFilesST] = useState([]);
  const [selectedFilesLR, setSelectedFilesLR] = useState([]);
  const [selectedFilesMD, setSelectedFilesMD] = useState([]);
  const [selectedFilesLS, setSelectedFilesLS] = useState([]);

  const [selectedFilesRC2, setSelectedFilesRC2] = useState([]);
  const [selectedFilesRCD2, setSelectedFilesRCD2] = useState([]);
  const [selectedFilesBOL2, setSelectedFilesBOL2] = useState([]);
  const [selectedFilesST2, setSelectedFilesST2] = useState([]);
  const [selectedFilesLR2, setSelectedFilesLR2] = useState([]);
  const [selectedFilesMD2, setSelectedFilesMD2] = useState([]);
  const [selectedFilesLS2, setSelectedFilesLS2] = useState([]);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const fetchLoad = async () => {
    setIsLoading(true);
    reset();
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GETLOADDOCS, {
        params: { loadId },
      });
      const tempData = response.data.data["load-docs"];
      if (typeof tempData === "object" && tempData !== null) {
        Object.keys(tempData).forEach((key) => {
          if (
            [
              "rateCont",
              "rateContDriver",
              "billOfLanding",
              "scaleTicket",
              "lumperReceipt",
              "miscellaneousDocuments",
              "locationScreenshots",
            ].includes(key)
          ) {
            setLoadFiles((prevLoadFiles) => ({
              ...prevLoadFiles,
              [key]: tempData[key],
            }));
          }
        });
      } else {
        console.log("Invalid Data Structure");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching load to edit:", error);
      setIsLoading(false);
    }
  };

  const refreshstates = () => {
    reset();
    setSelectedFilesRC([]);
    setSelectedFilesRC2([]);
    setSelectedFilesRCD([]);
    setSelectedFilesRCD2([]);
    setSelectedFilesBOL([]);
    setSelectedFilesBOL2([]);
    setSelectedFilesST([]);
    setSelectedFilesST2([]);
    setSelectedFilesLR([]);
    setSelectedFilesLR2([]);
    setSelectedFilesMD([]);
    setSelectedFilesMD2([]);
    setSelectedFilesLS([]);
    setSelectedFilesLS2([]);
    setBlobUrl(null);
  };

  useEffect(() => {
    if (loadId && refreshAddDocumentsModal) {
      refreshstates();
      fetchLoad();
    } else {
      document.getElementById("closeButtonAddDocumentsLoad").click();
    }
  }, [loadId, refreshAddDocumentsModal]);

  useEffect(() => {
    setSelectedFilesRC(loadfiles.rateCont);
    setSelectedFilesRCD(loadfiles.rateContDriver);
    setSelectedFilesBOL(loadfiles.billOfLanding);
    setSelectedFilesST(loadfiles.scaleTicket);
    setSelectedFilesLR(loadfiles.lumperReceipt);
    setSelectedFilesMD(loadfiles.miscellaneousDocuments);
    setSelectedFilesLS(loadfiles.locationScreenshots);
  }, [loadfiles]);

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    if (
      role !== "admin" &&
      (loadStatusss === "Completed" || loadStatusss === "TONU")
    ) {
      const confirmSave = window.confirm(
        `Are you sure you want to edit this completed load.`
      );
      if (!confirmSave) {
        setIsRequestInProgress(true);
        return;
      }
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating load details.Please wait...");
    try {
      const formData = new FormData();

      selectedFilesRC2.forEach((file) => {
        formData.append("rateCont", file);
      });

      selectedFilesRCD2.forEach((file) => {
        formData.append("rateContDriver", file);
      });

      selectedFilesBOL2.forEach((file) => {
        formData.append("billOfLanding", file);
      });
      selectedFilesST2.forEach((file) => {
        formData.append("scaleTicket", file);
      });
      selectedFilesLR2.forEach((file) => {
        formData.append("lumperReceipt", file);
      });
      selectedFilesMD2.forEach((file) => {
        formData.append("miscellaneousDocuments", file);
      });
      selectedFilesLS2.forEach((file) => {
        formData.append("locationScreenshots", file);
      });

      await axiosInstance.patch(API_ENDPOINTS.GETLOAD, formData, {
        params: { loadId: loadId },
      });
      toast.update(id, {
        render: "Load details updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      document.getElementById("closeButtonAddDocumentsLoad").click();
    } catch (error) {
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  const handleFileChangeRC2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesRC2([...selectedFilesRC2, ...filteredFiles]);
  };

  const handleFileChangeRCD2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesRCD2([...selectedFilesRCD2, ...filteredFiles]);
  };

  const handleFileChangeBOL2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesBOL2([...selectedFilesBOL2, ...filteredFiles]);
  };

  const handleFileChangeST2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesST2([...selectedFilesST2, ...filteredFiles]);
  };

  const handleFileChangeLR2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesLR2([...selectedFilesLR2, ...filteredFiles]);
  };

  const handleFileChangeMD2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesMD2([...selectedFilesMD2, ...filteredFiles]);
  };

  const handleFileChangeLS2 = (event) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        extension === "pdf" || ["jpg", "jpeg", "png", "gif"].includes(extension)
      );
    });
    setSelectedFilesLS2([...selectedFilesLS2, ...filteredFiles]);
  };

  const handleFileDeleteRC2 = (fileName) => {
    const updatedFiles = selectedFilesRC2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesRC2(updatedFiles);
  };

  const handleFileDeleteRCD2 = (fileName) => {
    const updatedFiles = selectedFilesRCD2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesRCD2(updatedFiles);
  };

  const handleFileDeleteBOL2 = (fileName) => {
    const updatedFiles = selectedFilesBOL2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesBOL2(updatedFiles);
  };

  const handleFileDeleteST2 = (fileName) => {
    const updatedFiles = selectedFilesST2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesST2(updatedFiles);
  };

  const handleFileDeleteLR2 = (fileName) => {
    const updatedFiles = selectedFilesLR2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLR2(updatedFiles);
  };

  const handleFileDeleteMD2 = (fileName) => {
    const updatedFiles = selectedFilesMD2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesMD2(updatedFiles);
  };

  const handleFileDeleteLS2 = (fileName) => {
    const updatedFiles = selectedFilesMD2.filter(
      (file) => file.name !== fileName
    );
    setSelectedFilesLS2(updatedFiles);
  };

  const handleViewClick = async (contentName) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWFILE}/${contentName}?loadId=${loadId}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  const handleDeleteClick = async (contentName, filekey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this document?`
    );
    if (!confirmDelete) {
      return;
    }
    let id = toast.loading("Deleting Load attachement.Please wait...");

    try {
      await axiosInstance.delete(
        `${API_ENDPOINTS.FILE}/${contentName}?loadId=${loadId}&fileKey=${filekey}`
      );
      toast.update(id, {
        render: "Load file deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      if (filekey === "rateCont") {
        const index = selectedFilesRC.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesRC((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "rateContDriver") {
        const index = selectedFilesRCD.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesRCD((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "billOfLanding") {
        const index = selectedFilesBOL.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesBOL((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "scaleTicket") {
        const index = selectedFilesST.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesST((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "lumperReceipt") {
        const index = selectedFilesLR.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesLR((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "miscellaneousDocuments") {
        const index = selectedFilesMD.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesMD((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else if (filekey === "locationScreenshots") {
        const index = selectedFilesLS.indexOf(contentName);
        if (index !== -1) {
          setSelectedFilesLS((prevFiles) =>
            prevFiles.filter((file) => file !== contentName)
          );
        }
      } else {
        console.log("Sorry, No Content Found for this key.");
      }
    } catch (error) {
      console.log("Error Deleting file:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="modal5"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">
                Editing Load# {loadNumber}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="submit"
                className="btn reg-user-submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setRefreshAddDocumentsModal(false);
                  setBlobUrl(null);
                }}
                id="closeButtonAddDocumentsLoad"
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
              <div className="tab-content" id="myTabContent">
                <div id="documents" className="fw-bold text-center mt-4">
                  Documents
                </div>{" "}
                <hr className="rounded"></hr>
                {blobUrl && (
                  <>
                    <div className="d-flex flex-row-reverse">
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => setBlobUrl(null)}
                      >
                        Close
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {fileName.endsWith(".pdf") ? (
                          <embed className="iframedriver" src={blobUrl}></embed>
                        ) : (
                          <img
                            width={"100%"}
                            height="auto"
                            src={blobUrl}
                            alt="Image"
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Rate Confirmation:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeRC2}
                    />
                  </div>
                  <div className="col-md-6 card">
                    {selectedFilesRC !== null && (
                      <ul>
                        {selectedFilesRC.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() =>
                                handleDeleteClick(file, "rateCont")
                              }
                            >
                              Delete
                            </button>
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}
                    {selectedFilesRC2 !== null && (
                      <ul>
                        {selectedFilesRC2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteRC2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Driver's Rate Confirmation:
                    </label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeRCD2}
                    />
                  </div>
                  <div className="col-md-6 card">
                    {/* {selectedFilesRCD !== null &&
                          <ul>
                            {selectedFilesRCD.map((file) => (
                              <li key={file}>
                                <button type="button" className="me-2 mt-1 btn btn-outline-danger btn-sm" onClick={() => handleViewClick(file)}>
                                  View
                                </button>
                                <button type="button" className="me-2 mt-1 btn btn-outline-danger btn-sm" onClick={() => handleDeleteClick(file, 'rateContDriver')}>
                                  Delete
                                </button>
                                {file}
                              </li>
                            ))}
                          </ul>
                        } */}
                    {selectedFilesRCD !== null && (
                      <ul>
                        {selectedFilesRCD.map((file) => {
                          // const isImage = /\.(jpg|jpeg|png)$/i.test(file);

                          return (
                            <li key={file}>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() => handleViewClick(file)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                onClick={() =>
                                  handleDeleteClick(file, "rateContDriver")
                                }
                              >
                                Delete
                              </button>
                              {/* {isImage && (
                                    <button
                                      type="button"
                                      className="me-2 mt-1 btn btn-outline-danger btn-sm"
                                      onClick={() => handleConvertToPdfClick(file)}
                                    >
                                      Convert to PDF
                                    </button>
                                  )} */}
                              {file}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {selectedFilesRCD2 !== null && (
                      <ul>
                        {selectedFilesRCD2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteRCD2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Bill of Lading:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeBOL2}
                    />
                  </div>
                  <div className="col-md-6 card">
                    {selectedFilesBOL !== null && (
                      <ul>
                        {selectedFilesBOL.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() =>
                                handleDeleteClick(file, "billOfLanding")
                              }
                            >
                              Delete
                            </button>
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}

                    {selectedFilesBOL2 !== null && (
                      <ul>
                        {selectedFilesBOL2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteBOL2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Scale Ticket:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeST2}
                    />
                  </div>
                  <div className="col-md-6 card">
                    {selectedFilesST !== null && (
                      <ul>
                        {selectedFilesST.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() =>
                                handleDeleteClick(file, "scaleTicket")
                              }
                            >
                              Delete
                            </button>
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}

                    {selectedFilesST2 !== null && (
                      <ul>
                        {selectedFilesST2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteST2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">Lumper Reciept:</label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeLR2}
                    />
                  </div>
                  <div className="col-md-6 card">
                    {selectedFilesLR !== null && (
                      <ul>
                        {selectedFilesLR.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() =>
                                handleDeleteClick(file, "lumperReceipt")
                              }
                            >
                              Delete
                            </button>
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}

                    {selectedFilesLR2 !== null && (
                      <ul>
                        {selectedFilesLR2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteLR2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Location Screenshots:
                    </label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeLS2}
                    />
                  </div>
                  <div className="col-md-6 card">
                    {selectedFilesLS !== null && (
                      <ul>
                        {selectedFilesLS.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() =>
                                handleDeleteClick(file, "locationScreenshots")
                              }
                            >
                              Delete
                            </button>
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}

                    {selectedFilesLS2 !== null && (
                      <ul>
                        {selectedFilesLS2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteLS2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Miscellaneous Documents:
                    </label>
                    <input
                      type="file"
                      className="form-control input-field"
                      onChange={handleFileChangeMD2}
                    />
                  </div>
                  <div className="col-md-6 card">
                    {selectedFilesMD !== null && (
                      <ul>
                        {selectedFilesMD.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleViewClick(file)}
                            >
                              View
                            </button>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() =>
                                handleDeleteClick(
                                  file,
                                  "miscellaneousDocuments"
                                )
                              }
                            >
                              Delete
                            </button>
                            {file}
                          </li>
                        ))}
                      </ul>
                    )}

                    {selectedFilesMD2 !== null && (
                      <ul>
                        {selectedFilesMD2.map((file) => (
                          <li key={file.name}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-danger btn-sm"
                              onClick={() => handleFileDeleteMD2(file.name)}
                            >
                              Remove
                            </button>
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddDocuments;
