import axios from "axios";

// const FRONT_HIT_URL = "http://localhost:3000";
// const API_BASE_URL = "http://localhost:5000";
const FRONT_HIT_URL = "https://www.portal.allamlogistics.com/login";
const API_BASE_URL =
  "https://irl9pzceb6.execute-api.us-east-1.amazonaws.com/production";
// const FRONT_HIT_URL = "https://dev.dd3tflc8ni39z.amplifyapp.com";
// const API_BASE_URL =
//   "https://vmh6xkc1di.execute-api.us-east-1.amazonaws.com/dev";

export const API_ENDPOINTS = {
  LOADS: `${API_BASE_URL}/loads`,
  GETLOAD: `${API_BASE_URL}/loads-details`,
  GETLOADDOCS: `${API_BASE_URL}/loads-details-documents`,

  GETDRIVERLOAD: `${API_BASE_URL}/loads-details-driver`,
  USERS: `${API_BASE_URL}/users`,
  USERFINANCES: `${API_BASE_URL}/user-finances`,
  DRIVERWEEKLYREPORTS: `${API_BASE_URL}/driver_weekly_report`,
  BROKERS: `${API_BASE_URL}/brokers`,
  BROKERSAGENT: `${API_BASE_URL}/brokersagent`,
  LOGIN: `${API_BASE_URL}/login`,
  ME: `${API_BASE_URL}/me`,
  SELF: `${API_BASE_URL}/self`,
  USERNAMES: `${API_BASE_URL}/agents-usernames`,
  BROKERNAMES: `${API_BASE_URL}/brokers-usernames`,
  BROKERNAMESFORBILLING: `${API_BASE_URL}/brokers-billingemail`,
  AGENTNAMES: `${API_BASE_URL}/brokersagents-usernames`,
  FILE: `${API_BASE_URL}/download_loadfiles`,
  VIEWFILE: `${API_BASE_URL}/view_loadfiles`,
  INVOICE: `${API_BASE_URL}/download_invoicefile`,
  VIEWINVOICE: `${API_BASE_URL}/view_invoicefile`,
  COMPLETEDLOADS: `${API_BASE_URL}/completed_loads`,
  DRIVERSLOADCOUNT: `${API_BASE_URL}/drivers_load_count`,
  NOTIFICATIONS: `${API_BASE_URL}/notifications`,
  BROKERFILESACTION: `${API_BASE_URL}/download_brokerfiles`,
  GETBROKERFILES: `${API_BASE_URL}/broker_files`,
  DRIVERCHECKACTIONS: `${API_BASE_URL}/driver_check_time`,
  CHECKLOADNO: `${API_BASE_URL}/check_loadno`,
  INVOICES: `${API_BASE_URL}/invoices`,
  INVOICESLIST: `${API_BASE_URL}/invoices-list`,
  INVOICESDETAILS: `${API_BASE_URL}/invoices-details`,
  INVOICESDETAILSATTACH: `${API_BASE_URL}/invoices-details_attachments`,
  GETLOADSTATUSCOUNT: `${API_BASE_URL}/get_load_status_count`,
  GETLOADSRATES: `${API_BASE_URL}/get_loads_rates`,
};

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem("authkeytemp");

    if (access_token) {
      if (config.url !== "/login") {
        config.headers["Authorization"] = "Bearer " + access_token;
      } else {
        localStorage.removeItem("authkeytemp");
        window.location.href = FRONT_HIT_URL;
      }
    }

    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log();
    if (
      error.response.status === 401 &&
      error.response.data.msg === "Token has expired"
    ) {
      localStorage.removeItem("authkeytemp");
      // Redirect to /login
      window.location.href = FRONT_HIT_URL;
    }
    return Promise.reject(error);
  }
);
