import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { differenceInCalendarDays } from "date-fns";

const EditInvoice = ({
  onSaveSuccess,
  selectedInvoiceData,
  setRefreshEditModal,
  refreshEditModal,
}) => {
  const { register, setValue, handleSubmit, watch, reset } = useForm();
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [brokers, setBrokers] = useState([]);
  const [invoiceRate, setInvoiceRate] = useState(0);
  const [invoiceDetention, setInvoiceDetention] = useState(0);
  const [invoiceLumper, setInvoiceLumper] = useState(0);
  const [invoiceMiscAmount, setInvoiceMiscAmount] = useState(0);
  const [invoiceTotalDue, setInvoiceTotalDue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const initializeValues = () => {
    setValue("invoiceLoadNo", selectedInvoiceData[1]);
    setValue("invoiceLabel", selectedInvoiceData[2]);
    setValue("invoiceNumber", selectedInvoiceData[3]);
    setValue("invoiceBroker", selectedInvoiceData[4]);
    setValue("invoicePaidStatus", selectedInvoiceData[5]);
    setValue("loadShipperPickupDate", formatDateTime(selectedInvoiceData[6]));
    setValue("invoiceDate", formatDateTime(selectedInvoiceData[7]));
    setInvoiceTotalDue(selectedInvoiceData[8]);
    setValue("invoiceAmountReceived", selectedInvoiceData[9]);
    setInvoiceRate(selectedInvoiceData[10]);
    setInvoiceDetention(selectedInvoiceData[11]);
    setInvoiceLumper(selectedInvoiceData[12]);
    setInvoiceMiscAmount(selectedInvoiceData[13]);
    setValue("invoiceMiscLabel", selectedInvoiceData[14]);
  };

  const fetchLoadData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.INVOICESDETAILS, {
        params: { invoiceId: selectedInvoiceData[0] },
      });
      const tempData = response.data.data["invoice-data"];
      setInvoiceData(tempData);
      initializeValues();
      if (typeof tempData === "object" && tempData !== null) {
        Object.keys(tempData).forEach((key) => {
          if (["invoicePaidDate", "invoiceDepositDate"].includes(key)) {
            if (tempData[key] !== null) {
              setValue(key, formatDateTime2(tempData[key]));
            } else {
              setValue(key, null);
            }
          } else if (
            ["invoiceSubmittedThru", "invoiceMethodPaid"].includes(key)
          ) {
            if (tempData[key] === null) {
              setValue(key, "Choose...");
            } else {
              setValue(key, tempData[key]);
            }
          } else if (
            ["invoiceFileNameS3", "invoiceFileNameS3Merged"].includes(key)
          ) {
            //pass
          } else {
            setValue(key, tempData[key]);
          }
        });
      } else {
        console.log("Invalid Data Structure");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching load to Edit:", error);
      setIsLoading(false);
    }
  };

  const refreshStates = () => {
    reset();
    setInvoiceData({});
    setBlobUrl(null);
    setFileName(null);
    setBrokers([]);
  };

  useEffect(() => {
    if (
      selectedInvoiceData &&
      refreshEditModal &&
      selectedInvoiceData[0] !== null
    ) {
      refreshStates();
      fetchLoadData();
      fetchBrokers();
    }
  }, [selectedInvoiceData, refreshEditModal]);

  useEffect(() => {
    const rate = parseFloat(invoiceRate) || 0;
    const detention = parseFloat(invoiceDetention) || 0;
    const lumper = parseFloat(invoiceLumper) || 0;
    const otherCharges = parseFloat(invoiceMiscAmount) || 0;
    const total = rate + detention + lumper + otherCharges;
    setInvoiceTotalDue(total);
  }, [invoiceRate, invoiceDetention, invoiceLumper, invoiceMiscAmount]);

  const calculateDaysDifferenceFromToday = (startDate) => {
    const start = new Date(startDate);
    const today = new Date();
    return differenceInCalendarDays(today, start);
  };

  const handleInputChange = (e, setterFunc) => {
    const inputValue = e.target.value;
    const isValid = /^\d*\.?\d*$/.test(inputValue); // Modified regex to allow dots (decimal points)
    // const isValid = /^\d+$/.test(inputValue); // Modified regex to disallow dot
    if (isValid || inputValue === "") {
      setterFunc(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage("Please input only digits");
    }
  };

  function formatDateTime2(dateStr) {
    let parts = dateStr.split("/");
    let month = parts[0];
    let day = parts[1];
    let year = parts[2];
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function formatDateTime(dateStr) {
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    let parts = dateStr.split(" ");
    let day = parts[1];
    let month = months[parts[2]];
    let year = parts[3];
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const fetchBrokers = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.BROKERNAMESFORBILLING
      );
      setBrokers(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  const handleBrokerChange = (e) => {
    const selectedBrokerId = e.target.value;
    const selectedBroker1 = brokers.find(
      (broker) => String(broker[0]) === selectedBrokerId
    );

    if (selectedBroker1) {
      setValue("invoiceRecipentEmail", selectedBroker1[2]);
      setValue("assignedBrokerBillingEmail", "Choose...");
    }
  };

  const handleViewClick = async (contentName, Type) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWINVOICE}/${contentName}?Type=${Type}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching invoice:", error);
    }
  };

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    let id = toast.loading("Updating load details.Please wait...");
    try {
      if (invoiceMiscAmount && invoiceMiscAmount !== "0") {
        if (!data["invoiceMiscLabel"] || !data["invoiceMiscLabel"].trim()) {
          toast.update(id, {
            render: "Misc Label is Required for the Misc Amount.",
            type: "error",
            isLoading: false,
            autoClose: 1500,
          });
          setIsRequestInProgress(true);
          return;
        }
      } else {
        setValue("invoiceMiscLabel", null);
      }
      const formData = new FormData();
      formData.append("invoiceRate", invoiceRate);
      formData.append("invoiceLumper", invoiceLumper);
      formData.append("invoiceDetention", invoiceDetention);
      formData.append("invoiceMiscAmount", invoiceMiscAmount);
      formData.append("invoiceTotalDue", invoiceTotalDue);
      if (data["invoiceDate"] === null) {
        const today = new Date();
        const formattedDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
        setValue("invoiceDate", formattedDate);
      }
      Object.keys(data).forEach((val) => {
        val !== "assignedBrokerBillingEmail" && formData.append(val, data[val]);
      });

      await axiosInstance.patch(API_ENDPOINTS.INVOICESDETAILS, formData, {
        params: { invoiceId: selectedInvoiceData[0] },
      });
      toast.update(id, {
        render: "Invoice details updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      onSaveSuccess();
      document.getElementById("closeButtonEditInvoice").click();
    } catch (error) {
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  const handleDeleteClick = async (contentName, Type) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this document?`
    );
    if (!confirmDelete) {
      return;
    }
    let id = toast.loading("Deleting Load attachement.Please wait...");

    try {
      await axiosInstance.delete(
        `${API_ENDPOINTS.VIEWINVOICE}/${contentName}?invoiceId=${selectedInvoiceData[0]}&Type=${Type}`
      );
      if (Type === "Merged") {
        setInvoiceData((prevData) => ({
          ...prevData,
          invoiceFileNameS3Merged: null,
        }));
      } else if (Type === "Single") {
        setInvoiceData((prevData) => ({
          ...prevData,
          invoiceFileNameS3: null,
        }));
      } else {
      }
      toast.update(id, {
        render: "Invoice file deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      console.log("Error Deleting file:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };
  const handleStatusChange = (event) => {
    if (event.target.value === "Paid") {
      setValue("invoiceAmountReceived", invoiceRate);
    } else {
      setValue("invoiceAmountReceived", 0);
    }
  };

  const invoiceNumber = watch("invoiceNumber");
  const invoiceBroker = watch("invoiceBroker");
  const invoiceRecipentEmail = watch("invoiceRecipentEmail");
  const invoiceDate = watch("invoiceDate");
  const invoiceLoadNo = watch("invoiceLoadNo");
  const invoiceLabel = watch("invoiceLabel");

  const isFormValid =
    invoiceNumber &&
    invoiceDate &&
    invoiceBroker &&
    invoiceRecipentEmail &&
    invoiceLoadNo &&
    invoiceLabel &&
    invoiceRate &&
    invoiceTotalDue;

  return (
    <div
      className="modal fade"
      id="modalEditInvoice"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">
                Editing Invoice#{" "}
                {selectedInvoiceData ? selectedInvoiceData[1] : ""}
                {selectedInvoiceData &&
                  calculateDaysDifferenceFromToday(selectedInvoiceData[7]) && (
                    <>
                      {"  Age: "}
                      {calculateDaysDifferenceFromToday(selectedInvoiceData[7])}
                    </>
                  )}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="submit"
                className="btn reg-user-submit"
                onClick={handleSubmit(onSubmit)}
                disabled={!isFormValid}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setRefreshEditModal(false);
                  setBlobUrl(null);
                }}
                id="closeButtonEditInvoice"
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)} className="modal-body">
              <div>
                <div id="invoiceDetails" className="fw-bold text-center">
                  Invoice Details
                </div>
                <hr className="rounded"></hr>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Invoice#</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceNumber", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Status: </label>
                    <select
                      className="form-control input-field"
                      {...register("invoicePaidStatus", {
                        required: true,
                      })}
                      onChange={handleStatusChange}
                    >
                      <option value="Created">Created</option>
                      <option value="Sent">Sent</option>
                      <option value="Short">Short</option>
                      <option value="Paid">Paid</option>
                    </select>
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Submitted Thru:
                    </label>
                    <select
                      className="form-control input-field"
                      {...register("invoiceSubmittedThru")}
                    >
                      <option value="Choose...">Choose...</option>
                      <option value="Email">Email</option>
                      <option value="ePay">ePay</option>
                      <option value="TriumphPay">TriumphPay</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Label: </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceLabel", {
                        required: true,
                        maxLength: 100,
                      })}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Load# </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceLoadNo", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Broker: </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceBroker", {
                        required: true,
                        maxLength: 100,
                      })}
                    />
                  </div>
                  <div className="col-md-1 d-flex align-items-end">
                    <select
                      className="form-control input-field fw-bold"
                      {...register("assignedBrokerBillingEmail")}
                      defaultValue={"Choose..."}
                      onChange={(e) => handleBrokerChange(e)}
                    >
                      <option value="Choose...">Choose...</option>
                      {brokers.map((broker) => (
                        <option key={broker[0]} value={broker[0]}>
                          {broker[1] + (broker[2] ? ` (${broker[2]})` : "")}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-5 d-flex align-items-end">
                    <label className="col-form-label pb-0 single-line">
                      Billing Email:{" "}
                    </label>
                    <input
                      type="email"
                      className="form-control input-field"
                      {...register("invoiceRecipentEmail", {
                        required: true,
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>

                <div id="invoiceRates" className="fw-bold text-center mt-4">
                  Invoice Rates
                </div>
                <hr className="rounded"></hr>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Rate($):
                    </label>
                    <input
                      type="number"
                      className="form-control input-field"
                      value={invoiceRate}
                      onChange={(e) => handleInputChange(e, setInvoiceRate)}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Detention($):
                    </label>
                    <input
                      type="number"
                      className="form-control input-field"
                      value={invoiceDetention}
                      onChange={(e) =>
                        handleInputChange(e, setInvoiceDetention)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Lumper($):
                    </label>
                    <input
                      type="number"
                      className="form-control input-field"
                      value={invoiceLumper}
                      onChange={(e) => handleInputChange(e, setInvoiceLumper)}
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Misc Label:
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceMiscLabel", {
                        maxLength: 50,
                      })}
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Misc Amount($):
                    </label>
                    <input
                      type="number"
                      className="form-control input-field"
                      value={invoiceMiscAmount}
                      onChange={(e) =>
                        handleInputChange(e, setInvoiceMiscAmount)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Total Due:
                    </label>
                    <input
                      className="form-control input-field"
                      value={invoiceTotalDue}
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end single-line">
                    <label className="col-form-label pb-0 mx-1">
                      Rec. Amount:
                    </label>
                    <input
                      type="number"
                      className="form-control input-field"
                      {...register("invoiceAmountReceived", {
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>
                {errorMessage && (
                  <div className="row mt-2">
                    <div className="text-danger mr-3 text-center">
                      {errorMessage}
                    </div>
                  </div>
                )}

                <div id="invoiceDates" className="fw-bold text-center mt-4">
                  Invoice Dates
                </div>
                <hr className="rounded"></hr>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Pickup Date:
                    </label>
                    <input
                      type="date"
                      className="form-control input-field"
                      {...register("loadShipperPickupDate", {
                        required: true,
                        maxLength: 100,
                      })}
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Invoice Date:
                    </label>
                    <input
                      type="date"
                      className="form-control input-field"
                      {...register("invoiceDate", {
                        required: true,
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Paid Date:
                    </label>
                    <input
                      type="date"
                      className="form-control input-field"
                      {...register("invoicePaidDate", {
                        maxLength: 100,
                      })}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Deposit Date:
                    </label>
                    <input
                      type="date"
                      className="form-control input-field"
                      {...register("invoiceDepositDate", {
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>

                <div id="invoiceExtras" className="fw-bold text-center mt-4">
                  Other Details
                </div>
                <hr className="rounded"></hr>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Method Paid:
                    </label>
                    <select
                      className="form-control input-field"
                      {...register("invoiceMethodPaid")}
                    >
                      <option value="Choose...">Choose...</option>
                      <option value="ACH/Wire">ACH/Wire</option>
                      <option value="Check">Check</option>
                      <option value="TriumphPay/ACH">TriumphPay/ACH</option>
                      <option value="Zelle">Zelle</option>
                    </select>
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Check# </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceCheckNo", {
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Agent Name:
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceBrokerName", {
                        maxLength: 100,
                      })}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Comments:
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      {...register("invoiceComment", {
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>
              </div>
              <div id="documentsDetails" className="fw-bold text-center mt-4">
                Documents
              </div>
              <hr className="rounded"></hr>
              {blobUrl && (
                <>
                  <div className="mt-5 d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => setBlobUrl(null)}
                    >
                      Close
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {fileName.endsWith(".pdf") ? (
                        <embed className="iframedriver" src={blobUrl}></embed>
                      ) : (
                        <img
                          width={"100%"}
                          height="auto"
                          src={blobUrl}
                          alt="Image"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="row mb-2">
                <div className="col-md-6">
                  <label className="col-form-label fw-bold">
                    Invoice Single:
                  </label>
                  {invoiceData.invoiceFileNameS3 && (
                    <ul>
                      <li>
                        <button
                          type="button"
                          className="me-2 mt-1 btn btn-outline-success btn-sm"
                          onClick={() =>
                            handleViewClick(
                              invoiceData.invoiceFileNameS3,
                              "Single"
                            )
                          }
                        >
                          View
                        </button>
                        <button
                          type="button"
                          className="me-2 mt-1 btn btn-outline-danger btn-sm"
                          onClick={() =>
                            handleDeleteClick(
                              invoiceData.invoiceFileNameS3,
                              "Single"
                            )
                          }
                        >
                          Delete
                        </button>
                        <span>{invoiceData.invoiceFileNameS3}</span>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="col-form-label fw-bold">
                    Invoice Merged:
                  </label>
                  {invoiceData.invoiceFileNameS3Merged && (
                    <ul>
                      <li>
                        <button
                          type="button"
                          className="me-2 mt-1 btn btn-outline-success btn-sm"
                          onClick={() =>
                            handleViewClick(
                              invoiceData.invoiceFileNameS3Merged,
                              "Merged"
                            )
                          }
                        >
                          View
                        </button>
                        <button
                          type="button"
                          className="me-2 mt-1 btn btn-outline-danger btn-sm"
                          onClick={() =>
                            handleDeleteClick(
                              invoiceData.invoiceFileNameS3Merged,
                              "Merged"
                            )
                          }
                        >
                          Delete
                        </button>
                        <span>{invoiceData.invoiceFileNameS3Merged}</span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditInvoice;
