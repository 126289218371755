import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const ViewBroker = ({
  selectedData,
  setRefreshViewModal,
  refreshViewModal,
  userRole,
}) => {
  const {
    register,
    setValue, // Added setValue from react-hook-form
  } = useForm();

  const [selectedFilesCP, setSelectedFilesCP] = useState([]);
  const [brokerfiles, setBrokerFiles] = useState({
    brokerContractPacket: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const fetchFiles = async (brokerId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GETBROKERFILES, {
        params: { brokerId: brokerId },
      });
      const tempData = response.data.message[0];
      console.log(tempData);

      // Check if brokerContractPacket is an array
      if (Array.isArray(tempData["brokerContractPacket"])) {
        setBrokerFiles((prevBrokerFiles) => ({
          ...prevBrokerFiles,
          ["brokerContractPacket"]: tempData["brokerContractPacket"],
        }));
      } else {
        console.log("Invalid Data Structure for brokerContractPacket");
      }

      // Handle potentially null values using nullish coalescing operator (??)
      setValue("brokerEmail", tempData["brokerEmail"] ?? "");
      setValue("brokerPayment", tempData["brokerPayment"] ?? "");
      setValue("brokerRemittance", tempData["brokerRemittance"] ?? "");
      setValue("brokerStatus", tempData["brokerStatus"] ?? "Disabled"); // Assuming "Inactive" is the fallback if null

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching broker to edit:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedData && refreshViewModal) {
      setIsLoading(true);
      setValue("brokerName", selectedData[1]);
      setValue("mcNumber", selectedData[2]);
      setValue("brokerPhone", selectedData[3]);
      setValue("brokerAfterHours", selectedData[4]);
      setValue("brokerBillingEmail", selectedData[5]);
      fetchFiles(selectedData[0]);
    }
  }, [selectedData, refreshViewModal]);

  useEffect(() => {
    setSelectedFilesCP(brokerfiles.brokerContractPacket);
    setIsLoading(false);
  }, [brokerfiles]);

  const handleDownloadClick = async (contentName) => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.BROKERFILESACTION}/${contentName}?brokerId=${selectedData[0]}`
      );
      const fileUrl = response.data.url;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.log("Error fetching file:", error);
    }
  };

  return (
    <div
      className="modal fade"
      id="modalViewBroker"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fw-bold fs-5">Viewing Broker</h1>
          </div>
          {!isLoading ? (
            <form className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label fw-bold">Name:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerName", {
                      required: true,
                      maxLength: 255,
                    })}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label fw-bold">MC#</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("mcNumber", { maxLength: 255 })}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label fw-bold">Phone:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerPhone", {
                      maxLength: 255,
                    })}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label fw-bold">Email:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerEmail", { maxLength: 255 })}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">After Hours:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerAfterHours", { maxLength: 255 })}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Billing Email:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerBillingEmail", { maxLength: 255 })}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">
                    Remittance Email:
                  </label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerRemittance", { maxLength: 255 })}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Payment Inquiry:</label>
                  <input
                    type="text"
                    className="form-control create-input"
                    {...register("brokerPayment", { maxLength: 255 })}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="form-label fw-bold">Status:</label>
                  <select
                    className="form-control create-input"
                    {...register("brokerStatus")}
                    disabled
                  >
                    <option value="Active">Active</option>
                    <option value="Disabled">Disabled</option>
                  </select>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-12">
                  {/* <label className="form-label fw-bold">Contract Packet:</label>
                  <input type="file" className="form-control" disabled /> */}
                  {selectedFilesCP !== null && (
                    <>
                      <label className="form-label fw-bold">
                        Contract Packet:
                      </label>
                      <ul>
                        {selectedFilesCP.map((file) => (
                          <li key={file}>
                            <button
                              type="button"
                              className="me-2 mt-1 btn btn-outline-success btn-sm"
                              onClick={() => handleDownloadClick(file)}
                            >
                              View
                            </button>
                            {file}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
              <div>
                <button type="submit" className="btn reg-user-submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    setRefreshViewModal(false);
                    setSelectedFilesCP(null);
                  }}
                  id="closeButton"
                >
                  Close
                </button>
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewBroker;
