import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";

const InvoiceFilter = ({ sendData }) => {
  const [brokers, setBrokers] = useState([]);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    sendData(data);
  };

  const fetchBrokers = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.BROKERNAMES);
      setBrokers(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  };

  return (
    <div className="p-0 card mt-3">
      <div className="card-header fw-bold">Filter</div>
      <form className="card-body" onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-2">
          <div className="col-md-4">
            <label className="form-label">Load#</label>
            <input
              type="text"
              className="form-control"
              {...register("ByLoadNo", { maxLength: 100 })}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Invoice#</label>
            <input
              type="text"
              className="form-control"
              {...register("ByNo", { maxLength: 50 })}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Payment Method:</label>
            <select
              className="form-select"
              {...register("ByPaymentMethod")}
              defaultValue="All"
            >
              <option value="All">All</option>
              <option value="ACH/Wire">ACH/Wire</option>
              <option value="Check">Check</option>
              <option value="Zelle">Zelle</option>
            </select>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-4">
            <label className="form-label">Broker:</label>
            <select
              className="form-control"
              onFocus={fetchBrokers}
              defaultValue={"All"}
              {...register("ByBroker")}
            >
              <option value="All">All</option>
              {brokers.map((broker) => (
                <option key={broker[0]} value={broker[1]}>
                  {broker[1] + (broker[2] ? ` (${broker[2]})` : "")}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Status:</label>
            <select
              className="form-select"
              {...register("ByStatus")}
              defaultValue="All"
            >
              <option value="All">All</option>
              <option value="Created">Created</option>
              <option value="Sent">Sent</option>
              <option value="Short">Short</option>
              <option value="Paid">Paid</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Month:</label>
            <select
              className="form-select"
              {...register("ByMonth")}
              defaultValue="All"
            >
              <option value="All">All</option>
              <option value="09">September-24</option>
              <option value="08">August-24</option>
              <option value="07">July-24</option>
              <option value="06">June-24</option>
              <option value="05">May-24</option>
            </select>
          </div>
        </div>
        <div className="text-center mb-2">
          <button type="submit" className="btn reg-user-submit">
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default InvoiceFilter;
