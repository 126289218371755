import React, { useEffect, useState } from "react";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { differenceInCalendarDays } from "date-fns";

const ViewInvoice = ({
  selectedInvoiceData,
  setRefreshViewModal,
  refreshViewModal,
}) => {
  const [invoiceData, setInvoiceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null); // State for iframe URL
  const [fileName, setFileName] = useState(null); // State for iframe URL

  const fetchLoadData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.INVOICESDETAILS, {
        params: { invoiceId: selectedInvoiceData[0] },
      });
      const tempData = response.data.data["invoice-data"];
      setInvoiceData(tempData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching load to view:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedInvoiceData &&
      refreshViewModal &&
      selectedInvoiceData[0] !== null
    ) {
      fetchLoadData();
    }
  }, [selectedInvoiceData, refreshViewModal]);

  const calculateDaysDifferenceFromToday = (startDate) => {
    const start = new Date(startDate);
    const today = new Date();
    return differenceInCalendarDays(today, start);
  };

  function formatDateTime(dateStr) {
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    let parts = dateStr.split(" ");
    let day = parts[1];
    let month = months[parts[2]];
    let year = parts[3];
    let formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  const handleViewClick = async (contentName, Type) => {
    setBlobUrl(null);
    setFileName(contentName);
    let id = toast.loading("File is being fetched. Please wait...");
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.VIEWINVOICE}/${contentName}?Type=${Type}`,
        {
          responseType: "blob",
        }
      );
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setBlobUrl(fileUrl);
      toast.update(id, {
        render: "Success. File will be opened shortly...",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong with the request.",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      console.log("Error fetching file:", error);
    }
  };

  const handleDownloadClick = async (contentName, Type) => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.INVOICE}/${contentName}?Type=${Type}`
      );
      const fileUrl = response.data.url;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.log("Error fetching file:", error);
    }
  };

  return (
    <div
      className="modal fade"
      id="modalViewInvoice"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content form-part-head">
          <div className="row container mt-2 mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <h1 className="modal-title fs-5 fw-bold">
                Viewing Invoice#{" "}
                {selectedInvoiceData ? selectedInvoiceData[1] : ""}
                {selectedInvoiceData &&
                  calculateDaysDifferenceFromToday(selectedInvoiceData[7]) && (
                    <>
                      {"  Age: "}
                      {calculateDaysDifferenceFromToday(selectedInvoiceData[7])}
                    </>
                  )}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setRefreshViewModal(false);
                  setBlobUrl(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
          {!isLoading ? (
            <form onSubmit={(e) => e.preventDefault()} className="modal-body">
              <div>
                <div id="invoiceDetails" className="fw-bold text-center">
                  Invoice Details
                </div>
                <hr className="rounded"></hr>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Invoice#{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[3] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Status: </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[5] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Submitted Thru:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoiceSubmittedThru
                          ? invoiceData.invoiceSubmittedThru
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Label: </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[2] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Load# </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[1] : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Broker: </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[4] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Recipent Email:
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoiceRecipentEmail
                          ? invoiceData.invoiceRecipentEmail
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>

                <div id="invoiceRates" className="fw-bold text-center mt-4">
                  Invoice Rates
                </div>
                <hr className="rounded"></hr>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Rate: </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[10] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Detention:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[11] : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Lumper: </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[12] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Misc Label:
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[14] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Misc Amount:
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[13] : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Total Due:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[8] : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end single-line">
                    <label className="col-form-label pb-0 mx-1">
                      Rec. Amount:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData ? selectedInvoiceData[9] : ""
                      }
                      disabled
                    />
                  </div>
                </div>

                <div id="invoiceDates" className="fw-bold text-center mt-4">
                  Invoice Dates
                </div>
                <hr className="rounded"></hr>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Pickup Date:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData
                          ? formatDateTime(selectedInvoiceData[6])
                          : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Invoice Date:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        selectedInvoiceData
                          ? formatDateTime(selectedInvoiceData[7])
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Paid Date:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoicePaidDate
                          ? formatDateTime(invoiceData.invoicePaidDate)
                          : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Deposit Date:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoiceDepositDate
                          ? formatDateTime(invoiceData.invoiceDepositDate)
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>

                <div id="invoiceExtras" className="fw-bold text-center mt-4">
                  Other Details
                </div>
                <hr className="rounded"></hr>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Method Paid:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoiceMethodPaid
                          ? invoiceData.invoiceMethodPaid
                          : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">Check# </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoiceCheckNo
                          ? invoiceData.invoiceCheckNo
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1 single-line">
                      Agent Name:
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoiceBrokerName
                          ? invoiceData.invoiceBrokerName
                          : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <label className="col-form-label pb-0 mx-1">
                      Comment:{" "}
                    </label>
                    <input
                      className="form-control input-field"
                      defaultValue={
                        invoiceData.invoiceComment
                          ? invoiceData.invoiceComment
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                <div id="documentsDetails" className="fw-bold text-center mt-4">
                  Documents
                </div>
                <hr className="rounded"></hr>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="col-form-label fw-bold">
                      Invoice Single:
                    </label>
                    {invoiceData.invoiceFileNameS3 && (
                      <ul>
                        <li>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-success btn-sm"
                            onClick={() =>
                              handleViewClick(
                                invoiceData.invoiceFileNameS3,
                                "Single"
                              )
                            }
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() =>
                              handleDownloadClick(
                                invoiceData.invoiceFileNameS3,
                                "Single"
                              )
                            }
                          >
                            Download
                          </button>
                          <span>{invoiceData.invoiceFileNameS3}</span>
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label fw-bold">
                      Invoice Merged:
                    </label>
                    {invoiceData.invoiceFileNameS3Merged && (
                      <ul>
                        <li>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-success btn-sm"
                            onClick={() =>
                              handleViewClick(
                                invoiceData.invoiceFileNameS3Merged,
                                "Merged"
                              )
                            }
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="me-2 mt-1 btn btn-outline-danger btn-sm"
                            onClick={() =>
                              handleDownloadClick(
                                invoiceData.invoiceFileNameS3Merged,
                                "Merged"
                              )
                            }
                          >
                            Download
                          </button>
                          <span>{invoiceData.invoiceFileNameS3Merged}</span>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                {blobUrl && (
                  <>
                    <div className="mt-5 d-flex flex-row-reverse">
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => setBlobUrl(null)}
                      >
                        Close
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {fileName.endsWith(".pdf") ? (
                          <embed className="iframedriver" src={blobUrl}></embed>
                        ) : (
                          <img
                            width={"100%"}
                            height="auto"
                            src={blobUrl}
                            alt="Image"
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewInvoice;
