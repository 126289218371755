import React, { useState, useEffect } from "react";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { FaPagelines } from "react-icons/fa";
import DriverWeeklyReport from "./DriverWeeklyReport";

const CheckDriverLoadsCount = () => {
  const [loadCount, setLoadCount] = useState([]);
  const [loadDates, setLoadDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (startDate && endDate) {
      getLoadCount();
    }
  }, [startDate, endDate]);

  const getLoadCount = async () => {
    setIsLoading(true);
    try {
      const currentTime = new Date().toISOString().split("T")[0];
      const queryParams =
        startDate && endDate
          ? `start=${startDate}&end=${endDate}&search=manual`
          : `timestamp=${currentTime}&search=auto`;

      const response = await axiosInstance.get(
        `${API_ENDPOINTS.DRIVERSLOADCOUNT}?${queryParams}`
      );
      setLoadCount(response.data.message.data_stats);
      setLoadDates(response.data.message.date);
    } catch (error) {
      console.error("Error getting Loads Count:", error);
    }
    setIsLoading(false);
  };

  const handleEditFinances = (index) => {
    setSelectedUserName(index);
  };

  return (
    <div className="card" style={{ maxHeight: "600px", overflowY: "auto" }}>
      <div className="card-header d-flex justify-content-between align-items-center fw-bold">
        <span>Load Counts</span>
        <div className="d-flex align-items-center">
          <label htmlFor="startDate" className="me-2">
            Start:
          </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control me-2"
            placeholder="Start Date"
          />
          <label htmlFor="startDate" className="me-2">
            End:
          </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control me-2"
            placeholder="End Date"
          />
          <button
            className="btn btn-primary single-line"
            onClick={getLoadCount}
            disabled={isLoading}
          >
            {isLoading ? "Getting results..." : "Get Data"}
          </button>
        </div>
      </div>
      <div className="card-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Driver Name</th>
              <th>Completed</th>
              <th>Lumper Count/Sum</th>
              <th>TONU</th>
              <th>Report</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(loadCount).map((name) => (
              <tr key={name}>
                <td>{name}</td>
                <td>{loadCount[name].count}</td>
                <td>
                  {loadCount[name].total_occured + "/ $" + loadCount[name].sum}
                </td>
                <td>{loadCount[name].tonu}</td>
                <td>
                  <FaPagelines
                    className="PenTip"
                    onClick={() => handleEditFinances(name)}
                    data-bs-toggle="modal"
                    data-bs-target="#modal3"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-center">
          Week Start: {loadDates.start} Week End: {loadDates.end}
        </div>
      </div>
      <DriverWeeklyReport
        selectedDataId={selectedUserName}
        reportStartDate={loadDates.start}
        reportEndDate={loadDates.end}
      />
    </div>
  );
};

export default CheckDriverLoadsCount;
