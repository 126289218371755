import React from 'react'

const NotFound = () => {
  return (
    <div className='container'>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
      <h1>404 Not Found</h1>
    </div>
  )
}

export default NotFound
