import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import "../../Styling/EditLoginForm.css";

const EditLoginForm = () => {
  const [currentColor, setCurrentColor] = useState("#FFFFFF"); // Initial color
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  function handleChangeComplete(event) {
    setCurrentColor(event.target.value); // Set the current color
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const [loadData, setLoadData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  async function fetchData() {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.SELF);
      setLoadData(response.data.message[0]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Use a separate useEffect to set form values after data is fetched
    if (loadData) {
      setValue("userName", loadData[0]);
      setValue("phoneNumber", loadData[1]);
      setValue("email", loadData[2]);
    }
  }, [loadData, setValue]);

  const password = watch("password");

  const onSubmit = async (data) => {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);
    if (!data.passwordold) {
      toast.warning("Please enter the current/old password", {
        autoClose: 1000,
      });
      return;
    }

    if (data.passwordold === data.password) {
      toast.warning("Old password and new password must be different", {
        autoClose: 1000,
      });
      return;
    }
    let id = toast.loading("Updating user details.Please wait..."); // Update id inside the try block
    try {
      data.backgroundColor = String(currentColor);
      await axiosInstance.patch(API_ENDPOINTS.SELF, data);
      toast.update(id, {
        render: "User details updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      setValue("passwordold", "");
      setValue("password", "");
      setValue("confirmPassword", "");
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <div className="ourrow">
      {!isLoading ? (
        <form className="editcard" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label className="form-label fw-bold">Username:</label>
            <input
              type="text"
              className="form-control editdisabled"
              defaultValue={loadData[0]}
              disabled
              readOnly
            />
          </div>

          <div className="mb-3">
            <label className="form-label fw-bold">Phone:</label>
            <input
              type="tel"
              placeholder="12345678900"
              className="form-control create-input"
              {...register("phoneNumber", { maxLength: 15 })}
            />
            {errors.phone && errors.phone.type === "maxLength" && (
              <span className="text-danger fst-italic">
                Phone number must not exceed 15 characters
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Email:</label>
            <input
              type="email"
              placeholder="user@example.com"
              className="form-control create-input"
              {...register("email", { maxLength: 255 })}
            />
            {errors.email && errors.email.type === "maxLength" && (
              <span className="text-danger fst-italic">
                Email must not exceed 255 characters
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Current/Old Password:</label>
            <input
              type="password"
              placeholder="Password"
              className="form-control  create-input"
              {...register("passwordold")}
            />
            {errors.passwordold && errors.passwordold.type === "required" && (
              <span className="text-danger fst-italic">
                This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Password:</label>
            <input
              type="password"
              placeholder="Password"
              className="form-control create-input"
              {...register("password", { minLength: 8 })}
            />
            {errors.password && errors.password.type === "required" && (
              <span className="text-danger fst-italic">
                This field is required
              </span>
            )}
            {errors.password && errors.password.type === "minLength" && (
              <span className="text-danger fst-italic">
                Password must be at least 8 characters long
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Confirm Password:</label>
            <input
              className="form-control create-input"
              type="password"
              placeholder="Retype Above Password"
              {...register("confirmPassword", {
                validate: (value) =>
                  value === password || "Passwords do not match",
              })}
            />
            {errors.confirmPassword && (
              <span className="text-danger fst-italic">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label fw-bold me-2">Output:</label>
            <span
              className="status-button me-2"
              style={{ backgroundColor: currentColor }}
            >
              Name XYZ
            </span>
            <input
              type="color"
              value={currentColor}
              onChange={handleChangeComplete}
            />
          </div>

          <button type="submit" className="btn reg-user-submit editsubmit">
            Save
          </button>
        </form>
      ) : (
        <>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditLoginForm;
