import React, { useContext, useEffect } from 'react';
import { API_ENDPOINTS } from '../../Api/apiConfig';
import { useHistory } from 'react-router-dom';
import { OpenContext } from '../../App';
import axios from 'axios';

const Logout = () => {
  const history = useHistory();  // Initialize useHistory
  const { reloadNavbarComponent } = useContext(OpenContext);

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await axios.delete(API_ENDPOINTS.ME, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authkeytemp')}`
          }
        });
        localStorage.removeItem('authkeytemp');
        localStorage.removeItem('authkeyperm');
        history.push('/login');
        reloadNavbarComponent();
      } catch (error) {
        console.error('Error logging out:', error);
        // localStorage.removeItem('authkeytemp');
        // localStorage.removeItem('authkeyperm');
        // history.push('/login');
        // reloadNavbarComponent();
      }
    };

    // Call the logout function
    logoutUser();
  }, [history]); // Include history in the dependency array to avoid the React Hook useEffect has a missing dependency warning

  return (
    <div>
      {/* You can optionally render a loading message or spinner here */}
    </div>
  );
};

export default Logout;
