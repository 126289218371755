import React, { useContext } from "react";
import LoadsTableAdmin from "../../Components/AdminDispatcher/LoadsTableAdmin";
import PageTitle from "../../Components/Shared/PageTitle";
import { OpenContext } from "../../App";
import NotFound from "../Shared/NotFound";
import LoadsTableDriver from "../../Components/Driver/LoadsTableDriver";

const Loads = () => {
  const { userRole, isLoading } = useContext(OpenContext);
  return (
    <>
      {!isLoading && (
        <>
          {(userRole === "admin" || userRole === "dispatcher") && (
            <div>
              <PageTitle title="Loads" description="Admin has special roles" />
              <LoadsTableAdmin userRole={userRole} />
            </div>
          )}

          {userRole === "driver" && (
            <div className="container little-spacing-driver">
              <LoadsTableDriver />
            </div>
          )}

          {userRole !== "admin" &&
            userRole !== "dispatcher" &&
            userRole !== "driver" && <NotFound />}
        </>
      )}
    </>
  );
};

export default Loads;
