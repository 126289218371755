import React, { useState, useEffect } from "react";
import { API_ENDPOINTS, axiosInstance } from "../../Api/apiConfig";
import { FaEye, FaPencilAlt, FaTrash } from "react-icons/fa";
import "../../Styling/AgentList.css";
import TableLoader from "../Shared/TableLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditBroker from "../../Components/AdminDispatcher/EditBroker";
import ViewBroker from "../../Components/AdminDispatcher/ViewBroker";
import SignupBroker from "./SignupBroker";

const Brokerslist = ({ userRole }) => {
  const [myData, setMyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false); // State variable to trigger table reload
  const [selectedBroker, setSelectedBroker] = useState(null); // State variable to store the selected broker data
  const [refreshEditModal, setRefreshEditModal] = useState(false);
  const [refreshViewModal, setRefreshViewModal] = useState(false);

  async function getData() {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.BROKERS);
      const data = response.data.message[0];
      setMyData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error getting brokerlist:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [reloadTable]);

  const handleDeleteRow = async (index) => {
    const updatedData = [...myData];
    const brokerName = updatedData[index][1];
    const brokerId = updatedData[index][0];
    const confirmDelete = window.confirm(
      `Are you sure you want to delete broker named ${brokerName}?`
    );
    if (!confirmDelete) {
      return;
    }
    let id = toast.loading("Removing broker.Please wait..."); // Update id inside the try block
    try {
      await axiosInstance.delete(API_ENDPOINTS.BROKERS, {
        params: { brokerId: brokerId },
      });
      updatedData.splice(index, 1);
      toast.update(id, {
        render: "Broker deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
      setMyData(updatedData);
    } catch (error) {
      console.error("Error deleting broker:", error);
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  const handleSaveSuccess = () => {
    setReloadTable((prevReloadTable) => !prevReloadTable);
  };

  const handleViewRow = (index) => {
    setSelectedBroker(myData[index]);
    setRefreshViewModal(true);
  };

  const handleEditRow = (index) => {
    setSelectedBroker(myData[index]);
    setRefreshEditModal(true);
  };

  return (
    <div>
      <div className="text-center mt-1 mb-1">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-toggle="modal"
          data-bs-target="#modalNewBroker"
        >
          Add New Broker
        </button>
      </div>

      {isLoading ? (
        <TableLoader />
      ) : myData.length > 0 ? (
        <div>
          <div className="table-responsive shadow mb-4">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>MC#</th>
                  <th>Phone</th>
                  <th>A.Hours</th>
                  <th>Bill. Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {myData.map((brokerData, index) => {
                  const [brokerId, name, mc, phone, afterhours, billingEmail] =
                    brokerData;

                  return (
                    <tr className="myfs-13" key={index}>
                      <td>{name}</td>
                      <td>{mc}</td>
                      <td>{phone}</td>
                      <td>{afterhours}</td>
                      <td>{billingEmail}</td>

                      <td>
                        <span className="actions">
                          <FaPencilAlt
                            className="EyeTip"
                            onClick={() => handleEditRow(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#modalEditBroker"
                          />

                          <FaEye
                            className="PenTip"
                            onClick={() => handleViewRow(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#modalViewBroker"
                          />
                          {userRole === "admin" && (
                            <FaTrash
                              className="DelBin"
                              onClick={() => handleDeleteRow(index)}
                            />
                          )}
                        </span>
                      </td>
                      {/* <td>
                        <span
                          className={`status-circle agent-status-${status.toLowerCase()}`}
                        ></span>
                        <span className="status-text">{status}</span>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <p>No Brokers to Show</p>
        </div>
      )}

      <SignupBroker onSaveSuccess={handleSaveSuccess} userRole={userRole} />
      <ViewBroker
        selectedData={selectedBroker}
        setRefreshViewModal={setRefreshViewModal}
        refreshViewModal={refreshViewModal}
        userRole={userRole}
      />
      <EditBroker
        onSaveSuccess={handleSaveSuccess}
        selectedData={selectedBroker}
        setRefreshEditModal={setRefreshEditModal}
        refreshEditModal={refreshEditModal}
        userRole={userRole}
      />
    </div>
  );
};

export default Brokerslist;
