import React, { useContext } from "react";
import Userlist from "../../Components/Admin/Userlist";
import PageTitle from "../../Components/Shared/PageTitle";
import { OpenContext } from "../../App";
import NotFound from "../Shared/NotFound";

const Dispatchers = () => {
  const { userRole, isLoading } = useContext(OpenContext);
  return (
    <div>
      {!isLoading && (
        <>
          {userRole === "admin" ? (
            <>
              {" "}
              <PageTitle
                title="Dispatchers"
                description="Dispatchers can create load"
              />
              <Userlist role="Dispatcher" />
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </div>
  );
};

export default Dispatchers;
